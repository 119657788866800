import React from 'react';
import { Box, VStack, Text } from '@chakra-ui/react';
import { EmptyTeamChatView } from './components';
import { TeamChatData } from '../../types';

interface ITeamChatPlaceholderProps {
	teamChatData?: TeamChatData | null;
	isAdmin?: boolean;
}

export const TeamChatPlaceholder: React.FC<ITeamChatPlaceholderProps> = ({
	teamChatData,
	isAdmin,
}) => {
	return (
		<Box
			w="100%"
			borderRadius="4px"
			boxShadow="light"
			borderWidth="1px"
			borderColor="gray.20"
			bg="gray.10">
			<VStack spacing={0} align="flex-start" p="8px 8px 4px 8px">
				<Text fontWeight={500} noOfLines={1}>
					Your team chat
				</Text>
				<EmptyTeamChatView isEmpty={!teamChatData} isAdmin={isAdmin} />
			</VStack>
		</Box>
	);
};
