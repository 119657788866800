import { useInfiniteQuery } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import { ApiResponse } from '../../../../../types';
import { QueryKeys } from '../../../../../constants';
import { CardActivityItem } from '../../../types';

const PAGE_SIZE = 40;

export const useGetCardActivitiesList = (
	workspaceId?: number | null,
	cardId?: number,
	offset?: number,
) => {
	const fetchActivities = async ({ pageParam = 0, offset = 0 }) => {
		const res: ApiResponse<{
			count: number;
			timeline: CardActivityItem[];
		}> = await API.get(
			`/api/workspaces/${workspaceId}/cards/${cardId}/timelines/get-list`,
			{
				Limit: PAGE_SIZE,
				Offset: PAGE_SIZE * pageParam + offset,
			},
		);

		return { ...res.value, pageParam };
	};

	return useInfiniteQuery({
		initialPageParam: 0,
		queryKey: [QueryKeys.GET_CARD_ACTIVITIES_LIST, cardId, workspaceId],
		queryFn: ({ pageParam }) =>
			fetchActivities({
				offset,
				pageParam,
			}),
		getNextPageParam: lastPage => {
			return lastPage?.timeline?.length === PAGE_SIZE
				? lastPage.pageParam + 1
				: null;
		},
		enabled: !!workspaceId && !!cardId,
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});
};
