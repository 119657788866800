import { useMutation } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';
import { useDispatch } from 'react-redux';
import { setArchiveVisibility } from '../../../redux/reducers/auth.reducer';

export const useUpdateArchiveVisibility = () => {
	const dispatch = useDispatch();

	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ workspaceId: number; isArchiveVisible: boolean }
	>({
		mutationKey: [QueryKeys.UPDATE_ARCHIVE_VISIBILITY],
		mutationFn: payload => {
			const { workspaceId, isArchiveVisible } = payload;
			return API.put(`/api/workspaces/${workspaceId}/archive/status`, {
				isArchiveVisible,
			});
		},
		onSuccess(data, payload) {
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Something went wrong during the update of the archive visibility status',
				);
				return;
			}
			Alerter.success(
				'The archive visibility status has been successfully updated!',
			);
			dispatch(setArchiveVisibility(payload.isArchiveVisible));
		},
	});
};
