import React from 'react';
import { ModalWrapper } from '../../components';
import { SelectWorkspace } from '../Onboarding/steps';

export const SelectWorkspaceModal: React.FC = () => {
	return (
		<ModalWrapper isOpen={true} shouldClose={false} onClose={() => {}}>
			<SelectWorkspace isIsolated={true} />
		</ModalWrapper>
	);
};
