import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils';
import { QueryKeys } from '../../../constants';
import { UpdateStagesPayload } from '../types';
import { useGetWorkspaceId } from '../../../hooks';

export const useSaveStagesChanges = () => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	return useMutation<ApiResponseEmpty, unknown, UpdateStagesPayload>({
		mutationKey: [QueryKeys.SAVE_STAGES_CHANGES],
		mutationFn: payload =>
			API.post(`/api/workspaces/${workspaceId}/stages`, payload),
		onSuccess(data) {
			if (data.success) {
				Alerter.success(
					data.messages?.[0]?.message || 'Stages updated',
				);
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_WORKSPACE_CARDS_STATUSES],
				});
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_ACTIVE_STAGES],
				});
			} else {
				Alerter.error(
					data.errors?.[0]?.message || 'Error while updating stages',
				);
			}
		},
		onError(error) {
			Alerter.error(
				error?.toString() || 'Error occurred while updating stages',
			);
		},
	});
};
