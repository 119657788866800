import React from 'react';
import { Text, HStack, Button, Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Icon } from '../../../components';
import { setSelectTeamChatModalOpenState } from '../../../redux/reducers/workspace.reducer';
import { MiniTeamChatCard } from './MiniTeamChatCard';
import {
	modalSubHeaderStyleProps,
	modalRegularTextStyleProps,
} from '../../../modals/Onboarding/steps/helper';

export const TeamChatSelector: React.FC = () => {
	const dispatch = useDispatch();

	return (
		<Box>
			<Text {...modalSubHeaderStyleProps}>Team Chat</Text>
			<Text {...modalRegularTextStyleProps} mb="16px">
				The pinned chat with your team
			</Text>
			<HStack spacing="16px">
				<MiniTeamChatCard />
				<Button
					variant="outline"
					onClick={() =>
						dispatch(setSelectTeamChatModalOpenState(true))
					}
					leftIcon={
						<Icon name="refresh" height="16px" width="16px" />
					}>
					Select a new chat
				</Button>
			</HStack>
		</Box>
	);
};
