import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, ApiResponseEmpty, CardTaskStatusEnum } from '../types';
import API from '../api/api.base';
import { QueryKeys } from '../constants';
import { Alerter } from '../utils';
import { CardTaskItem } from '../pages/Chat/types';
import { useGetWorkspaceId } from '../hooks';

export const useDeleteTask = (isTaskPage?: boolean, cardId?: number) => {
	const workspaceId = useGetWorkspaceId();
	const queryClient = useQueryClient();

	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationKey: [QueryKeys.DELETE_TASK],
		mutationFn: id =>
			API.delete(
				`/api/workspaces/${workspaceId}/cards/tasks/${id}/delete`,
			),
		onSuccess(data, taskId) {
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Error occurred while deleting task',
				);
				return;
			}
			Alerter.success('Task successfully deleted');

			if (isTaskPage) {
				const currentUserTask = queryClient
					.getQueryData<{
						pageParams: number[];
						pages: { count: number; tasks: CardTaskItem[] }[];
					}>([QueryKeys.GET_ALL_CURRENT_USER_TASKS])
					?.pages?.flatMap(page => page.tasks)
					?.find(task => task.id === taskId);
				if (currentUserTask) {
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_ALL_CURRENT_USER_TASKS],
					});
					queryClient.setQueryData<ApiResponse<number> | undefined>(
						[QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
						oldData => {
							if (oldData && !currentUserTask.isArchived) {
								const newValue =
									oldData.value &&
									currentUserTask.status ===
										CardTaskStatusEnum.TODO
										? oldData.value - 1
										: oldData.value;

								return {
									...oldData,
									value: newValue,
								};
							}

							return oldData;
						},
					);
					return;
				}
				const otherUsersTask = queryClient
					.getQueryData<{
						pageParams: number[];
						pages: { count: number; tasks: CardTaskItem[] }[];
					}>([QueryKeys.GET_ALL_TASKS_OTHER_USERS])
					?.pages?.flatMap(page => page.tasks)
					?.find(task => task.id === taskId);
				if (otherUsersTask) {
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_ALL_TASKS_OTHER_USERS],
					});
					return;
				}
				return;
			}
			if (!cardId) {
				return;
			}
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
			});
		},
	});
};
