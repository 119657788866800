import React, { useMemo } from 'react';
import {
	Avatar,
	AvatarGroup,
	AvatarGroupProps,
	AvatarProps,
} from '@chakra-ui/react';
import { BaseUserData } from '../../types';

interface CustomAvatarGroupProps {
	items: BaseUserData[];
	avatarProps?: AvatarProps;
	groupProps?: AvatarGroupProps;
	avatars: Record<string, string>;
}

export const CustomAvatarGroup: React.FC<CustomAvatarGroupProps> = ({
	items,
	avatarProps,
	groupProps,
	avatars,
}) => {
	const renderAvatars = useMemo(
		() =>
			items.map((user, index) => {
				const avatar = user.telegramUserId
					? avatars[user.telegramUserId]
					: '';

				return (
					<Avatar
						key={user.id}
						name={`${user.firstName} ${user.lastName || ''}`}
						src={avatar}
						width="20px"
						height="20px"
						bg="blueAccent.60"
						sx={{
							position: 'relative',
							marginLeft: index === 0 ? 0 : '-30px',
							zIndex: index,
							'> div': {
								fontSize: '10px',
							},
						}}
						{...avatarProps}
					/>
				);
			}),
		[items, avatars, avatarProps],
	);
	return (
		<AvatarGroup
			max={3}
			size="xs"
			color="white"
			fontSize="11px"
			flexWrap="wrap"
			flexDirection="row-reverse"
			sx={{
				span: {
					borderWidth: '1px',
					borderColor: 'mainSurface',
					bg: 'blueAccent.60',
					color: 'white',
				},
				'span:not(:last-of-type)': {
					marginLeft: '6px',
					color: 'white',
					width: '20px',
					height: '20px',
				},
				'span:first-of-type': {
					ml:
						items?.length === 1
							? '0px'
							: items?.length > 3
								? '-6px'
								: '6px',
					zIndex: items?.length > 3 ? 30 : 3,
					fontSize: items?.length > 3 ? '9px' : '10px',
				},
			}}
			{...groupProps}>
			{renderAvatars}
		</AvatarGroup>
	);
};
