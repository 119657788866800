import { MessageTaggedUsers } from '../types';

export const extractMarkedMentions = (message: string) => {
	const pattern = /@\[([^\]]+)\]\((\d+)\)/g;
	const mentions: MessageTaggedUsers[] = [];

	let match;
	let plainText = message;
	let plainTextOffset = 0;

	while ((match = pattern.exec(message)) !== null) {
		const username = match[1];
		const id = match[2];
		const matchIndex = match.index;
		const usernameLength = username.length;

		const offset = matchIndex - plainTextOffset;

		plainText =
			plainText.slice(0, offset) +
			username +
			plainText.slice(offset + match[0].length);

		plainTextOffset += match[0].length - usernameLength;

		mentions.push({
			taggedUserTelegramIds: +id,
			length: usernameLength,
			offset,
		});
	}

	return mentions;
};
