import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Icon } from '../../../components';

export const PipelineAddStageButton: React.FC<ButtonProps> = props => {
	return (
		<Button
			variant="ghost"
			w="100%"
			color="secondary"
			borderTopRadius={0}
			borderBottomRadius="3px"
			borderTop="1px solid"
			borderTopColor="gray.15"
			leftIcon={
				<Icon
					name="plus"
					height="14px"
					width="14px"
					color="currentColor"
				/>
			}
			{...props}>
			Add Stage
		</Button>
	);
};
