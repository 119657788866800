import React, { PropsWithChildren } from 'react';
import { Tooltip, TooltipProps } from '@chakra-ui/react';

export const SoonTooltip: React.FC<TooltipProps & PropsWithChildren> = ({
	children,
}) => {
	return (
		<Tooltip
			hasArrow={true}
			label="Soon"
			bg="mainSurface"
			color="gray.35"
			fontSize="11px"
			fontWeight="500"
			padding="4px 8px"
			borderRadius="50px"
			lineHeight="10px">
			{children}
		</Tooltip>
	);
};
