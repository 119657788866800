import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from './auth.reducer';

type InitialState = {
	telegramIds: number[];
	selectTeamChatModalOpen: boolean;
};

const initialState: InitialState = {
	telegramIds: [],
	selectTeamChatModalOpen: false,
};

const workspaceReducer = createSlice({
	name: 'workspace',
	initialState,
	reducers: {
		setWorkspaceTelegramIds: (state, action: PayloadAction<number[]>) => {
			state.telegramIds = action.payload;
		},
		setSelectTeamChatModalOpenState: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.selectTeamChatModalOpen = action.payload;
		},
		// setWorkspaceTelegramFolders: (
		// 	state,
		// 	action: PayloadAction<TgFoldersResponseItem[]>,
		// ) => {
		// 	state.telegramFolders = action.payload;
		// },
	},
	extraReducers(builder) {
		builder.addCase(logout, state => {
			state.telegramIds = [];
		});
	},
});

export const {
	setWorkspaceTelegramIds,
	setSelectTeamChatModalOpenState,
	// setWorkspaceTelegramFolders
} = workspaceReducer.actions;

export default workspaceReducer.reducer;
