import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApiResponse, CardStatusItem } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { useSupabaseWithAuth } from '../state/auth/context';

export const useGetWorkspaceCardsStatuses = (isEnabled?: boolean) => {
	const { workSpace } = useSelector((state: RootStore) => state.auth.user);
	const supabase = useSupabaseWithAuth();
	
	return useQuery<ApiResponse<{ statuses: CardStatusItem[] } | null> | null>({
		queryKey: [QueryKeys.GET_WORKSPACE_CARDS_STATUSES, workSpace?.id],
		queryFn: async () => {
			if (!workSpace?.id) {
				return null;
			}
			const { data, error, status } = await supabase
				.from('workspace_card_statuses')
				.select(
					`
                    id,
                    card_status:card_statuses (
                        id,
                        name
                    )
                `,
				)
				.eq('workspace_id', workSpace?.id)
				.order('card_status_id');

			if (error) {
				return {
					success: false,
					status,
					value: null,
					errors: [{ key: error.code, message: error.message }],
					messages: [],
				};
			}

			const statuses: CardStatusItem[] = data.map(item => ({
				id: item.id,
				typeId: item.card_status?.id ?? null,
				name: item.card_status?.name ?? null,
			}));

			return {
				success: true,
				status,
				value: { statuses },
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		enabled: !!isEnabled && !!workSpace?.id,
	});
};
