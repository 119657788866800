import React from 'react';
import { chakra } from '@chakra-ui/react';
import { MessageTaggedUsers } from '../../../../types';

interface MessageBodyProps {
	text: string;
	taggedUsers: MessageTaggedUsers[];
	userTelegramId: number;
}

export const MessageBody: React.FC<MessageBodyProps> = ({
	text,
	taggedUsers,
	userTelegramId,
}) => {
	const renderContent = (
		text: string,
		taggedUsersList: MessageTaggedUsers[],
	): JSX.Element => {
		const renderedText: JSX.Element[] = [];
		let currentIndex = 0;

		const sortedTaggedUsersList = taggedUsersList
			.filter(user => user.offset !== null && user.length !== null)
			.sort((a, b) => a.offset! - b.offset!);

		for (const [index, user] of sortedTaggedUsersList.entries()) {
			const { offset, length } = user || {};
			const beforeText = text.substring(currentIndex, offset!);
			const taggedText = text.substring(offset!, offset! + length!);
			const isCurrentUserTagged =
				user.taggedUserTelegramIds === userTelegramId;

			if (beforeText) {
				renderedText.push(
					<React.Fragment key={`before-${index}`}>
						{beforeText}
					</React.Fragment>,
				);
			}

			renderedText.push(
				<chakra.span
					key={`tagged-${index}`}
					color={isCurrentUserTagged ? 'red.90' : 'blue.DEFAULT'}>
					{taggedText}
				</chakra.span>,
			);

			currentIndex = offset! + length!;
		}

		if (currentIndex < text.length) {
			renderedText.push(
				<React.Fragment key="remaining-text">
					{text.substring(currentIndex)}
				</React.Fragment>,
			);
		}

		return <>{renderedText}</>;
	};

	return renderContent(text, taggedUsers);
};
