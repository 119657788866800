import React from 'react';
import { Box, Center, Divider } from '@chakra-ui/react';
import { ActivityItem } from './ActivityItem';
import { CardActivityItem } from '../../../types';
import { getMonthNameByDate, isNewMonth } from '../../../../../utils';
import { MonthDivider } from './MonthDivider';

interface ActivityItemSectionProps extends CardActivityItem {
	index: number;
	activitiesArr: CardActivityItem[];
}

export const ActivityItemSection: React.FC<ActivityItemSectionProps> = ({
	index,
	activitiesArr,
	...props
}) => {
	const isLastArrayActivity = index === activitiesArr.length - 1;

	const isLastDayOfMonth = isNewMonth(
		props.createdAt,
		activitiesArr[Math.min(index + 1, activitiesArr.length - 1)].createdAt,
	);
	const isNewDayOfMonth =
		isNewMonth(
			props.createdAt,
			activitiesArr[Math.max(0, index - 1)].createdAt,
		) || index === 0;

	return (
		<Box mb={isLastDayOfMonth ? 6 : 0}>
			{isNewDayOfMonth ? (
				<MonthDivider title={getMonthNameByDate(props.createdAt)} />
			) : null}
			{isNewDayOfMonth ? (
				<Box
					height="12px"
					width="24px"
					borderRadius="12px 12px 0 0"
					borderTopWidth="1px"
					borderLeftWidth="1px"
					borderRightWidth="1px"
					borderColor="gray.15"
					bg="gray.10"
					mt={6}
				/>
			) : (
				<Center
					bg="gray.10"
					width="24px"
					height="12px"
					borderLeftWidth="1px"
					borderRightWidth="1px"
					borderColor="gray.15">
					<Divider
						orientation="vertical"
						borderColor="gray.20"
						borderWidth="1px"
						boxSizing="border-box"
						borderRadius="2px"
						opacity={1}
					/>
				</Center>
			)}

			<ActivityItem {...props} />

			{isLastDayOfMonth || isLastArrayActivity ? (
				<Box
					height="12px"
					width="24px"
					borderRadius="0 0 12px 12px"
					borderBottomWidth="1px"
					borderLeftWidth="1px"
					borderRightWidth="1px"
					borderColor="gray.15"
					bg="gray.10"
				/>
			) : null}
		</Box>
	);
};
