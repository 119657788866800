import { useQuery } from '@tanstack/react-query';
import { ApiResponse, BaseUserData, Tables } from '../types';
import { QueryKeys } from '../constants';
import { useGetWorkspaceId } from '../hooks';
import { useSupabaseWithAuth } from '../state/auth/context';

export const useGetWorkspaceMembers = (
	isEnabled?: boolean,
	overrideWorkspaceId?: number,
) => {
	const workspaceId = useGetWorkspaceId();
	const supabase = useSupabaseWithAuth();

	return useQuery<ApiResponse<{ members: BaseUserData[] }>>({
		queryKey: [
			QueryKeys.GET_WORKSPACE_MEMBERS,
			overrideWorkspaceId || workspaceId,
		],
		queryFn: async () => {
			const filterByWorkspaceId = overrideWorkspaceId || workspaceId;
			if (!filterByWorkspaceId) {
				throw new Error('Workspace ID is missing');
			}
			type WorkspaceUserWithAvatars = Tables<"workspace_users"> & {
				users: Tables<"users"> & {
					avatar_id: {
						storage_path: string;
					};
					thumbnail_id: {
						storage_path: string;
					};
				};
			};

			const { data, error } = await supabase
				.from('workspace_users')
				.select(
					`
					users (
						id,
						first_name,
						last_name,
						telegram_user_id,
						avatar_id (storage_path),
						thumbnail_id (storage_path)
					)
				 `,
				)
				.eq('workspace_id', filterByWorkspaceId)
				.eq('is_removed', false)
				.returns<WorkspaceUserWithAvatars[]>();

			if (error) {
				throw error;
			}

			const members: BaseUserData[] = data.map(item => ({
				id: item.users?.id,
				firstName: item.users?.first_name,
				lastName: item.users?.last_name,
				telegramUserId: item.users?.telegram_user_id,
				avatarUrl: item.users?.avatar_id?.storage_path || null,
				avatarThumbnailUrl: item.users?.thumbnail_id?.storage_path || null,
			}));

			return {
				success: true,
				status: 200,
				value: { members },
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		enabled: !!isEnabled && (!!overrideWorkspaceId || !!workspaceId),
	});
};