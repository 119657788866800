import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ApiResponseEmpty } from '../types';
import { setUserTimeZone } from '../redux/reducers/auth.reducer';
import { RootStore } from '../redux/createStore';
import { Alerter } from '../utils';
import { useSupabaseWithAuth } from '../state/auth/context';

export const useSetUserTimeZone = () => {
	const supabase = useSupabaseWithAuth();
	const dispatch = useDispatch();
	const { id: userId } = useSelector((state: RootStore) => state.auth.user);

	return useMutation<ApiResponseEmpty, unknown, string>({
		mutationFn: async (timeZoneId: string) => {
			const { status, error } = await supabase
				.from('users')
				.update({ time_zone_id: timeZoneId })
				.eq('id', userId);

			if (error) {
				throw error;
			}

			return {
				success: true,
				status: status,
				errors: [],
				messages: [],
			};
		},
		onSuccess: (_, timeZoneId) => {
			dispatch(setUserTimeZone(timeZoneId));
		},
		onError: error => {
			Alerter.error('Error updating time zone');
			console.error('Error updating time zone:', error);
		},
	});
};
