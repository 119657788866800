import React, { useMemo } from 'react';
import { Flex, Heading, Text, VStack, chakra } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetNotifications } from '../queries';
import { NotificationStatusEnum } from '../types';
import { Loader } from '../../../components';
import { DateDivider, NotificationItem } from '../components';

interface NotificationsPanelTemplateTabsProps {
	filterId: NotificationStatusEnum;
}

export const NotificationsPanelTemplateTabs: React.FC<
	NotificationsPanelTemplateTabsProps
> = ({ filterId }) => {
	const { data, isPending, hasNextPage, fetchNextPage } = useGetNotifications(
		filterId === NotificationStatusEnum.ARCHIVED,
	);

	const { notificationsGroups, totalCount } = useMemo(() => {
		const totalCount =
			data?.pages?.[data?.pages.length - 1]?.totalCount || 0;
		const notificationsGroups = data?.pages?.flatMap(
			page => page.groupedNotifications || [],
		);
		return { notificationsGroups, totalCount };
	}, [data?.pages]);

	if (isPending) {
		return <Loader centerHeight="calc(100vh - 170px)" />;
	}

	if (!notificationsGroups?.length) {
		return (
			<Flex justify="center" align="center" h="calc(100vh - 160px)">
				<Text fontSize="24px" fontWeight={600}>
					There are no notifications here yet
				</Text>
			</Flex>
		);
	}

	return (
		<>
			<Heading color="primary" fontSize="16px" fontWeight="600">
				New notifications
				<chakra.span color="gray.35" ml={2}>
					{totalCount}
				</chakra.span>
			</Heading>
			<InfiniteScroll
				dataLength={notificationsGroups.length}
				pullDownToRefresh={false}
				refreshFunction={fetchNextPage}
				next={fetchNextPage}
				hasMore={hasNextPage}
				height="100%"
				inverse={false}
				scrollThreshold={1}
				loader={null}
				style={{
					maxHeight: 'calc(100vh - 176px)',
					overflowY: 'auto',
					marginTop: '16px',
				}}>
				<VStack
					w="full"
					align="flex-start"
					spacing={4}
					borderRadius="8px">
					{notificationsGroups.map((notificationGroup, index) => (
						<React.Fragment key={index}>
							<DateDivider date={notificationGroup.date} />

							<VStack
								w="full"
								borderColor="gray.20"
								borderRadius="8px"
								background="gray.10"
								borderWidth="1px"
								overflow="hidden"
								spacing={0}>
								{notificationGroup.notifications?.map(
									notification => (
										<NotificationItem
											key={notification.id}
											date={notificationGroup.date}
											isInboxTab={
												filterId ===
												NotificationStatusEnum.INBOX
											}
											{...notification}
										/>
									),
								)}
							</VStack>
						</React.Fragment>
					))}
				</VStack>
				{/* {isFetchingNextPage ? (
						<Loader centerHeight="48px" spinnerSize="sm" />
					) : null} */}
			</InfiniteScroll>
		</>
	);
};
