import { useQuery } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import { ApiResponse } from '../../../../../types';
import { TemplatesResponse } from '../../../types';
import { QueryKeys } from '../../../../../constants';
import { useGetWorkspaceId } from '../../../../../hooks';

export const useGetTemplates = () => {
	const workspaceId = useGetWorkspaceId();

	return useQuery<ApiResponse<TemplatesResponse>>({
		queryKey: [QueryKeys.GET_TEMPLATES, workspaceId],
		queryFn: () =>
			API.get(`/api/workspaces/${workspaceId}/templates/get-list`, {}),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId,
	});
};
