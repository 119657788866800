import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';

export const useSetTeamChat = () => {
	const queryClient = useQueryClient();
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ workspaceId: number; telegramChatId: number; name: string }
	>({
		mutationKey: [QueryKeys.SET_TEAM_CHAT],
		mutationFn: payload => {
			const { workspaceId, ...bodyPayload } = payload;
			return API.post(
				`/api/workspaces/${workspaceId}/team-chat`,
				bodyPayload,
			);
		},

		onSuccess: data => {
			if (!data.success) {
				return Alerter.error(
					data?.errors?.[0].message ||
						'Error when adding command chat',
				);
			}
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_CURRENT_USER],
			});
			Alerter.success('The team chat has been successfully added!');
		},
	});
};
