import React from 'react';
import { StackProps, Text, TextProps, VStack } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { IndicatorTypeEnum } from '../../types';
import { colors } from '../../theme/colors';

interface EventsIndicatorProps {
	count?: number;
	textProps?: TextProps;
	wrapperProps?: StackProps;
	isMention?: boolean;
	type?: IndicatorTypeEnum;
}

export const EventsIndicator: React.FC<EventsIndicatorProps> = ({
	count,
	textProps,
	wrapperProps,
	type = IndicatorTypeEnum.COUNT,
}) => {
	if (!count) {
		return null;
	}

	const renderIndicator = (type: IndicatorTypeEnum) => {
		switch (type) {
			case IndicatorTypeEnum.MENTION:
				return (
					<Icon
						name="at"
						width="9px"
						height="9px"
						color={colors.secondary}
					/>
				);

			default:
				return (
					<Text
						fontSize="10px"
						lineHeight="12px"
						fontWeight={600}
						{...textProps}>
						{count > 99 ? '99+' : count}
					</Text>
				);
		}
	};
	return (
		<VStack
			p="1px 3px"
			borderRadius="20px"
			minW="14px"
			align="center"
			justify="center"
			spacing={0}
			{...wrapperProps}>
			{renderIndicator(type)}
		</VStack>
	);
};
