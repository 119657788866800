import React from 'react';
import { Flex, FormLabel } from '@chakra-ui/react';
import { RadixCheckbox } from '../../../components';

interface IFolderItemProps {
	name: string;
	value: string;
	checked: boolean;
	onCheckedChange: (value: boolean) => void;
}

export const FolderItem: React.FC<IFolderItemProps> = ({
	name,
	value,
	checked,
	onCheckedChange,
}) => {
	return (
		<Flex
			p={'20px'}
			w={'100%'}
			borderBottom={'1px solid'}
			borderColor="gray.20"
			align="center"
			gap="16px"
			_last={{ borderBottom: 'none' }}>
			<RadixCheckbox
				name={name}
				value={value}
				checked={checked}
				onCheckedChange={onCheckedChange}
				id={`check-${value}`}
			/>
			<FormLabel
				color="primary"
				m={0}
				htmlFor={`check-${value}`}
				fontSize="13px"
				fontWeight={500}>
				{name}
			</FormLabel>
		</Flex>
	);
};
