import axios, { AxiosRequestConfig } from 'axios';
import { ApiResponse } from '../types';
import { store } from '../redux/createStore';
import { setAuthHeaderToken, handleAxiosError } from '../utils';

type KeyValue<U> = {
	[key: string]: U;
};

class Api<T> {
	async get(url: string, params: any, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.get(url, {
				params,
				headers: {
					...headers,
				},
			})
			.catch(err => handleAxiosError(err));
		return response.data;
	}

	async post(
		url: string,
		body: T,
		config: AxiosRequestConfig = {},
	): Promise<ApiResponse<any>> {
		const response = await axios
			.post(url, body, config)
			.catch(err => handleAxiosError(err));

		return response.data;
	}

	async put(url: string, body: T, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.put(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleAxiosError(err));
		return response.data;
	}

	async patch(url: string, body: T, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.patch(url, body, {
				headers: {
					...headers,
				},
			})
			.catch(err => handleAxiosError(err));
		return response.data;
	}

	async delete(url: string, body?: T, headers: KeyValue<string> = {}) {
		const token = store.getState().auth.token.accessToken;
		setAuthHeaderToken(token);
		const response = await axios
			.delete(url, {
				data: body,
				headers,
			})
			.catch(err => handleAxiosError(err));
		return response.data;
	}
}

export default new Api();
