import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { Icon } from '../../../components';

interface IPipelineControlButtonsProps {
	isSaveLoading: boolean;
	onCancel: VoidFunction;
	isActive: boolean;
}

export const PipelineControlButtons: React.FC<IPipelineControlButtonsProps> = ({
	isSaveLoading,
	onCancel,
	isActive,
}) => {
	return (
		<Flex alignItems="center" justify="flex-end" gap="4px" mb="32px">
			{isActive ? (
				<Button
					variant="link"
					fontSize="13px"
					color="tertiary"
					width="59px"
					height="24px"
					fontWeight={400}
					onClick={onCancel}>
					Cancel
				</Button>
			) : null}
			<Button
				variant="popup"
				iconSpacing="4px"
				minW="auto"
				px="8px"
				type="submit"
				isDisabled={!isActive}
				isLoading={isSaveLoading}
				leftIcon={
					<Icon
						name="floppy"
						height="14px"
						width="14px"
						color="currentColor"
					/>
				}>
				Save
			</Button>
		</Flex>
	);
};
