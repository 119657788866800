import { ApiResponse } from './../types/api.types';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants';
import { Tables } from '../types';
import { useAuthContext, useSupabaseWithAuth } from '../state/auth/context';
import { RootStore } from '../redux/createStore';
import { useSelector } from 'react-redux';

type Workspace = {
	id: number;
	name: string;
	avatarUrl: string;
	avatarThumbnailUrl: string;
};

export const useGetWorkspaces = () => {
	const supabase = useSupabaseWithAuth();
	const { state } = useAuthContext();
	const user = useSelector((state: RootStore) => state.auth.user);

	return useQuery<ApiResponse<Workspace[] | null>>({
		queryKey: [QueryKeys.GET_WORKSPACES],
		queryFn: async () => {
			if (state.type !== 'authed') {
				return {
					success: false,
					status: 400,
					value: null,
					errors: [
						{
							key: 'not_authed',
							message: 'User is not authenticated',
						},
					],
					messages: [],
				} as ApiResponse<Workspace[] | null>;
			}
			type WorkspaceWithStoragePaths = Tables<'workspaces'> & {
				avatar: { storage_path: string } | null;
				thumbnail: { storage_path: string } | null;
			};
			const { data, error, status } = await supabase
				.from('workspaces')
				.select(
					`
					id,
					name,
					avatar:avatar_id(storage_path), 
					thumbnail:thumbnail_id(storage_path)
				`,
				)
				.eq('owner_id', user.id)
				.returns<WorkspaceWithStoragePaths[]>();

			if (error) {
				return {
					success: false,
					status: status,
					value: null,
					errors: [{ key: error.code, message: error.message }],
					messages: [],
				} as ApiResponse<Workspace[] | null>;
			}
			const result = data?.map(
				item =>
					({
						id: item.id,
						name: item.name,
						avatarUrl: item.avatar?.storage_path,
						avatarThumbnailUrl: item.thumbnail?.storage_path,
					}) as Workspace,
			);
			return {
				success: true,
				status: status,
				value: result,
				errors: [],
				messages: [],
			} as ApiResponse<Workspace[] | null>;
		},
		refetchOnWindowFocus: false,
		enabled: state.type === 'authed',
	});
};
