import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApiResponse, CardDefaultStatusEnum, CardTaskStatusEnum } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { useGetMatchedWorkspaceId } from '../hooks';
import { useSupabaseWithAuth } from '../state/auth/context';

export const useGetUserTasksCount = (includeRefetchInterval: boolean) => {
	const workspaceId = useGetMatchedWorkspaceId();
	const { isOnboarded, id: currentUserId } = useSelector((state: RootStore) => state.auth.user);
	const supabase = useSupabaseWithAuth();
	
	return useQuery<ApiResponse<number>>({
		queryKey: [QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
		queryFn: async () => {
			if (!workspaceId || !currentUserId) {
				throw new Error('Workspace ID or User ID is missing');
			}

			// Get tasks count
			const { count, error, status, statusText } = await supabase
				.from('card_tasks')
				.select(
					` 
					id,
					user_id,
					workspace_cards(
						id,
						workspace_id, 
						card_status_id, 
						is_archived
					)
				`,
					{ count: 'exact', head: true },
				)
				.eq('user_id', currentUserId)
				.eq('status', CardTaskStatusEnum.TODO)
				.eq('workspace_cards.workspace_id', workspaceId)
				.neq('workspace_cards.card_status_id', CardDefaultStatusEnum.ARCHIVE)
				.eq('workspace_cards.is_archived', false);

			console.error('useGetUserTasksCount', error, status, statusText);

			if (error) {
				throw error;
			}

			return {
				success: true,
				status: 200,
				value: count || 0,
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && isOnboarded,
		refetchInterval: includeRefetchInterval ? 1000 * 30 : false, // refetch data every 30s if includeRefetchInterval is true
	});
};