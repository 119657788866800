import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const createProfileSchema = yup.object().shape({
	firstName: yup.string().required('This field is required'),
	lastName: yup.string().required('This field is required'),
});

export type CreateProfileForm = yup.InferType<typeof createProfileSchema>;
export const createProfileResolver = yupResolver(createProfileSchema);
