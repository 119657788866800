import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse } from '../../../../../types';
import API from '../../../../../api/api.base';
import { QueryKeys } from '../../../../../constants';
import { ChatResponse } from '../../../types';

const PAGE_SIZE = 50;

const fetchMessages = async ({
	pageParam = 0,
	workspaceId = 0,
	cardId = 0,
	additionalOffset = 0,
}) => {
	const res: ApiResponse<ChatResponse> = await API.get(
		`/api/workspaces/${workspaceId}/cards/${cardId}/chat`,
		{
			Limit: PAGE_SIZE,
			Offset: PAGE_SIZE * pageParam,
			AdditionalOffset: additionalOffset,
		},
	);

	if (
		(!res.value &&
			res.errors?.[0]?.message === 'Workspace Card not found!') ||
		(!res.value && res.status === 404)
	) {
		return {
			count: 0,
			messages: [],
			unreadCount: 0,
			userLastReadDateTime: '1970-01-01T00:00:00Z', // from default new chat response
			pageParam,
			notExists: true,
		};
	}

	return { ...res.value, pageParam };
};

export const useInfiniteChatMessages = (
	workspaceId?: number,
	cardId?: number,
	additionalOffset?: number,
) => {
	return useInfiniteQuery({
		initialPageParam: 0,
		queryKey: [QueryKeys.GET_CHAT_MESSAGES, cardId, workspaceId],
		queryFn: ({ pageParam }) =>
			fetchMessages({
				pageParam,
				workspaceId,
				cardId,
				additionalOffset,
			}),
		getNextPageParam: lastPage => {
			return lastPage?.messages?.length === PAGE_SIZE
				? lastPage.pageParam + 1
				: null;
		},
		refetchOnWindowFocus: false,
		refetchOnMount: true,
		enabled: !!workspaceId,
	});
};
