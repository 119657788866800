import { lettersDiacritics } from './diacritics';

const removeAccents = (str: string) => {
	let formattedStr = str;

	lettersDiacritics.forEach(letterDiacritics => {
		formattedStr = formattedStr.replace(
			letterDiacritics.letters,
			letterDiacritics.base,
		);
	});

	return formattedStr;
};

export const normalizeString = (str: string) =>
	removeAccents(str).toLowerCase();

export const getSubstringIndex = (
	str: string,
	substr: string,
	ignoreAccents: boolean,
) => {
	console.log('getSubstringIndex', str, substr);
	if (!ignoreAccents) {
		return str.toLowerCase().indexOf(substr.toLowerCase());
	}

	return normalizeString(str).indexOf(normalizeString(substr));
};
