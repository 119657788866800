import React from 'react';
import { HStack, Text, Avatar } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { useGetChatInfo } from '../../../hooks';

export const MiniTeamChatCard: React.FC = () => {
	const { user } = useSelector((state: RootStore) => state.auth);

	const avatarUri = useGetChatInfo({
		chatId: user.workSpace?.teamChat?.id,
		includeUsernamesFetch: true,
		isEnabled: !!user.workSpace?.teamChat?.id,
	});

	return (
		<HStack
			spacing="4px"
			p="0px 16px"
			borderRadius="4px"
			bg="#00000005"
			border="1px solid"
			borderColor="gray.20"
			h="32px">
			<Avatar
				boxSize="14px"
				borderRadius="2px"
				name={user.workSpace?.teamChat?.name || ''}
				src={user.workSpace?.teamChat && avatarUri ? avatarUri : ''}
				sx={{
					'> div': {
						fontSize: '8px',
					},
				}}
			/>
			{user.workSpace?.teamChat?.name ? (
				<Text color="primary" fontSize="13px" fontWeight="500">
					{user.workSpace.teamChat.name}
				</Text>
			) : null}
		</HStack>
	);
};
