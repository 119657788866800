type NavLinkItem = {
	id: number;
	name: string;
	path: string;
	iconName: string;
	isSoon: boolean;
};

export const getNavLinkItems = (workspaceId: number): NavLinkItem[] => [
	{
		id: 1,
		name: 'Dashboard',
		path: `/${workspaceId}`,
		iconName: 'kanban',
		isSoon: false,
	},
	{
		id: 2,
		name: 'Tasks',
		path: `/${workspaceId}/tasks`,
		iconName: 'checkbox',
		isSoon: false,
	},
	{
		id: 3,
		name: 'Notifications',
		path: `/${workspaceId}/notifications`,
		iconName: 'bell',
		isSoon: false,
	},
	{
		id: 4,
		name: 'Batch Send',
		path: `/${workspaceId}/batch-send`,
		iconName: 'batch-send',
		isSoon: true,
	},
	{
		id: 5,
		name: 'Settings',
		path: `/${workspaceId}/settings`,
		iconName: 'settings',
		isSoon: false,
	},
];

export const getSettingsNavItems = (workspaceId: number): NavLinkItem[] => [
	{
		id: 1,
		name: 'General',
		path: `/${workspaceId}/settings`,
		iconName: 'settings',
		isSoon: false,
	},
	{
		id: 2,
		name: 'Members',
		path: `/${workspaceId}/settings/members`,
		iconName: 'users',
		isSoon: false,
	},
	{
		id: 3,
		name: 'Billing',
		path: `/${workspaceId}/settings/billing`,
		iconName: 'credit-card',
		isSoon: true,
	},
	{
		id: 4,
		name: 'Pipeline',
		path: `/${workspaceId}/settings/pipeline`,
		iconName: 'table-import',
		isSoon: false,
	},
	{
		id: 5,
		name: 'Activity',
		path: `/${workspaceId}/settings/activity`,
		iconName: 'progress-check',
		isSoon: true,
	},
];
