import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../constants';
import { CardTaskItem } from '../../Chat/types';

export const useClearTasksData = () => {
	const queryClient = useQueryClient();

	const clearTasksQueryData = (key: QueryKeys, workspaceId: number) =>
		queryClient.setQueryData(
			[key, workspaceId],
			(oldData: {
				pageParams: number[];
				pages: { count: number; tasks: CardTaskItem[] }[];
			}) => {
				if (oldData) {
					return {
						pageParams: [oldData.pageParams[0]],
						pages: [oldData.pages[0]],
					};
				}

				return oldData;
			},
		);

	return { clearTasksQueryData };
};
