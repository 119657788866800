import React, { useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Loader } from '../../components';
import { getBoardTabs } from './constants';
import { BoardTab, LabelsSelectPopup } from './components';
import { DashboardPanelTemplateTabs } from './tabs';
import { useGetBoardCards } from './queries';
import { RootStore } from '../../redux/createStore';
import { useUserAvatars, useGetWorkspaceId } from '../../hooks';
import { BoardFilterValue } from './types';
import { ApiResponse } from '../../types';
import { QueryKeys } from '../../constants';
import {
	getUnreadCards,
	getUnansweredCards,
	getMentionedCards,
	getActiveTasksCards,
	getTeamActivityCards,
} from '../../utils';
import { DefaultPageHeader } from '../../widgets';

const DashboardPage: React.FC = () => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	const { workSpace } = useSelector((state: RootStore) => state.auth.user);
	const chatsCache = useSelector(
		(state: RootStore) => state.telegramState.chats,
	);
	const activeLabelId = useSelector(
		(state: RootStore) => state.dashboardState.activeLabelId,
	);

	const { data, isLoading } = useGetBoardCards();

	const teamTelegramIds = queryClient.getQueryData<
		ApiResponse<{ telegramUserIds: number[] }>
	>([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

	useUserAvatars(teamTelegramIds?.value?.telegramUserIds);

	const [tabIndex, setTabIndex] = useState(BoardFilterValue.All);

	const renderTabs = useMemo(
		() =>
			getBoardTabs({
				unread:
					getUnreadCards({
						cards: data?.value?.cards || [],
						tgChats: chatsCache || {},
						activeLabelId,
					}).length || 0,

				unanswered:
					getUnansweredCards({
						cards: data?.value?.cards || [],
						tgChats: chatsCache || {},
						teamTelegramIds:
							teamTelegramIds?.value?.telegramUserIds || [],
						activeLabelId,
					}).length || 0,
				activeTasks:
					getActiveTasksCards({
						cards: data?.value?.cards || [],
						activeLabelId,
					}).length || 0,
				teamActivity:
					getTeamActivityCards({
						cards: data?.value?.cards || [],
						activeLabelId,
					}).length || 0,

				mentions:
					getMentionedCards({
						cards: data?.value?.cards || [],
						activeLabelId,
					}).length || 0,
			}).map((tab, index) => (
				<BoardTab
					key={tab.id}
					isActive={tab.filterValue === tabIndex}
					onClick={() => setTabIndex(index)}
					{...tab}
				/>
			)),
		[
			tabIndex,
			data,
			activeLabelId,
			chatsCache,
			teamTelegramIds?.value?.telegramUserIds,
		],
	);

	return (
		<Box>
			<DefaultPageHeader
				title="Main Pipeline"
				iconName="users"
				isSyncSettingsEnabled={true}
				isInviteMembersEnabled={true}
			/>
			{isLoading ? (
				<Loader centerHeight="calc(100vh - 68px)" />
			) : (
				<Box
					borderWidth="1px"
					borderColor="gray.20"
					borderRadius="8px"
					bg="white"
					h="calc(100dvh - 68px)">
					<Flex
						columnGap={4}
						p={2}
						overflowX="auto"
						borderBottomWidth="1px"
						borderBottomColor="gray.15">
						{renderTabs}
						<LabelsSelectPopup />
					</Flex>
					<DashboardPanelTemplateTabs
						tabIndex={tabIndex}
						data={data?.value?.cards || []}
						workspaceId={workspaceId}
						tgChatsData={chatsCache}
						isArchiveVisible={workSpace?.isArchiveVisible}
						teamTelegramIds={
							teamTelegramIds?.value?.telegramUserIds
						}
						activeLabelId={activeLabelId}
					/>
				</Box>
			)}
		</Box>
	);
};

export default DashboardPage;
