const colors = [
	'#E54D2E',
	'#E93D82',
	'#AB4ABA',
	'#5B5BD6',
	'#0090FF',
	'#12A594',
	'#46A758',
];

export function getAvatarBg(id: number) {
	return colors[Math.abs(id) % colors.length];
}
