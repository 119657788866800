/* eslint-disable quotes  */

export const limitedAccessCardsBoxStyles = {
	_after: {
		position: 'absolute',
		content: `''`,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'white',
		borderRadius: '4px',
		border: '1px solid #ebebeb',
		boxShadow: '0px 0px 4px 0px #0000000A',
	},
};
