import React from 'react';
import { ListItem, Text, Avatar, Box } from '@chakra-ui/react';

interface ISuggestionProps {
	id: string;
	focused: boolean;
	suggestion: {
		name: string;
		id: string;
		username: string | null;
		photoURL?: string;
	};
	onClick: VoidFunction;
	onMouseEnter: VoidFunction;
}

export const Suggestion: React.FC<ISuggestionProps> = ({
	id,
	focused,
	onClick,
	onMouseEnter,
	suggestion,
}) => {
	const rest = { onClick, onMouseEnter };

	return (
		<ListItem
			id={id}
			role="option"
			aria-selected={focused}
			borderRadius="4px"
			display="flex"
			alignItems="center"
			bg={focused ? 'transparentLight.5' : 'white'}
			cursor="pointer"
			p="6px 4px"
			gap={2}
			_hover={{
				bg: 'transparentLight.5',
			}}
			{...rest}>
			<Box
				width="14px"
				minW="14px"
				height="14px"
				borderRadius="50%"
				bg="blue.DEFAULT">
				{suggestion.photoURL ? (
					<Avatar
						width="14px"
						height="14px"
						src={suggestion.photoURL}
					/>
				) : null}
			</Box>
			<Text
				fontSize="13px"
				lineHeight="20px"
				color="secondary"
				isTruncated={true}>
				{suggestion.name}
			</Text>

			{suggestion.username ? (
				<Text
					fontSize="13px"
					lineHeight="20px"
					color="gray.40"
					isTruncated={true}>
					@{suggestion.username}
				</Text>
			) : null}
		</ListItem>
	);
};
