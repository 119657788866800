import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@chakra-ui/react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { KanbanBoardColumn } from '../components';
import { BoardCardItemResponse, BoardFilterValue } from '../types';
import {
	useGetWorkspaceCardsStatuses,
	useUpdateWorkspaceCardStatus,
} from '../../../queries';
import {
	CardDefaultStatusEnum,
	CardStatusItem,
	TgDataForCard,
} from '../../../types';
import { useUpdateBoardCardData } from '../hooks';
import { useUpdateArchiveVisibility } from '../queries';
import {
	getUnreadCards,
	getUnansweredCards,
	getMentionedCards,
	getAllCards,
	getTeamActivityCards,
	getActiveTasksCards,
} from '../../../utils';

interface DashboardPanelTemplateTabsProps {
	tabIndex: BoardFilterValue;
	data: BoardCardItemResponse[];
	workspaceId?: number;
	tgChatsData: Record<string, TgDataForCard>;
	isArchiveVisible?: boolean;
	teamTelegramIds?: number[];
	activeLabelId: number;
}

const parseArrayByStatusId = (
	cards: BoardCardItemResponse[],
	statuses: CardStatusItem[],
) => {
	const cardsByStatus: { [key: string]: BoardCardItemResponse[] } = {};

	statuses.forEach(status => {
		cardsByStatus[status.id] = [];
	});

	// Group cards by status ID
	cards.forEach(card => {
		const statusId = card.status.id;
		if (cardsByStatus.hasOwnProperty(statusId)) {
			cardsByStatus[statusId].push(card);
		}
	});

	return cardsByStatus;
};

const isObjEmpty = (obj: { [key: string]: BoardCardItemResponse[] }) =>
	Object.keys(obj).length === 0;

export const DashboardPanelTemplateTabs: React.FC<
	DashboardPanelTemplateTabsProps
> = ({
	tabIndex,
	data,
	workspaceId,
	tgChatsData,
	isArchiveVisible,
	teamTelegramIds,
	activeLabelId,
}) => {
	const { data: statusesData } = useGetWorkspaceCardsStatuses();
	const { updateBoardCardData } = useUpdateBoardCardData();
	const { mutateAsync: updateWorkspaceCardStatus } =
		useUpdateWorkspaceCardStatus();
	const { mutateAsync: updateArchiveVisibility } =
		useUpdateArchiveVisibility();

	const statuses = useMemo(
		() => statusesData?.value?.statuses,
		[statusesData],
	);
	const tasksDefault = useMemo(
		() => parseArrayByStatusId(data, statuses || []),
		[data, statuses],
	);

	const [tasks, setTasks] = useState<{
		[key: string]: BoardCardItemResponse[];
	}>(tasksDefault);
	const [prevData, setPrevData] = useState(JSON.stringify(data));

	useEffect(() => {
		if (!isObjEmpty(tasksDefault) && isObjEmpty(tasks)) {
			setTasks(tasksDefault);
		}
	}, [tasksDefault, tasks]);

	if (JSON.stringify(data) !== prevData) {
		setPrevData(JSON.stringify(data));
		setTasks(tasksDefault);
	}

	const onDragEnd = async (result: DropResult) => {
		const { source, destination } = result;

		// If dropped outside the droppable area or in the same column
		if (
			!destination ||
			!workspaceId ||
			source.droppableId === destination.droppableId
		) {
			return;
		}

		const sourceTasks = tasks[source.droppableId];
		const destinationTasks = tasks[destination.droppableId];
		const movedTask = sourceTasks.splice(source.index, 1)[0];
		destinationTasks.splice(destination.index, 0, movedTask);

		const res = await updateWorkspaceCardStatus({
			workspaceId,
			cardId: movedTask.cardId,
			statusId: +destination.droppableId,
		});

		if (!res.success) {
			return;
		}

		setTasks({
			...tasks,
			[source.droppableId]: sourceTasks,
			[destination.droppableId]: destinationTasks,
		});
		updateBoardCardData(movedTask.cardId, +destination.droppableId);
	};

	const getFilteredCards = useCallback(
		(
			items: BoardCardItemResponse[],
			tgChats?: Record<string, TgDataForCard>,
			teamTelegramIds?: number[],
		) => {
			switch (tabIndex) {
				case BoardFilterValue.All:
					return getAllCards({ cards: items || [], activeLabelId });
				case BoardFilterValue.Unread:
					return (
						getUnreadCards({
							cards: items || [],
							tgChats: tgChats || {},
							activeLabelId,
						}) || []
					);
				case BoardFilterValue.Unanswered:
					return (
						getUnansweredCards({
							cards: items || [],
							tgChats: tgChats || {},
							teamTelegramIds: teamTelegramIds || [],
							activeLabelId,
						}) || []
					);
				case BoardFilterValue.ActiveTasks:
					return (
						getActiveTasksCards({
							cards: items || [],
							activeLabelId,
						}) || []
					);
				case BoardFilterValue.TeamActivity:
					return (
						getTeamActivityCards({
							cards: items || [],
							activeLabelId,
						}) || []
					);
				case BoardFilterValue.Mentions:
					return (
						getMentionedCards({
							cards: items || [],
							activeLabelId,
						}) || []
					);
				default:
					return [];
			}
		},
		[tabIndex, activeLabelId],
	);

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Grid
				templateColumns={`repeat(${statuses?.length}, minmax(280px, 1fr))`}
				w="100%"
				h="calc(100vh - 118px)"
				overflow="auto">
				{statuses?.map((status, index) => {
					const isArchiveColumn =
						status.typeId === CardDefaultStatusEnum.ARCHIVE;

					return (
						<KanbanBoardColumn
							key={status.id}
							tasks={getFilteredCards(
								tasks[`${status.id}`],
								tgChatsData,
								teamTelegramIds,
							)}
							tabIndex={tabIndex}
							columnStatus={status}
							gridProps={{
								borderRightWidth:
									index === statuses.length - 1
										? '0px'
										: '1px',
							}}
							tgChatsData={tgChatsData}
							enableColumnIcon={isArchiveColumn}
							isColumnIconActive={
								isArchiveColumn ? isArchiveVisible : false
							}
							onClickColumnIcon={() => {
								if (workspaceId && isArchiveColumn) {
									updateArchiveVisibility({
										workspaceId,
										isArchiveVisible: !isArchiveVisible,
									});
								}
							}}
							teamTgIds={teamTelegramIds || []}
						/>
					);
				})}
			</Grid>
		</DragDropContext>
	);
};
