import { useInfiniteQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import { CardTaskItem } from '../../Chat/types';

const PAGE_SIZE = 30;

export const useGetAllCurrentUserTasks = (
	workspaceId?: number | null,
	filterStatusId?: 1 | 2 | 3,
	// offset?: number,
) => {
	const fetchTasks = async ({ pageParam = 0 }) => {
		const res: ApiResponse<{
			count: number;
			tasks: CardTaskItem[];
		}> = await API.get(
			`/api/workspaces/${workspaceId}/tasks-screen/get-yours-list`,
			{
				FilterStatus: filterStatusId,
				Offset: pageParam * PAGE_SIZE,
				Limit: PAGE_SIZE,
			},
		);

		return { ...res.value, pageParam };
	};

	return useInfiniteQuery({
		initialPageParam: 0,
		queryKey: [QueryKeys.GET_ALL_CURRENT_USER_TASKS, workspaceId],
		queryFn: ({ pageParam }) => fetchTasks({ pageParam }),
		getNextPageParam: lastPage => {
			return lastPage?.tasks?.length === PAGE_SIZE
				? lastPage.pageParam + 1
				: null;
		},
		enabled: !!workspaceId,
		refetchOnWindowFocus: false,
	});
};
