export const countPlaceholders = (markup: string) => {
	let count = 0;
	if (markup.indexOf('__id__') >= 0) {
		count++;
	}
	if (markup.indexOf('__display__') >= 0) {
		count++;
	}
	return count;
};
