import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiResponseEmpty } from '../types';
import { QueryKeys } from '../constants';
import { Alerter } from '../utils';
import { setOnboardingVisibility } from '../redux/reducers/onboarding.reducer';
import { resetRecentStack } from '../redux/reducers/search-recent-stack.reducer';
import { RootStore } from '../redux/createStore';
import { useGetMatchedCardId } from '../hooks';
import { setActiveLabelId } from '../redux/reducers/dashboard-state.reducer';
import { useSupabaseWithAuth } from '../state/auth/context';

export const useSetWorkspace = () => {
	const supabase = useSupabaseWithAuth();
	const queryClient = useQueryClient();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const cardId = useGetMatchedCardId();
	const { workSpace } = useSelector(
		(state: RootStore) => state.auth.user,
	);

	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationKey: [QueryKeys.SET_WORKSPACE],
		mutationFn: async (workspaceId: number) => {
			const { error, status } = await supabase
				.from('workspace_users')
				.update({ is_current: false })
				.neq('workspace_id', workspaceId);

			if (error) {
				throw error;
			}

			const { error: updateError, status: updateStatus } = await supabase
				.from('workspace_users')
				.update({ is_current: true })
				.eq('workspace_id', workspaceId);

			if (updateError) {
				throw updateError;
			}

			return {
				success: true,
				status: updateStatus ?? status,
				errors: [],
				messages: [],
			};
		},
		onSuccess(data, variables) {
			if (data.success) {
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_WORKSPACES],
				});
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_CURRENT_USER],
				});
				dispatch(setOnboardingVisibility(false));
				dispatch(resetRecentStack());
				dispatch(setActiveLabelId(0));

				if (
					!pathname.includes('/chat') ||
					cardId === workSpace?.teamChat?.cardId
				) {
					navigate(`/${variables}`, { replace: true });
					return;
				}

				navigate(pathname);
			} else {
				Alerter.error(
					data.messages?.[0]?.message ||
						'Error occurred while selecting workspace',
				);
			}
		},
		onError(error) {
			console.log(error, 'SET_WORKSPACE');
			Alerter.error('Error occurred while selecting workspace');
		},
	});
};
