import React from 'react';
import {
	Flex,
	Box,
	Center,
	IconButton,
	Editable,
	EditablePreview,
	Input,
	EditableInput,
} from '@chakra-ui/react';
import { Controller, Control } from 'react-hook-form';
import { Icon } from '../../../components';
import { colors } from '../../../theme/colors';
import { PipelineForm } from '../types';
import { StageItemEditableControls } from './StageItemEditableControls';
import { PipelineDefaultStages } from '../../../constants';

interface IStageItemProps {
	index: number;
	name: string;
	control: Control<PipelineForm>;
	onDelete: VoidFunction;
}

export const StageItem: React.FC<IStageItemProps> = ({
	index,
	name,
	control,
	onDelete,
}) => {
	return (
		<Flex flexDirection="row" gap="8px" py="4px">
			<Center boxSize="24px">
				<Box
					w="12px"
					h="16px"
					bg={PipelineDefaultStages[index].color}
					borderRadius="60px"
				/>
			</Center>
			<Controller
				control={control}
				name={`stages.${index}.name`}
				render={({ field }) => (
					<Editable
						defaultValue={name || PipelineDefaultStages[index].name}
						isPreviewFocusable={false}
						display="flex"
						w="100%"
						gap="8px"
						onChange={nextValue => field.onChange(nextValue)}
						value={field.value}
						onSubmit={value => {
							if (!value) {
								field.onChange(
									PipelineDefaultStages[index].name,
								);
							}
						}}>
						<EditablePreview
							bg="#00000005"
							padding="0px 4px 0px 8px"
							borderRadius="4px"
							border="1px solid"
							borderColor="gray.20"
							w="100%"
							fontSize="13px"
							fontWeight={400}
							color="primary"
							lineHeight="22px"
						/>
						<Input
							as={EditableInput}
							height="24px"
							fontSize="13px"
							fontWeight={400}
							color="primary"
							borderRadius="4px"
							padding="0px 4px 0px 8px"
							_focusVisible={{
								border: '1px solid #1961ED',
								boxShadow: '0px 0px 0px 3px #1961ED1A',
							}}
						/>
						<StageItemEditableControls />
					</Editable>
				)}
			/>

			{index !== 0 ? (
				<IconButton
					aria-label="edit"
					variant="ghost"
					minW="auto"
					borderRadius="4px"
					onClick={onDelete}
					boxSize="24px">
					<Icon
						name="delete-red-bin"
						height="16px"
						width="16px"
						color={colors.red['DEFAULT']}
					/>
				</IconButton>
			) : (
				<Box boxSize="24px" />
			)}
			{/*<Icon*/}
			{/*	name="edit"*/}
			{/*	height="16px"*/}
			{/*	width="16px"*/}
			{/*	color={colors.secondary}*/}
			{/*/>*/}
		</Flex>
	);
};
