import React, { useEffect, PropsWithChildren, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootStore } from '../../redux/createStore';
import { useGetWorkspaces, useSetWorkspace } from '../../queries';
import { useGetWorkspaceId } from '../../hooks';

export const AuthWrapper: React.FC<PropsWithChildren> = ({ children }) => {
	const navigate = useNavigate();
	const workspaceId = useGetWorkspaceId();
	const setWorkspaceMutation = useSetWorkspace();

	const { data: workspacesData } = useGetWorkspaces();
	const { isOpen } = useSelector((state: RootStore) => state.onboarding);
	const initialWorkspaceSelectedRef = useRef(false);

	const { user } = useSelector((state: RootStore) => state.auth);

	useEffect(() => {
		if (workspacesData) {
			if (!workspacesData.value?.find(elem => elem.id === workspaceId)) {
				navigate('/select-workspace', { replace: true });
			} else if (
				workspaceId !== user.workSpace?.id &&
				!isOpen &&
				!initialWorkspaceSelectedRef.current
			) {
				setWorkspaceMutation.mutate(workspaceId);
			}
			initialWorkspaceSelectedRef.current = true;
		}
	}, [workspaceId, workspacesData, user.workSpace?.id]);

	return <>{children}</>;
};
