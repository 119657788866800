/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Box, Button, Text, Flex, HStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { TeamChatSelector } from './components';
import { InputText } from '../../components';
import {
	modalSubHeaderStyleProps,
	settingsRegularTextStyleProps,
} from '../../modals/Onboarding/steps/helper';
import { UploadWorkspaceAvatar } from '../../modals/Onboarding/components';
import {
	useGetWorkspaceInfo,
	useUpdateWorkspaceLogo,
	useUpdateWorkspaceInfo,
} from './queries';
import {
	updateWorkspaceResolver,
	UpdateWorkspaceForm,
} from '../../modals/Onboarding/validation';

const SettingsPage: React.FC = () => {
	const [imageFromFile, setImageFromFile] = useState('');

	const { data: workspaceData, isFetching } = useGetWorkspaceInfo();
	const updateWorkspaceInfoMutation = useUpdateWorkspaceInfo();
	const updateWorkspaceLogoMutation = useUpdateWorkspaceLogo(1);
	const deleteWorkspaceLogoMutation = useUpdateWorkspaceLogo(2);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm<UpdateWorkspaceForm>({ resolver: updateWorkspaceResolver });

	const showImage = (file: File) => {
		const imageDataUrl = URL.createObjectURL(file);
		setImageFromFile(imageDataUrl);
		const fd = new FormData();
		fd.append('NewImage', file);
		updateWorkspaceLogoMutation.mutate(fd);
	};

	const removeImage = () => {
		deleteWorkspaceLogoMutation.mutate(null, {
			onSuccess(data) {
				if (data.success) {
					setImageFromFile('');
				}
			},
		});
	};

	const onSubmitName = (data: UpdateWorkspaceForm) => {
		updateWorkspaceInfoMutation.mutate(data.name, {
			onSuccess(data, variables) {
				if (data.success) {
					reset({ name: variables });
				}
			},
		});
	};

	const nameWatch = watch('name');

	useEffect(() => {
		if (workspaceData?.value?.workspaceName) {
			reset({ name: workspaceData?.value?.workspaceName });
		}
	}, [workspaceData?.value, reset]);

	useEffect(() => {
		if (workspaceData?.value?.logoUrl && !isFetching) {
			setImageFromFile(workspaceData?.value?.logoUrl);
		}
	}, [workspaceData?.value, reset, isFetching]);

	return (
		<Box h="100vh" py="12px">
			<Box
				bg="white"
				h="100%"
				borderRadius="8px"
				border="1px solid"
				borderColor="gray.20"
				p="32px">
				<Flex flexDirection="column" gap="32px" maxW="container.sm">
					<Text color="tertiary" fontSize="16px" fontWeight="600">
						Workspace
					</Text>
					<UploadWorkspaceAvatar
						imageFromFile={imageFromFile}
						removeImage={removeImage}
						showImage={showImage}
						title="Company logo"
						removeLoading={deleteWorkspaceLogoMutation.isPending}
						uploadLoading={updateWorkspaceLogoMutation.isPending}
					/>
					<Box>
						<Text {...modalSubHeaderStyleProps}>Company name</Text>
						<Text
							{...settingsRegularTextStyleProps}
							mt="12px"
							mb="16px">
							The name of your workspace. Will be used to name new
							groups
						</Text>
						<form onSubmit={handleSubmit(onSubmitName)}>
							<HStack align="flex-start" mt="16px">
								<InputText
									{...register('name')}
									errorMsg={errors?.name?.message}
								/>
								{nameWatch !==
								workspaceData?.value?.workspaceName ? (
									<Button
										type="submit"
										isLoading={
											updateWorkspaceInfoMutation.isPending
										}>
										Save
									</Button>
								) : null}
							</HStack>
						</form>
					</Box>
					<TeamChatSelector />
				</Flex>
			</Box>
		</Box>
	);
};

export default SettingsPage;
