export type UserInvitePayload = {
	telegramUserId: number;
	firstName: string;
	lastName: string | null;
};

export enum OnboardingSteps {
	SELECT_WORKSPACE,
	CREATE_WORKSPACE,
	INVITE_TEAM,
	SYNC_CHATS,
}
