import { useQuery } from '@tanstack/react-query';
import API from '../api/api.base';
import { ApiResponse, LabelItemType } from '../types';
import { QueryKeys } from '../constants';

export const useGetWorkspaceLabels = (workspaceId?: number) => {
	return useQuery<ApiResponse<{ workspaceLabels: LabelItemType[] }>>({
		queryKey: [QueryKeys.GET_WORKSPACE_LABELS, workspaceId],
		queryFn: () => API.get(`/api/workspaces/${workspaceId}/labels`, {}),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId,
	});
};
