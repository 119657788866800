import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Flex, FormLabel, Button, Text, HStack } from '@chakra-ui/react';
import styles from '../Templates.module.css';
import { useCreateTemplate, useUpdateTemplate } from '../queries';
import {
	Editor,
	EditorProvider,
	Icon,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverClose,
	BtnItalic,
	BtnBold,
	BtnLink,
	SoonTooltip,
} from '../../../../../components';

interface TemplatePopupProps {
	template?: string;
	children: ReactElement;
	isOpen: boolean;
	onClose: VoidFunction;
	onToggle: VoidFunction;
	templateId?: number;
}

export const TemplatePopup = React.forwardRef<
	HTMLDivElement | null,
	TemplatePopupProps
>(({ template, templateId, children, isOpen, onClose, onToggle }, ref) => {
	const createTemplateMutation = useCreateTemplate();
	const updateTemplateMutation = useUpdateTemplate();
	const [text, setText] = useState(template || '');

	const onEditTemplate = (id: number) => {
		if (!id) {
			return;
		}
		updateTemplateMutation.mutate(
			{ content: text, templateId: id },
			{
				onSuccess(data) {
					if (data.success) {
						onClose();
					}
				},
			},
		);
	};

	const onCreateTemplate = () => {
		createTemplateMutation.mutate(text, {
			onSuccess(data) {
				if (data.success) {
					onClose();
					setText('');
				}
			},
		});
	};

	useEffect(() => {
		if (isOpen && template?.length) {
			setText(template || '');
		}
	}, [isOpen, template]);

	const handlePaste = (event: React.ClipboardEvent<HTMLElement>) => {
		const clipboardData = event.clipboardData;

		if (clipboardData && clipboardData.items) {
			for (let i = 0; i < clipboardData.items.length; i++) {
				const item = clipboardData.items[i];

				if (item.type.indexOf('image') !== -1) {
					event.preventDefault();
				}
			}
		}
	};

	return (
		<Popover open={isOpen} onOpenChange={onToggle} modal={true}>
			<PopoverTrigger asChild={true}>{children}</PopoverTrigger>
			<EditorProvider>
				<PopoverContent
					ref={ref}
					sideOffset={5}
					align="end"
					className={styles.PopoverContent}>
					<Flex
						flexDirection="row"
						padding="8px"
						borderBottomWidth="1px"
						borderBottomColor="gray.15"
						align="center"
						justify="space-between">
						<Text color="primary" fontSize="13px" fontWeight="600">
							{templateId ? 'Edit' : 'New'} template
						</Text>
						<PopoverClose aria-label="Close" onClick={onClose}>
							<Icon name="close-cross" height="16" width="16" />
						</PopoverClose>
					</Flex>
					<Flex
						padding="8px"
						gap="8px"
						flexDirection="column"
						borderBottomWidth="1px"
						borderBottomColor="gray.15">
						<Box>
							<FormLabel
								textTransform="uppercase"
								color="gray.35"
								fontSize="11px"
								fontWeight="600"
								mb="4px">
								Text
							</FormLabel>

							<Editor
								value={text}
								onChange={ev => setText(ev.target.value)}
								onPaste={handlePaste}></Editor>
							<Text
								fontSize="12px"
								color="gray.40"
								fontWeight="400">
								Copy and paste your frequently used messages
								from telegram or format them here.
							</Text>
						</Box>
					</Flex>
					<Flex justifyContent="space-between" padding="8px">
						<HStack spacing="8px">
							<BtnBold />
							<BtnItalic />
							<BtnLink />
							<SoonTooltip>
								<Button
									isDisabled={true}
									variant="link"
									fontSize="11px"
									color="secondary"
									fontWeight={400}
									px="8px">
									Advanced formatting
								</Button>
							</SoonTooltip>
						</HStack>
						<Button
							variant="popup"
							isLoading={
								updateTemplateMutation.isPending ||
								createTemplateMutation.isPending
							}
							isDisabled={!text?.length}
							onClick={() => {
								if (!templateId) {
									return onCreateTemplate();
								}

								onEditTemplate(templateId);
							}}
							px="10px">
							{templateId ? 'Update' : 'Create'}
						</Button>
					</Flex>
				</PopoverContent>
			</EditorProvider>
		</Popover>
	);
});
