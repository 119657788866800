export const PipelineDefaultStages = [
	{ name: 'Lead', color: '#DDFCD8' },
	{ name: 'Contacted', color: '#CCF9FF' },
	{ name: 'Negotiation', color: '#E9DFFF' },
	{ name: 'Ready to start', color: '#FDDBF6' },
	{ name: 'Closed', color: '#FED8D8' },
	{ name: 'Stage 6', color: '#FFE3D3' },
	{ name: 'Stage 7', color: '#FFF6D7' },
	{ name: 'Stage 8', color: '#DDFCD8' },
	{ name: 'Stage 9', color: '#D1DFFB' },
	{ name: 'Stage 10', color: '#E9DFFF' },
];
