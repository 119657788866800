import React from 'react';
import {
	Avatar,
	Box,
	HStack,
	IconButton,
	Skeleton,
	Text,
} from '@chakra-ui/react';
import { getAvatarBg } from '../../../utils';
import { useUpdateCardTrackStatus } from '../../../queries';
import { Icon } from '../../Icon/Icon';
import { useGetFullChatInfo } from '../../../hooks';
import { TgDataForCard } from '../../../types';

interface CardHeaderProps {
	title: string;
	isLoaded?: boolean;
	chatTelegramId: number;
	lastTgChatMessageDate: number | null;
	trackedLastTelegramMessageUnixTime?: number | null;
	cardId: number;
	workspaceId?: number;
	cachedData?: TgDataForCard;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
	title,
	isLoaded,
	chatTelegramId,
	lastTgChatMessageDate,
	trackedLastTelegramMessageUnixTime,
	cardId,
	workspaceId,
	cachedData,
}) => {
	const updateCardTrackStatusMutation = useUpdateCardTrackStatus(false);

	const chatAvatar = useGetFullChatInfo({
		accessHash: cachedData?.accessHash,
		type: cachedData?.type,
		chatId: chatTelegramId,
		title: cachedData?.title || '',
		msgSenderId: cachedData?.sender?.id,
		isEnabled: !!cachedData?.hasAccess && !cachedData?.isChatFullyLoaded,
	});

	const onChangeTrackState = (
		currentMessageDate: number | null,
		cardMessageDate?: number | null,
	) => {
		const isLastCardMessageIgnored = currentMessageDate === cardMessageDate;
		updateCardTrackStatusMutation.mutate({
			cardId,
			workspaceId: workspaceId || 0,
			trackedLastTelegramMessageUnixTime: isLastCardMessageIgnored
				? null
				: currentMessageDate,
		});
	};

	return (
		<HStack spacing={1} pb="6px" w="full" position="relative">
			<Box
				w={isLoaded ? '14px' : '24px'}
				minW={isLoaded ? '14px' : '24px'}
				h={isLoaded ? '14px' : '24px'}
				borderRadius="2px"
				overflow="hidden">
				<Skeleton
					w="100%"
					h="100%"
					borderRadius="2px"
					startColor="gray.40"
					endColor="gray.20"
					isLoaded={isLoaded}>
					<Avatar
						src={chatAvatar || ''}
						w="100%"
						h="100%"
						borderRadius="2px"
						name={title || ''}
						color="white"
						bg={getAvatarBg(chatTelegramId)}
						sx={{
							'> div': {
								fontSize: '8px',
							},
						}}
					/>
				</Skeleton>
			</Box>
			<Skeleton
				isLoaded={isLoaded}
				h="24px"
				startColor="gray.40"
				endColor="gray.20"
				flex={1}
				noOfLines={1}>
				<Text
					fontWeight={500}
					maxW="100%"
					isTruncated={true}
					title={title}
					lineHeight="24px">
					{title || ''}
				</Text>
			</Skeleton>
			<IconButton
				position="absolute"
				top="0px"
				right="0px"
				aria-label="To track conversation on/off"
				className="card__track-status-btn"
				visibility="hidden"
				opacity={0}
				transition="visibility 0.3s ease, opacity 0.3s ease"
				minW="auto"
				boxSize="24px"
				variant="outline"
				bg="gray.10"
				borderRadius="4px"
				title={
					trackedLastTelegramMessageUnixTime === lastTgChatMessageDate
						? 'Mark as unanswered'
						: 'Mark as answered'
				}
				isLoading={updateCardTrackStatusMutation.isPending}
				onClick={e => {
					e.stopPropagation();
					onChangeTrackState(
						lastTgChatMessageDate,
						trackedLastTelegramMessageUnixTime,
					);
				}}>
				<Icon
					name={
						trackedLastTelegramMessageUnixTime ===
						lastTgChatMessageDate
							? 'eye-off'
							: 'eye'
					}
					height="16"
					width="16"
				/>
			</IconButton>
		</HStack>
	);
};
