import React from 'react';
import * as RadixPopoverPrimitive from '@radix-ui/react-popover';
import classNames from 'classnames';
import styles from './Popover.module.css';

export const Popover = RadixPopoverPrimitive.Root;

export const PopoverTrigger = RadixPopoverPrimitive.Trigger;

export const PopoverContent = React.forwardRef<
	React.ElementRef<typeof RadixPopoverPrimitive.Content>,
	RadixPopoverPrimitive.PopoverContentProps
>(({ className, ...props }, forwardedRef) => {
	return (
		<RadixPopoverPrimitive.Portal>
			<RadixPopoverPrimitive.Content
				{...props}
				ref={forwardedRef}
				className={classNames(styles.PopoverContent, className)}
			/>
		</RadixPopoverPrimitive.Portal>
	);
});
PopoverContent.displayName = 'Popover.Content';

export const PopoverClose = React.forwardRef<
	React.ElementRef<typeof RadixPopoverPrimitive.Close>,
	RadixPopoverPrimitive.PopoverCloseProps
>(({ children, ...props }, forwardedRef) => (
	<RadixPopoverPrimitive.Close {...props} ref={forwardedRef}>
		{children}
	</RadixPopoverPrimitive.Close>
));
PopoverClose.displayName = 'Popover.Close';
