import React from 'react';
import { SidebarContent } from './SidebarContent';
import { CurrentUser } from '../../../types';

interface SidebarProps {
	data: CurrentUser;
}

export const Sidebar: React.FC<SidebarProps> = ({ data }) => {
	return (
		<>
			<SidebarContent
				display={{ base: 'none', md: 'flex' }}
				data={data}
			/>
			{/* <Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full">
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			<MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} /> */}
		</>
	);
};
