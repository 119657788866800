import React, { useCallback } from 'react';
import { CustomTag } from '../../../../../components';
import { getLabelInfoByType } from '../../../../../utils';
import { LabelItemType } from '../../../../../types';
import { useUpdateLabelAssignStatus } from '../queries';

interface CardLabelItemProps extends LabelItemType {
	enableCloseButton: boolean;
	workspaceId: number;
	cardId: number;
}

export const CardLabelItem: React.FC<CardLabelItemProps> = ({
	enableCloseButton,
	colourId,
	workspaceLabelId,
	name,
	workspaceId,
	cardId,
}) => {
	const updateLabelAssignStatusMutation = useUpdateLabelAssignStatus();

	const { bgColor, textColor } = getLabelInfoByType(colourId);

	const onAssignLabelToCard = useCallback(
		() =>
			updateLabelAssignStatusMutation.mutate({
				workspaceId,
				workspaceLabelId,
				cardId,
				isAssigned: false,
			}),
		[
			workspaceId,
			cardId,
			updateLabelAssignStatusMutation,
			workspaceLabelId,
		],
	);

	return (
		<CustomTag
			label={name}
			bgColor={bgColor}
			labelColor={textColor}
			enableCloseButton={enableCloseButton}
			wrapperProps={{
				pr: enableCloseButton ? '3px' : '8px',
			}}
			labelProps={{
				maxW: '164px',
			}}
			closeButtonProps={{
				'aria-label': 'un-assign label from card',
				isLoading: updateLabelAssignStatusMutation.isPending,
				onClick: onAssignLabelToCard,
				_loading: {
					cursor: 'not-allowed',
					fontSize: '10px',
				},
			}}
			closeIconColor={textColor}
		/>
	);
};
