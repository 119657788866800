import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { msgErr, MsgsProxy, useTeaSimple } from '../tesm';
import { init, update, State, Msg } from './tea';
import { Auth } from '../../api/workers';
import { poll } from '../poll';
import { useTelegram } from '../../services';
import { BOT_USERNAME } from '../../tg-config';
import { setExchangingCodeLoading } from '../../redux/reducers/login-flow.reducer';
import { createSupabaseClient } from '../../services/supabase';
import { useGetCurrentUser } from '../../queries/useGetCurrentUser';
import { setAuthToken, setAuthUser } from '../../redux/reducers/auth.reducer';
import { useSetUserTimeZone } from '../../queries';
import { getCurrentUserTimeZone } from '../../utils';

const AuthContext = createContext<{ state: State, msgs: MsgsProxy<Msg> } | undefined>(undefined);

async function pollRefreshToken(query: {
	tgid: number;
	otp: string;
	did: string;
}) {
	let token = await poll(
		async () => {
			let result = await Auth.refreshToken({ query: query });
			return result.token || undefined;
		},
		750,
		10,
	);

	return { token };
}

const AuthProvider: React.FCC<{
	sendBotOtp: (otp: string) => Promise<void>;
}> = ({ children, ...props }) => {
	// backwards compatibility with existing spaghetti
	const dispatch = useDispatch();

	const [state, msgs] = useTeaSimple(init, update, {
		request_otp: ({ tgid }) =>
			Auth.otp({ query: { id: tgid } })
				.then(msgs.otp_arrived)
				.catch(msgErr(msgs.otp_request_failed)),

		send_otp: cmd => {
			// console.log("[CONTEXT] send_otp", cmd)
			props
				.sendBotOtp(cmd.otp)
				.then(() =>
					pollRefreshToken(cmd)
						.then(msgs.refresh_token_arrived)
						.catch(msgErr(msgs.refresh_token_request_failed)),
				)
				.catch(msgErr(msgs.refresh_token_request_failed));
		},

		request_jwt: ({ tgid }) =>
			Auth.jwt({ query: { id: tgid } })
				.then(res =>
					res.expired
						? msgs.refresh_token_expired({})
						: msgs.jwt_arrived(res),
				)
				.catch(error =>
					msgs.jwt_request_failed({ error, now: Date.now() }),
				),

		refresh_jwt: ({ tgid, at }) =>
			setTimeout(
				() =>
					Auth.jwt({ query: { id: tgid } })
						.then(res =>
							res.expired
								? msgs.refresh_token_expired({})
								: msgs.jwt_arrived(res),
						)
						.catch(error =>
							msgs.jwt_request_failed({ error, now: Date.now() }),
						),
				at - Date.now(),
			),

		// backwards compatibility with existing spaghetti
		setExchangingCodeLoading: ({ value }) =>
			dispatch(setExchangingCodeLoading(value)),
	});

	const contextValue = useMemo(
		() => ({ state, msgs }),
		[state, props.sendBotOtp],
	);

	return (
		<AuthContext.Provider value={contextValue}>
			<LegacyUserReduxProvider>
				{children}
			</LegacyUserReduxProvider>
		</AuthContext.Provider>
	);
};

// backwards compatibility with existing spaghetti
const LegacyUserReduxProvider: React.FCC = ({ children }) => {
	const { state } = useAuthContext();
	const dispatch = useDispatch();
	const { data: userData } = useGetCurrentUser(true);
	const setUserTimeZoneMutation = useSetUserTimeZone();

	// backwards compatibility with existing spaghetti
	useEffect(() => {
		if (userData?.value) {
			dispatch(setAuthUser(userData.value));
			const currentUserTimeZone = getCurrentUserTimeZone();
			if (
				!userData?.value?.timeZoneId ||
				userData?.value?.timeZoneId !== currentUserTimeZone
			) {
				setUserTimeZoneMutation.mutate(currentUserTimeZone);
			}
		}
	}, [userData]);

	// backwards compatibility with existing spaghetti
	useEffect(() => {
		if (state.type === 'authed' && state.jwt) {
			dispatch(
				setAuthToken({
					accessToken: state.jwt,
				}),
			);
		}
	}, [state]);

	return children;
};

export const AuthProviderWithTg: React.FCC = ({ children }) => {
	const tg = useTelegram();
	const [shouldSend, setShouldSend] = React.useState<string | undefined>();
	const [sendPromiseResolve, setSendPromiseResolve] =
		React.useState<() => void>();

	const sendCodeToBot = useCallback(
		(token: string) => {
			setShouldSend(token);
			return new Promise<void>(resolve => setSendPromiseResolve(resolve));
		},
		[setShouldSend, setSendPromiseResolve],
	);

	useEffect(() => {
		// console.log(`[AuthProviderWithTg] shouldSend: ${shouldSend}, tg: ${!!tg}`)

		if (!shouldSend) return;
		if (!tg) return;

		// console.log(`[AuthProviderWithTg] sending code to bot: ${shouldSend}`)
		tg.actions.proxy.openChatByUsername({
			username: BOT_USERNAME,
			startParam: shouldSend,
		});
		setShouldSend(undefined);
		sendPromiseResolve?.();
	}, [shouldSend, tg, sendPromiseResolve]);

	return <AuthProvider sendBotOtp={sendCodeToBot}>{children}</AuthProvider>;
};

export function useAuthContext() {
	let ctx = React.useContext(AuthContext);
	if (!ctx)
		throw new Error('useAuthContext must be used within a AuthProvider');

	return ctx;
}

export function useSupabaseWithAuth() {
	const { state } = useAuthContext();
	const jwt = state.type === 'authed' ? state.jwt : null;
	const supabase = useMemo(() => createSupabaseClient(jwt), [jwt]);
	return supabase;
}
