/* eslint-disable indent */
import { Suspense } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
// import * as Sentry from '@sentry/react';
import { AuthWrapper } from './widgets';
import SelectWorkspace from './pages/SelectWorkspace/SelectWorkspace';
import Onboarding from './pages/Onboarding/Onboarding';
import { MainLayout, SuspenseLoader } from './components';
import TasksPage from './pages/Tasks/Tasks.page';
import SettingsPage from './pages/Settings/Settings.page';
import ChatPage from './pages/Chat/Chat.page';
import DashboardPage from './pages/Dashboard/Dashboard.page';
import NotificationsPage from './pages/Notifications/Notifications.page';
import MembersSettingsPage from './pages/Settings/subpages/Members.settings.page';
import PipelineSettingsPage from './pages/Settings/subpages/Pipeline.settings.page';
import { FrameWrapper } from './widgets/AuthWrapper/FrameWrapper';

// const sentryCreateBrowserRouter =
// 	Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const browserRouter = () =>
	createBrowserRouter([
		{
			element: <FrameWrapper />,
			children: [
				{
					path: '/login',
					element: <div />,
				},
				{
					path: '/select-workspace',
					element: (
						<AuthWrapper>
							<SelectWorkspace />
						</AuthWrapper>
					),
				},
				{
					path: '/onboarding',
					element: <Onboarding />,
				},
				{
					path: '/:workspaceId',
					element: (
						<AuthWrapper>
							<MainLayout />
						</AuthWrapper>
					),
					children: [
						{
							index: true,
							element: (
								<Suspense fallback={SuspenseLoader}>
									<DashboardPage />
								</Suspense>
							),
						},
						{
							path: 'tasks',
							element: (
								<Suspense fallback={SuspenseLoader}>
									<TasksPage />
								</Suspense>
							),
						},
						{
							path: 'settings',
							element: (
								<Suspense fallback={SuspenseLoader}>
									<SettingsPage />
								</Suspense>
							),
						},
						{
							path: 'settings/members',
							element: (
								<Suspense fallback={SuspenseLoader}>
									<MembersSettingsPage />
								</Suspense>
							),
						},
						{
							path: 'settings/pipeline',
							element: (
								<Suspense fallback={SuspenseLoader}>
									<PipelineSettingsPage />
								</Suspense>
							),
						},
						{
							path: 'notifications',
							element: (
								<Suspense fallback={SuspenseLoader}>
									<NotificationsPage />
								</Suspense>
							),
						},
						{
							path: 'chat/:id',
							element: (
								<Suspense fallback={SuspenseLoader}>
									<ChatPage />
								</Suspense>
							),
						},
					],
				},
				{
					path: '*',
					element: <Navigate to="/select-workspace" />
				},
			],
		},
	]);
