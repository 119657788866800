import React from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	chakra,
	useDisclosure,
} from '@chakra-ui/react';
import { EmptyItemsText, Icon, Loader } from '../../../../components';
import { useGetCardReminders } from './queries';
import { ReminderPopup } from '../../components';
import { colors } from '../../../../theme/colors';
import { Reminder } from './components';

interface RemindersProps {
	cardId: number;
	workspaceId?: number;
}

export const Reminders: React.FC<RemindersProps> = ({
	workspaceId,
	cardId,
}) => {
	const { data, isLoading } = useGetCardReminders(workspaceId, cardId);
	const {
		isOpen: isReminderPopupOpen,
		onToggle: onToggleReminderPopup,
		onClose: onCloseReminderPopup,
	} = useDisclosure();

	if (isLoading) {
		return <Loader centerHeight="calc(100vh - 105px)" />;
	}
	return (
		<Box p="24px">
			<Flex alignItems="center" justify="space-between" mb="16px">
				<Heading color="primary" fontSize="16px" fontWeight="600">
					Active reminders
					<chakra.span color="gray.35" ml={2}>
						{data?.value?.reminders?.length || 0}
					</chakra.span>
				</Heading>
				<ReminderPopup
					isOpen={isReminderPopupOpen}
					onToggle={onToggleReminderPopup}
					onClose={onCloseReminderPopup}
					cardId={cardId}>
					<Button
						variant="outline"
						height="24px"
						shadow="none"
						color="secondary"
						px="8px"
						py="3px"
						borderRadius="4px"
						iconSpacing={1}
						leftIcon={
							<Icon
								name="plus"
								width="14"
								height="14"
								color={colors.secondary}
							/>
						}
						isActive={isReminderPopupOpen}>
						Add Reminder
					</Button>
				</ReminderPopup>
			</Flex>
			{data?.value?.reminders?.length ? (
				<Box
					borderColor="gray.20"
					borderRadius="8px"
					background="gray.10"
					borderWidth="1px"
					maxH="calc(100vh - 200px)"
					overflowY="auto">
					{data?.value?.reminders?.map(elem => (
						<Reminder key={elem.id} cardId={cardId} {...elem} />
					))}
				</Box>
			) : (
				<EmptyItemsText text="There are no reminders at the moment" />
			)}
		</Box>
	);
};
