import React, { useMemo } from 'react';
import { Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { ActionsMenu, Icon } from '../../../../../components';
import { ReminderPopup } from '../../../components';
import { useDeleteReminder } from '../queries';
import { CardReminderItem } from '../../../types';
import { getViewDateByFormat } from '../../../../../utils';

interface IReminderProps extends CardReminderItem {
	cardId: number;
}

export const Reminder: React.FC<IReminderProps> = ({
	id,
	text,
	remindOn,
	cardId,
}) => {
	const {
		onToggle: onToggleReminderPopup,
		isOpen: isReminderPopupOpen,
		onClose: onCloseReminderPopup,
	} = useDisclosure();
	const deleteReminderMutation = useDeleteReminder(cardId);

	const onDeleteReminder = () =>
		deleteReminderMutation.mutate(id, {
			onSuccess: data => {
				if (data?.success && isReminderPopupOpen) {
					onCloseReminderPopup();
				}
			},
		});

	const formattedDate = useMemo(
		() => getViewDateByFormat(remindOn, 'MMM D, YYYY'),
		[remindOn],
	);
	return (
		<Flex
			px={4}
			py={3}
			alignItems="center"
			justifyContent="space-between"
			_last={{ borderBottomWidth: 0 }}
			borderBottomWidth="1px"
			borderBottomColor="gray.15"
			position="relative"
			overflow="hidden"
			_hover={{
				'.actions-menu': {
					opacity: 1,
					visibility: 'visible',
					transform: 'translateY(-50%) translateX(0)',
				},
			}}>
			<Text
				color="primary"
				fontSize="13px"
				fontWeight="500"
				textAlign="left"
				as="button"
				wordBreak="break-word"
				noOfLines={3}
				sx={{
					a: {
						textDecoration: 'underline',
					},
				}}>
				{text}
			</Text>
			<Text
				fontSize="13px"
				lineHeight="20px"
				color="gray.50"
				whiteSpace="nowrap"
				alignSelf="flex-start">
				{formattedDate}
			</Text>
			<ActionsMenu
				isOpen={isReminderPopupOpen}
				includeDelete={true}
				deleteButtonProps={{
					onClick: onDeleteReminder,
					isLoading: deleteReminderMutation.isPending,
				}}>
				<ReminderPopup
					isOpen={isReminderPopupOpen}
					onClose={onCloseReminderPopup}
					onToggle={onToggleReminderPopup}
					reminderId={id}
					reminderDate={remindOn}
					reminderDescription={text}
					cardId={cardId}>
					<IconButton
						aria-label="edit Reminder"
						minW="auto"
						variant="ghost"
						boxSize="24px"
						borderRadius="4px"
						onClick={onToggleReminderPopup}>
						<Icon name="edit" height="16" width="16" />
					</IconButton>
				</ReminderPopup>
			</ActionsMenu>
		</Flex>
	);
};
