import { CardStatusItem } from '../types';
import { getTaskStatusByType } from './getLabelByType';

export const getSystemDefaultStatusData = (
	selectedStatusId?: number,
	statuses?: CardStatusItem[],
) => {
	const matchedStatusData = statuses?.find(
		status => status.id === selectedStatusId,
	);
	if (!matchedStatusData) {
		return;
	}

	const systemDefaultStatusData = getTaskStatusByType({
		position: matchedStatusData.position,
		typeId: matchedStatusData.typeId,
	});

	return {
		...systemDefaultStatusData,
		title: matchedStatusData.name,
	};
};
