import { extendTheme, theme as base } from '@chakra-ui/react';
import { colors } from './colors';
import { shadows } from './shadows';
import { Button, Heading, Text } from './components';

export const theme = extendTheme({
	colors,
	shadows,
	fonts: {
		body: `'Inter', ${base.fonts.body}`,
	},
	components: {
		Text,
		Button,
		Heading,
	},
});
