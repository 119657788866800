import { useQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { CardActivityOption } from '../types';
import { QueryKeys } from '../../../constants';

export const useGetCardActivitiesOptions = (
	isFetchEnabled: boolean,
	workspaceId?: number,
	cardId?: number,
) => {
	return useQuery<
		ApiResponse<{ workspaceCardActivities: CardActivityOption[] }>
	>({
		queryKey: [QueryKeys.GET_CARD_ACTIVITIES_OPTIONS, workspaceId, cardId],
		queryFn: () =>
			API.get(
				`/api/workspaces/${workspaceId}/cards/${cardId}/activities/get-list`,
				{},
			),
		refetchOnWindowFocus: false,
		enabled: isFetchEnabled,
	});
};
