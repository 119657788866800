import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { CardReminderItem } from '../../../types';
import { QueryKeys } from '../../../../../constants';
import { Alerter } from '../../../../../utils';
import { useGetWorkspaceId } from '../../../../../hooks';

export const useDeleteReminder = (cardId: number) => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: reminderId =>
			API.delete(
				`/api/workspaces/${workspaceId}/cards/reminders/${reminderId}/delete`,
				{},
			),
		onSuccess: async (data, reminderId) => {
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Error occurred while deleting reminder',
				);
				return;
			}

			Alerter.success(
				data.messages?.[0]?.message || 'Reminder successfully deleted',
			);

			if (!workspaceId) {
				return;
			}

			queryClient.setQueryData<
				ApiResponse<{ reminders: CardReminderItem[] }>
			>([QueryKeys.GET_CARD_REMINDERS, cardId, workspaceId], oldData => {
				if (oldData?.value.reminders) {
					const updatedReminders = oldData.value.reminders.filter(
						reminder => reminder.id !== reminderId,
					);
					return {
						...oldData,
						value: {
							reminders: updatedReminders || [],
						},
					};
				}

				return oldData;
			});
		},
	});
};
