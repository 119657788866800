import React, { useEffect } from 'react';
import { Flex, Button, Text } from '@chakra-ui/react';

interface IErrorPlaceholderProps {
	onReload: VoidFunction;
}

export const ErrorPlaceholder: React.FC<IErrorPlaceholderProps> = ({
	onReload,
}) => {
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			onReload();
		}, 30000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [onReload]);

	return (
		<Flex
			bg="white"
			height="100%"
			width="100%"
			flexDirection="column"
			align="center"
			justify="center"
			borderRadius="4px"
			border="1px solid"
			borderColor="gray.20"
			gap="30px">
			<Text variant="bodyLarge" textAlign="center" w="70%">
				Seems like there are problems with Telegram loading
			</Text>
			<Button variant="outline" onClick={onReload}>
				Reload
			</Button>
		</Flex>
	);
};
