import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Database } from '../types';

const VITE_SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
const VITE_SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!VITE_SUPABASE_URL || !VITE_SUPABASE_ANON_KEY) {
	throw new Error('VITE_SUPABASE_URL and VITE_SUPABASE_ANON_KEY must be set');
}

export const createSupabaseClient = (accessToken: string | null): SupabaseClient<Database> => createClient(
	VITE_SUPABASE_URL,
	VITE_SUPABASE_ANON_KEY,
	accessToken ? {
		global: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		},
	} : undefined,
);
