import { useEffect, useState } from 'react';
import { CardStatusItem } from '../../../types';
import { useDispatch } from 'react-redux';
import { setCardsStatusId } from '../../../redux/reducers/page-navigation-state.reducer';

export function useHandleSwitchingStatuses(
	initialStatusId: number | null,
	statuses: CardStatusItem[] | undefined,
) {
	const dispatch = useDispatch();

	const [statusIndex, setStatusIndex] = useState<number | null>(null);

	useEffect(() => {
		if (initialStatusId && statuses) {
			const initialIndex = statuses.findIndex(
				elem => elem.id === initialStatusId,
			);
			setStatusIndex(initialIndex);
		}
	}, [initialStatusId, statuses]);

	const nextStatus = () => {
		if (
			statusIndex !== null &&
			statuses &&
			statusIndex < statuses.length - 1
		) {
			const newIndex = statusIndex + 1;
			dispatch(setCardsStatusId(statuses[newIndex].id));
			setStatusIndex(newIndex);
		}
	};

	const prevStatus = () => {
		if (statusIndex !== null && statuses && statusIndex > 0) {
			const newIndex = statusIndex - 1;
			dispatch(setCardsStatusId(statuses[newIndex].id));
			setStatusIndex(newIndex);
		}
	};

	return {
		nextStatus,
		prevStatus,
		canGoPrev: statusIndex !== null && statusIndex > 0,
		canGoNext:
			statusIndex !== null && statusIndex < (statuses?.length || 0) - 1,
	};
}
