import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from '../types';
import { QueryKeys } from '../constants';
import { useGetMatchedWorkspaceId } from '../hooks';
import { useSupabaseWithAuth } from '../state/auth/context';

export const useGetWorkspaceTelegramIds = () => {
	const workspaceId = useGetMatchedWorkspaceId();
	const supabase = useSupabaseWithAuth();

	return useQuery<ApiResponse<{ telegramUserIds: number[] }>>({
		queryKey: [QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId],
		queryFn: async () => {
			if (!workspaceId) {
				throw new Error('Workspace ID is missing');
			}

			const { data, error } = await supabase
				.from('workspace_users')
				.select('users (telegram_user_id)')
				.eq('workspace_id', workspaceId)
				.eq('is_removed', false);

			if (error) {
				throw error;
			}

			const telegramUserIds = data
				.map(item => item.users?.telegram_user_id)
				.filter((id): id is number => id !== null);

			return {
				success: true,
				status: 200,
				value: { telegramUserIds },
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		enabled: !!workspaceId,
	});
};