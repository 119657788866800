import { PLACEHOLDERS } from './placeholders';

export const makeMentionsMarkup = (
	markup: string,
	id: string,
	name: string,
	username: string | null,
) =>
	markup
		.replace(PLACEHOLDERS.id, id)
		.replace(PLACEHOLDERS.display, username ? `@${username}` : name);
