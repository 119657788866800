import React from 'react';
import { Box, Image } from '@chakra-ui/react';

interface IWorkspaceItemImageProps {
	imageLink?: string;
}

export const WorkspaceItemImage: React.FC<IWorkspaceItemImageProps> = ({
	imageLink,
}) => {
	return (
		<Box
			w="16px"
			minW="16px"
			h="16px"
			borderRadius="2px"
			bg={imageLink ? 'transparent' : 'blue.50'}>
			{imageLink ? (
				<Image src={imageLink} w="100%" h="100%" objectFit="contain" />
			) : null}
		</Box>
	);
};
