import { useMutation } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty, TGChat } from '../../../types';
import { QueryKeys } from '../../../constants';

export const useSyncFolders = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{
			workspaceId: number;
			folders: { telegramFolderId: number; chats: TGChat[] }[];
		}
	>({
		mutationKey: [QueryKeys.SYNC_FOLDERS],
		mutationFn: payload => API.post('/api/cards/sync', payload),
	});
};
