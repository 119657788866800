import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApiResponse } from '../types';
import { QueryKeys } from '../constants';
import { RootStore } from '../redux/createStore';
import { getMillisecondsUntilNextNineAM } from '../utils';
import { useGetMatchedWorkspaceId } from '../hooks';
import { useSupabaseWithAuth } from '../state/auth/context';

const calculateNotificationsScheduledAtLowerBound = (timeZoneId: string): string => {
	const DAY_STARTS_AT_HOURS = 9; // 9:00 AM

	const now = new Date();
	const userDate = new Date(
		now.toLocaleString('en-US', { timeZone: timeZoneId }),
	);
	const currentHour = userDate.getHours();
	const currentMinute = userDate.getMinutes();

	// Set the date to either today or tomorrow based on the current time
	if (
		currentHour < DAY_STARTS_AT_HOURS ||
		(currentHour === DAY_STARTS_AT_HOURS && currentMinute === 0)
	) {
		// Before 9:00 AM, use the start of the current day
		userDate.setHours(0, 0, 0, 0);
	} else {
		// 9:00 AM or later, use the start of the next day
		userDate.setDate(userDate.getDate() + 1);
		userDate.setHours(0, 0, 0, 0);
	}

	const utcDate = new Date(
		userDate.getTime() - userDate.getTimezoneOffset() * 60000,
	);
	return utcDate.toISOString();
};

export const useGetNotificationsCount = () => {
	const workspaceId = useGetMatchedWorkspaceId();
	const supabase = useSupabaseWithAuth();
	const { isOnboarded, id: currentUserId, timeZoneId } = useSelector((state: RootStore) => state.auth.user);
	const refetchInterval = getMillisecondsUntilNextNineAM();

	return useQuery<ApiResponse<{ count: number }>>({
		queryKey: [QueryKeys.GET_NOTIFICATIONS_COUNT, workspaceId],
		queryFn: async () => {
			if (!workspaceId || !currentUserId || !timeZoneId) {
				throw new Error('Workspace ID, User ID, or Time Zone ID is missing');
			}

			const dateTimeLimit = calculateNotificationsScheduledAtLowerBound(timeZoneId);

			const { count, error } = await supabase
				.from('notifications')
				.select('*', { count: 'exact', head: true })
				.eq('workspace_user_id', currentUserId)
				.lt('scheduled_at', dateTimeLimit)
				.eq('is_archived', false);

			if (error) {
				throw error;
			}

			return {
				success: true,
				status: 200,
				value: { count: count || 0 },
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		refetchInterval,
		enabled: !!workspaceId && isOnboarded,
	});
};