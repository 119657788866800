import { TgUserData } from '../../../types';
import { MessageTaggedUsers } from '../types';
import { removeMentionsMarkup } from './removeMentionsMarkup';

export const extractUnmarkedMentions = (
	message: string,
	chatMembers?: TgUserData[],
) => {
	if (!chatMembers?.length) {
		return [];
	}

	const regex = /@(\w+)/g;
	const mentionsData: MessageTaggedUsers[] = [];

	let match;

	while ((match = regex.exec(message)) !== null) {
		const [fullMatch, username] = match;

		const isWithinBrackets =
			message.slice(match.index - 1, match.index) === '[' ||
			message.slice(regex.lastIndex, regex.lastIndex + 1) === ']';
		if (isWithinBrackets) {
			continue;
		}

		const plainMessage = removeMentionsMarkup(
			message.slice(0, match.index),
		);
		const offset = plainMessage.length;

		const member = chatMembers.find(item => item.username === username);

		if (member) {
			mentionsData.push({
				taggedUserTelegramIds: member.id,
				length: fullMatch.length,
				offset,
			});
		}
	}

	return mentionsData;
};
