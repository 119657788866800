export const modalSubHeaderStyleProps = {
	fontWeight: '600',
	fontSize: '13px',
	color: 'primary',
};

export const modalRegularTextStyleProps = {
	fontWeight: '400',
	fontSize: '12px',
	color: 'gray.35',
};

export const settingsRegularTextStyleProps = {
	fontWeight: '400',
	fontSize: '13px',
	color: 'tertiary',
};
