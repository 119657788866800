import React from 'react';
import { Box, HStack, IconButton } from '@chakra-ui/react';
import { Icon, SoonTooltip } from '../../../../../../components';
import { colors } from '../../../../../../theme/colors';

interface ReplyActionProps {
	onReply?: VoidFunction;
}

export const ReplyAction: React.FC<ReplyActionProps> = ({ onReply }) => {
	return (
		<Box
			visibility="hidden"
			opacity={0}
			position="absolute"
			className="action"
			top="50%"
			right="-32px"
			transform="translateY(-50%)"
			transition="visibility 0.3s ease-in-out, opacity 0.3s ease-in-out"
			zIndex={20}
			bg="white"
			boxShadow="0 0 12px white">
			<HStack zIndex={10} position="relative">
				<SoonTooltip>
					<IconButton
						aria-label="message reply"
						variant="ghost"
						w="24px"
						minW="24px"
						h="24px"
						borderRadius="4px"
						justifyContent="center"
						onClick={onReply}>
						<Icon
							name="arrow-back-up"
							width="16px"
							height="16px"
							color={colors.secondary}
						/>
					</IconButton>
				</SoonTooltip>
			</HStack>
		</Box>
	);
};
