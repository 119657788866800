import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../../../constants';
import { ApiResponse, LabelItemType } from '../../../../../types';

type Payload = LabelItemType & {
	workspaceId: number;
};

export const useUpdateLabelsStateOnCreate = () => {
	const queryClient = useQueryClient();

	const updateLabelsStateOnCreate = ({
		workspaceId,
		colourId,
		name,
		workspaceLabelId,
	}: Payload) =>
		queryClient.setQueryData<
			ApiResponse<{ workspaceLabels: LabelItemType[] }> | undefined
		>([QueryKeys.GET_WORKSPACE_LABELS, workspaceId], oldData => {
			if (!oldData) {
				return oldData;
			}

			const matchedLabel = oldData.value?.workspaceLabels?.find(
				el => el.workspaceLabelId === workspaceLabelId,
			);
			if (matchedLabel) {
				return oldData;
			}

			const updatedLabelData = {
				workspaceLabelId,
				name,
				colourId,
			};

			return {
				...oldData,
				value: {
					workspaceLabels: [
						...oldData.value.workspaceLabels,
						updatedLabelData,
					],
				},
			};
		});

	return { updateLabelsStateOnCreate };
};
