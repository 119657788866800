import React, { useMemo } from 'react';
import { Flex, Box, Grid } from '@chakra-ui/react';
import { Sidebar } from '../MainLayout/components';
import { DefaultPageHeader } from '../../widgets';
import { BoardTab } from '../../pages/Dashboard/components';
import { getBoardTabs } from '../../pages/Dashboard/constants';
import { DecoyKanbanBoardColumn } from './DecoyKanbanBoardColumn';
import { decoyStatuses, decoyTasks, decoyUser } from '../../constants';

export const DecoyBackground: React.FC = () => {
	const renderTabs = useMemo(
		() =>
			getBoardTabs({
				unread: 0,
				unanswered: 0,
				activeTasks: 0,
				teamActivity: 0,
				mentions: 0,
			}).map((tab, index) => (
				<BoardTab
					key={tab.id}
					isActive={tab.filterValue === 0}
					onClick={() => console.log(index)}
					{...tab}
				/>
			)),
		[],
	);

	return (
		<Flex
			minHeight="100vh"
			bgImage="url('/background-noise.png')"
			width="100vw">
			<Sidebar data={decoyUser} />
			<Box width="calc(100vw - 240px)" pr={3}>
				<DefaultPageHeader
					title="Main Pipeline"
					iconName="users"
					isSyncSettingsEnabled={true}
					isInviteMembersEnabled={true}
				/>
				<Box
					borderWidth="1px"
					borderColor="gray.20"
					borderRadius="8px"
					bg="white"
					w="100%"
					h="calc(100dvh - 68px)">
					<Flex
						columnGap={4}
						p={2}
						overflowX="auto"
						borderBottomWidth="1px"
						borderBottomColor="gray.15">
						{renderTabs}
					</Flex>
					<Grid
						templateColumns={`repeat(${decoyStatuses?.length}, minmax(280px, 1fr))`}
						w="100%"
						h="calc(100vh - 118px)"
						overflow="auto">
						{decoyStatuses?.map((status, index) => (
							<DecoyKanbanBoardColumn
								key={status.id}
								// @ts-ignore
								tasks={decoyTasks[status.id]}
								columnStatus={status}
								gridProps={{
									borderRightWidth:
										index === decoyStatuses.length - 1
											? '0px'
											: '1px',
								}}
							/>
						))}
					</Grid>
				</Box>
			</Box>
		</Flex>
	);
};
