import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, VStack } from '@chakra-ui/react';
import { useForm, useFieldArray } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import {
	modalSubHeaderStyleProps,
	settingsRegularTextStyleProps,
} from '../../../modals/Onboarding/steps/helper';
import {
	ConfirmDeleteModal,
	StageItem,
	PipelineControlButtons,
	PipelineAddStageButton,
	BlockerDialog,
} from '../components';
import { PipelineForm, UpdateStagesPayload } from '../types';
import { PipelineDefaultStages } from '../../../constants';
import { Loader } from '../../../components';
import { useSaveStagesChanges, useGetActiveStages } from '../queries';
import { useBeforeunload } from '../../../hooks';

const PipelineSettingsPage: React.FC = () => {
	const [stageIdToDelete, setStageIdToDelete] = useState<null | {
		index: number;
		value: number | string;
	}>(null);

	const {
		control,
		formState: { isDirty },
		handleSubmit,
		reset,
		setValue,
		getValues,
	} = useForm<PipelineForm>();

	useBeforeunload(
		isDirty ? (event: BeforeUnloadEvent) => event.preventDefault() : null,
	);

	const { data, isLoading } = useGetActiveStages();
	const saveStagesChangesMutation = useSaveStagesChanges();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'stages',
	});

	const onSubmit = (data: PipelineForm) => {
		const payload: UpdateStagesPayload = {
			deletedWorkspaceStagesIds: data.deletedIds || [],
			newStages: [],
			updatedWorkspaceStages: [],
		};

		data.stages?.forEach(elem => {
			if (typeof elem.identifier === 'string') {
				payload.newStages.push(elem.name);
			} else {
				payload.updatedWorkspaceStages.push({
					id: elem.identifier,
					name: elem.name,
				});
			}
		});

		saveStagesChangesMutation.mutate(payload);
	};

	const onAddStage = () => {
		append({
			name: PipelineDefaultStages[fields.length].name,
			identifier: uuid(),
		});
	};

	const onDeleteConfirm = () => {
		if (typeof stageIdToDelete?.value === 'number') {
			setValue('deletedIds', [
				...(getValues('deletedIds') || []),
				stageIdToDelete.value,
			]);
		}
		remove(stageIdToDelete?.index);
		setStageIdToDelete(null);
	};

	useEffect(() => {
		if (data?.value) {
			reset({
				stages: data.value.stages.map(elem => ({
					name: elem.name,
					identifier: elem.id,
				})),
			});
		}
	}, [data?.value]);

	const onDeleteStage = (id: number | string, index: number) => {
		if (typeof id === 'string') {
			remove(index);
		} else {
			setStageIdToDelete({
				index,
				value: id,
			});
		}
	};

	const onCancelEditing = () => {
		reset({
			stages:
				data?.value?.stages?.map(elem => ({
					name: elem.name,
					identifier: elem.id,
				})) || [],
			deletedIds: [],
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box h="100vh" p="12px" pl={0}>
				<Box
					bg="white"
					h="100%"
					borderRadius="8px"
					border="1px solid"
					borderColor="gray.20"
					overflowY="auto"
					p="32px">
					<Flex flexDirection="column" maxW="container.sm">
						<PipelineControlButtons
							isSaveLoading={saveStagesChangesMutation.isPending}
							isActive={isDirty}
							onCancel={onCancelEditing}
						/>
						<Text {...modalSubHeaderStyleProps}>
							Pipeline stages
						</Text>
						<Text
							{...settingsRegularTextStyleProps}
							mt="12px"
							mb="16px">
							Add up to 10 stages in your pipeline
						</Text>
						<Box
							bg="gray.10"
							borderRadius="4px"
							border="1px solid"
							borderColor="gray.20">
							<Box p="16px">
								<Text
									textTransform="uppercase"
									color="gray.35"
									fontWeight={600}
									fontSize="11px"
									mb="14px"
									mt="4px">
									Stages
								</Text>
								{isLoading ? (
									<Loader
										spinnerSize="md"
										centerHeight="200px"
									/>
								) : (
									<VStack spacing="2px" align="stretch">
										{fields.map((item, index) => (
											<StageItem
												key={item.id}
												index={index}
												name={item.name}
												control={control}
												onDelete={() =>
													onDeleteStage(
														item.identifier,
														index,
													)
												}
											/>
										))}
									</VStack>
								)}
							</Box>
							<PipelineAddStageButton
								onClick={onAddStage}
								isDisabled={fields.length >= 10}
							/>
						</Box>
					</Flex>
				</Box>
				<ConfirmDeleteModal
					onClose={() => {
						setStageIdToDelete(null);
					}}
					isOpen={!!stageIdToDelete}
					onConfirm={onDeleteConfirm}
					confirmLoading={false}
					title="Delete stage"
					description="This will permanently delete this stage and reassign its deals to the initial stage"
					confirmLabel="Delete stage"
				/>
				<BlockerDialog isDirty={isDirty} />
			</Box>
		</form>
	);
};

export default PipelineSettingsPage;
