export const decoyUser = {
	avatarThumbnailLink: '',
	avatarUrl: '',
	firstName: '',
	id: 0,
	isOnboarded: true,
	lastName: '',
	workSpace: {
		name: 'Apple',
		id: 0,
		isAdmin: false,
		isArchiveVisible: false,
		teamChat: null,
		avatarUrl: '',
		avatarThumbnailUrl: '',
	},
	telegramUserId: 0,
	timeZoneId: '',
};

export const decoyStatuses = [
	{
		id: 13,
		typeId: 1,
		name: 'Lead',
		position: 1,
	},
	{
		id: 14,
		typeId: 2,
		name: 'Contacted',
		position: 2,
	},
	{
		id: 15,
		typeId: 3,
		name: 'Negotiation',
		position: 3,
	},
	{
		id: 16,
		typeId: 4,
		name: 'Ready to start',
		position: 4,
	},
	{
		id: 17,
		typeId: 5,
		name: 'Closed',
		position: 5,
	},
];

export const decoyTasks = {
	13: [
		{
			cardId: 1,
			cardName: 'Stripe <> Dise',
			chatTelegramId: 1,
			tasksCount: 0,
			trackedLastTelegramMessageUnixTime: 1715677739,
			currentUserTagged: false,
			hasAccess: true,
			status: {
				id: 13,
				typeId: 1,
				name: 'Lead',
			},
			telegramMessage: {
				senderName: 'John',
				text: 'Looks good',
			},
			message: {
				text: '@vtlchlshv tagg',
				senderId: 6,
				senderName: 'Mykhailo Rospopchuk ✙',
				telegramUserId: 1,
				unreadCount: 1,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
		{
			cardId: 2,
			cardName: 'Google <> Tesla',
			chatTelegramId: 2,
			tasksCount: 2,
			trackedLastTelegramMessageUnixTime: 1715677799,
			currentUserTagged: true,
			hasAccess: true,
			status: {
				id: 14,
				typeId: 2,
				name: 'Opportunity',
			},
			telegramMessage: {
				senderName: 'Elon Musk',
				text: "Let's discuss the next steps",
			},
			message: {
				text: '@elonmusk tagged',
				senderId: 7,
				senderName: 'Sundar Pichai',
				telegramUserId: 2,
				unreadCount: 3,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
		{
			cardId: 3,
			chatId: 3,
			cardName: 'Amazon <> Microsoft',
			chatTelegramId: 3,
			tasksCount: 1,
			trackedLastTelegramMessageUnixTime: 1715677899,
			currentUserTagged: false,
			hasAccess: true,
			status: {
				id: 15,
				typeId: 3,
				name: 'Negotiation',
			},
			telegramMessage: {
				senderName: 'Jeff Bezos',
				text: 'We need to finalize the deal',
			},
			message: {
				text: '@satyanadella mentioned',
				senderId: 8,
				senderName: 'Satya Nadella',
				telegramUserId: 3,
				unreadCount: 2,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
		{
			cardId: 10,
			cardName: 'Coca-Cola <> Pepsi',
			chatTelegramId: 10,
			tasksCount: 7,
			trackedLastTelegramMessageUnixTime: 1715678599,
			currentUserTagged: true,
			hasAccess: true,
			status: {
				id: 22,
				typeId: 10,
				name: 'Closed',
			},
			telegramMessage: {
				senderName: 'James Quincey',
				text: 'The deal is finalized',
			},
			message: {
				text: '@ramonlaguarta tagged',
				senderId: 15,
				senderName: 'Ramon Laguarta',
				telegramUserId: 4,
				unreadCount: 4,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
	],
	14: [
		{
			cardId: 4,
			cardName: 'Apple <> IBM',
			chatTelegramId: 4,
			tasksCount: 5,
			trackedLastTelegramMessageUnixTime: 1715677999,
			currentUserTagged: true,
			hasAccess: true,
			status: {
				id: 16,
				typeId: 4,
				name: 'Proposal Sent',
			},
			telegramMessage: {
				senderName: 'Tim Cook',
				text: 'Review the latest proposal',
			},
			message: {
				text: '@ginnirometty tagged',
				senderId: 9,
				senderName: 'Ginni Rometty',
				telegramUserId: 5,
				unreadCount: 4,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
		{
			cardId: 8,
			cardName: 'Nike <> Adidas',
			chatTelegramId: 8,
			tasksCount: 2,
			trackedLastTelegramMessageUnixTime: 1715678399,
			currentUserTagged: true,
			hasAccess: true,
			status: {
				id: 20,
				typeId: 8,
				name: 'Initial Contact',
			},
			telegramMessage: {
				senderName: 'John Donahoe',
				text: "Let's start the collaboration",
			},
			message: {
				text: '@kasperrorsted mentioned',
				senderId: 13,
				senderName: 'Kasper Rorsted',
				telegramUserId: 6,
				unreadCount: 1,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
	],
	15: [
		{
			cardId: 5,
			cardName: 'Facebook <> Netflix',
			chatTelegramId: 5,
			tasksCount: 0,
			trackedLastTelegramMessageUnixTime: 1715678099,
			currentUserTagged: false,
			hasAccess: true,
			status: {
				id: 17,
				typeId: 5,
				name: 'Contract Signed',
			},
			telegramMessage: {
				senderName: 'Mark Zuckerberg',
				text: 'Contract is ready to be signed',
			},
			message: {
				text: '@reedhastings mentioned',
				senderId: 10,
				senderName: 'Reed Hastings',
				telegramUserId: 7,
				unreadCount: 1,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
	],
	16: [
		{
			cardId: 6,
			cardName: 'Uber <> Airbnb',
			chatTelegramId: 6,
			tasksCount: 4,
			trackedLastTelegramMessageUnixTime: 1715678199,
			currentUserTagged: true,
			hasAccess: true,
			status: {
				id: 18,
				typeId: 6,
				name: 'In Progress',
			},
			telegramMessage: {
				senderName: 'Dara Khosrowshahi',
				text: 'Working on the integration',
			},
			message: {
				text: '@bchesky tagged',
				senderId: 11,
				senderName: 'Brian Chesky',
				telegramUserId: 8,
				unreadCount: 2,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
		{
			cardId: 9,
			cardName: 'Oracle <> SAP',
			chatTelegramId: 9,
			tasksCount: 6,
			trackedLastTelegramMessageUnixTime: 1715678499,
			currentUserTagged: false,
			hasAccess: true,
			status: {
				id: 21,
				typeId: 9,
				name: 'Under Review',
			},
			telegramMessage: {
				senderName: 'Larry Ellison',
				text: 'Review the technical details',
			},
			message: {
				text: '@christian_klein tagged',
				senderId: 14,
				senderName: 'Christian Klein',
				telegramUserId: 9,
				unreadCount: 3,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
	],
	17: [
		{
			cardId: 7,
			cardName: 'Spotify <> Disney',
			chatTelegramId: 7,
			tasksCount: 3,
			trackedLastTelegramMessageUnixTime: 1715678299,
			currentUserTagged: false,
			hasAccess: true,
			status: {
				id: 19,
				typeId: 7,
				name: 'Completed',
			},
			telegramMessage: {
				senderName: 'Daniel Ek',
				text: 'Project completed successfully',
			},
			message: {
				text: '@bobiger tagged',
				senderId: 12,
				senderName: 'Bob Iger',
				telegramUserId: 10,
				unreadCount: 5,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
		{
			cardId: 11,
			cardName: 'Intel <> AMD',
			chatTelegramId: 11,
			tasksCount: 1,
			trackedLastTelegramMessageUnixTime: 1715678699,
			currentUserTagged: false,
			hasAccess: true,
			status: {
				id: 23,
				typeId: 11,
				name: 'Pending Approval',
			},
			telegramMessage: {
				senderName: 'Pat Gelsinger',
				text: 'Awaiting approval from management',
			},
			message: {
				text: '@lisa_su tagged',
				senderId: 16,
				senderName: 'Lisa Su',
				telegramUserId: 11,
				unreadCount: 2,
				workspaceCardActivityId: null,
				activityTypeId: null,
			},
		},
	],
};
