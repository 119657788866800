import { useInfiniteQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import { NotificationGroupData } from '../types';
import { getMillisecondsUntilNextNineAM } from '../../../utils';
import { useGetWorkspaceId } from '../../../hooks';

// const PAGE_SIZE = 30;

export const useGetNotifications = (isArchived: boolean) => {
	const workspaceId = useGetWorkspaceId();

	const refetchInterval = getMillisecondsUntilNextNineAM();

	const fetchData = async ({ pageParam = 0 }) => {
		const res: ApiResponse<{
			totalCount: number;
			groupedNotifications: NotificationGroupData[];
		}> = await API.get(`/api/workspaces/${workspaceId}/notifications`, {
			IsArchived: isArchived,
			// Offset: pageParam * PAGE_SIZE,
			// Limit: PAGE_SIZE,
		});

		return { ...res.value, pageParam };
	};

	return useInfiniteQuery({
		initialPageParam: 0,
		queryKey: [QueryKeys.GET_NOTIFICATIONS, workspaceId, isArchived],
		queryFn: ({ pageParam }) => fetchData({ pageParam }),
		getNextPageParam: () => {
			return null;
		},
		//TODO uncomment code above for pagination
		// getNextPageParam: lastPage => {
		// 	return lastPage?.groupedNotifications?.length === PAGE_SIZE
		// 		? lastPage.pageParam + 1
		// 		: null;
		// },
		enabled: !!workspaceId,
		refetchInterval,
		refetchOnWindowFocus: false,
	});
};
