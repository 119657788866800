import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../constants';
import { BoardCardItemResponse } from '../pages/Dashboard/types';
import { ApiResponse } from '../types';
import { useGetMatchedWorkspaceId } from './useGetWorkspaceId';

export const useGetCardById = (cardId: number) => {
	const workspaceId = useGetMatchedWorkspaceId();
	const queryClient = useQueryClient();
	return queryClient
		.getQueryData<
			ApiResponse<{ cards: BoardCardItemResponse[] }>
		>([QueryKeys.GET_BOARD_CARDS, workspaceId])
		?.value?.cards?.find(card => card.cardId === cardId);
};
