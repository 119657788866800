/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import {
	ApiChat,
	ApiChatFullInfo,
	ApiMessage,
	ApiPeer,
} from '../../../telegram-tt/src/api/types';
import { allowedForSyncChatTypes } from '../constants';

type TgChatInFolderResponse = {
	id: number;
	chat: ApiChat | undefined;
	fullInfo: ApiChatFullInfo | undefined;
	peerInfo: ApiPeer | undefined;
	msg: ApiMessage | undefined;
};

export function filterArrayByIds(array: number[], referenceArray: number[]) {
	return array.filter(id => referenceArray.includes(id));
}

export async function prepareFoldersSyncPayload(data: {
	promises: (
		| TgChatInFolderResponse[]
		| Promise<TgChatInFolderResponse[]>
		| undefined
	)[];
	foldersArray: number[];
}) {
	const promiseResult = await Promise.all(data.promises);

	const payload = promiseResult
		.map((elem, index) => ({
			telegramFolderId: data.foldersArray[index],
			chats:
				elem
					?.filter(elem =>
						allowedForSyncChatTypes.includes(elem.chat?.type || ''),
					)
					?.map(item => ({
						telegramChatId: +item.id,
						name: item.chat?.title || '',
					}))
					?.filter(it => !!it.name) || [],
		}))
		.filter(elem => elem.chats?.length);

	return payload;
}
