import { Divider, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { getViewDateByFormat } from '../../../utils';

interface DateDividerProps {
	date: string;
}

export const DateDivider: React.FC<DateDividerProps> = ({ date }) => {
	return (
		<HStack w="full" px={6} spacing={4} align="center">
			<Text
				fontSize="11px"
				lineHeight="13px"
				color="gray.35"
				whiteSpace="nowrap"
				textTransform="uppercase">
				{getViewDateByFormat(date, 'dddd, MMM D')}
			</Text>
			<Divider color="gray.35" opacity={1} />
		</HStack>
	);
};
