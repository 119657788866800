import React, { useMemo } from 'react';
import { Box, VStack, BoxProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabels,
	LimitedAccessView,
} from './components';
import { InternalChatMessagePreview } from '../../pages/Dashboard/types';
import { RootStore } from '../../redux/createStore';
import {
	CardDefaultStatusEnum,
	LabelItemType,
	TeamChatData,
} from '../../types';
import { getActivitySubjectName } from '../../utils';
import { useGetMatchedWorkspaceId } from '../../hooks';
import { useChat } from './hooks';

interface ITaskCardSoloProps {
	wrapperProps?: BoxProps;
	isDragging: boolean;
	onRedirect?: VoidFunction;
	cardName: string;
	hasAccess?: boolean;
	chatTelegramId: number;
	companyChatData?: InternalChatMessagePreview | null;
	teamChatData?: TeamChatData | null;
	currentUserTagged?: boolean;
	isTeamChat?: boolean;
	trackedLastTelegramMessageUnixTime?: number | null;
	cardId: number;
	cardStatusId?: number;
	teamTgIds?: number[];
	labels?: LabelItemType[];
}

export const TaskCardSolo: React.FC<ITaskCardSoloProps> = ({
	isDragging,
	cardName,
	onRedirect,
	chatTelegramId,
	companyChatData,
	wrapperProps,
	currentUserTagged,
	isTeamChat = false,
	trackedLastTelegramMessageUnixTime,
	cardId,
	cardStatusId,
	teamTgIds,
	labels,
}) => {
	const workspaceId = useGetMatchedWorkspaceId();

	const avatars = useSelector((state: RootStore) => state.avatars);
	const cachedData = useSelector(
		(state: RootStore) => state.telegramState.chats[chatTelegramId],
	);

	useChat(chatTelegramId.toString(), !cachedData?.isRefetchDisabled);

	const userName = cachedData?.sender
		? getActivitySubjectName(cachedData.sender)
		: '';

	const isLoaded = cachedData?.isLoaded;

	const showTrackStatusBtn = useMemo(() => {
		const isLastMessageFromTeam = teamTgIds?.includes(
			cachedData?.sender?.id || 0,
		);

		return (
			!isTeamChat &&
			cachedData?.hasAccess &&
			!isLastMessageFromTeam &&
			cardStatusId !== CardDefaultStatusEnum.ARCHIVE &&
			!isDragging
		);
	}, [
		isTeamChat,
		cachedData?.hasAccess,
		isDragging,
		cardStatusId,
		cachedData?.sender?.id,
		teamTgIds,
	]);

	return (
		<Box
			w="100%"
			borderRadius="4px"
			boxShadow="light"
			borderWidth="1px"
			borderColor="gray.20"
			role="button"
			bg={isDragging ? 'gray.20' : 'gray.10'}
			onClick={onRedirect}
			_hover={{
				'.card__track-status-btn': {
					visibility: showTrackStatusBtn ? 'visible' : 'hidden',
					opacity: showTrackStatusBtn ? 1 : 0,
				},
			}}
			{...wrapperProps}>
			<VStack
				spacing={0}
				align="flex-start"
				p="8px 8px 4px 8px"
				borderBottomWidth="1px"
				borderBottomColor="transparentLight.5">
				<CardHeader
					title={cardName}
					isLoaded={isLoaded}
					chatTelegramId={chatTelegramId}
					cardId={cardId}
					lastTgChatMessageDate={
						cachedData?.lastMessage?.date || null
					}
					trackedLastTelegramMessageUnixTime={
						trackedLastTelegramMessageUnixTime
					}
					workspaceId={workspaceId}
					cachedData={cachedData}
				/>
				<CardLabels isLoaded={isLoaded} data={labels || []} />

				<CardBody
					hasAccess={cachedData?.hasAccess}
					unreadCount={cachedData?.unreadCount}
					message={cachedData?.lastMessage || null}
					name={userName}
					storedAvatar={avatars[`${cachedData?.sender?.id || 0}`]}
					isLoaded={isLoaded}
					chatId={cachedData?.sender?.id || 0}
					chatTelegramId={chatTelegramId}
					senderData={cachedData?.sender}
					isEnableFetchSenderAvatar={
						!!cachedData?.isLoaded &&
						!teamTgIds?.includes(cachedData?.sender?.id || 0) &&
						!!cachedData?.sender?.id &&
						!!cachedData?.hasAccess
					}
				/>
				<LimitedAccessView
					hasAccess={cachedData?.hasAccess}
					isLoaded={isLoaded}
				/>
			</VStack>
			{companyChatData ? (
				<CardFooter
					isLoaded={!!cachedData}
					avatar={avatars[`${companyChatData.telegramUserId}`]}
					currentUserTagged={currentUserTagged}
					{...companyChatData}
				/>
			) : null}
		</Box>
	);
};
