import React from 'react';
import {
	Text,
	Menu,
	MenuButton,
	MenuList,
	Button,
	MenuGroup,
	HStack,
} from '@chakra-ui/react';
import { WorkspaceSelectItem } from './WorkspaceSelectItem';
import { WorkspaceItemImage } from './WorkspaceItemImage';
import { AddWorkspaceButton } from './AddWorkspaceButton';
import { useGetWorkspaces } from '../../../../queries';
import { Icon } from '../../../Icon/Icon';
import { colors } from '../../../../theme/colors';

interface IWorkspaceSelectProps {
	imageLink?: string;
	name: string;
}

export const WorkspaceSelect: React.FC<IWorkspaceSelectProps> = ({
	imageLink,
	name,
}) => {
	const { data } = useGetWorkspaces();
	return (
		<Menu autoSelect={false}>
			<MenuButton
				as={Button}
				rightIcon={
					<Icon
						name="chevron-down"
						width="14px"
						height="14px"
						color={colors.tertiary}
					/>
				}
				background="transparent"
				color="primary"
				height="auto"
				borderRadius="4px"
				iconSpacing="4px"
				p="2px 2px 2px 4px"
				_hover={{
					bg: 'transparentLight.5',
				}}
				_active={{
					bg: 'transparentLight.10',
				}}>
				<HStack spacing={2}>
					<WorkspaceItemImage imageLink={imageLink} />
					<Text fontWeight={500} lineHeight="20px" isTruncated={true}>
						{name}
					</Text>
				</HStack>
			</MenuButton>
			<MenuList
				py={0}
				borderRadius="8px"
				overflow="hidden"
				border="1px solid"
				borderColor="gray.20"
				maxH="600px"
				overflowY="auto"
				mt="11px"
				maxW="300px">
				<MenuGroup
					title="Your workspaces"
					fontSize="9px"
					fontWeight="600"
					color="gray.35"
					padding="4.5px 8px"
					textTransform="uppercase"
					background="#00000005"
					margin="0">
					{data?.value?.map(elem => (
						<WorkspaceSelectItem
							key={elem.id}
							name={elem.name}
							id={elem.id}
							imageLink={elem.avatarThumbnailUrl}
						/>
					))}

					<AddWorkspaceButton />
				</MenuGroup>
			</MenuList>
		</Menu>
	);
};
