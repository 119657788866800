/* eslint-disable indent */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import {
	ApiResponse,
	ApiResponseEmpty,
	LabelItemType,
	UpdateLabelAssignStatusPayload,
} from '../../../../../types';
import { Alerter } from '../../../../../utils';
import { QueryKeys } from '../../../../../constants';
import { BoardCardItemResponse } from '../../../../Dashboard/types';

//TODO refactor that query after backend change null in card labels

export const useUpdateLabelAssignStatus = () => {
	const queryClient = useQueryClient();

	return useMutation<
		ApiResponseEmpty,
		unknown,
		UpdateLabelAssignStatusPayload
	>({
		mutationKey: [QueryKeys.UPDATE_LABEL_ASSIGN_STATUS],
		mutationFn: payload => {
			const { workspaceId, workspaceLabelId, ...bodyPayload } = payload;

			return API.put(
				`/api/workspaces/${workspaceId}/labels/${workspaceLabelId}/cards`,
				bodyPayload,
			);
		},
		onSuccess: (data, variables) => {
			if (!data.success) {
				return;
			}

			const { workspaceId, workspaceLabelId, cardId, isAssigned } =
				variables;

			Alerter.success(
				data.messages?.[0]?.message ||
					`Label was successfully ${isAssigned ? 'assigned' : 'un-assigned'} to card!`,
			);

			const matchedLabel = queryClient
				.getQueryData<
					ApiResponse<{ workspaceLabels: LabelItemType[] }>
				>([QueryKeys.GET_WORKSPACE_LABELS, workspaceId])
				?.value?.workspaceLabels?.find(
					el => el.workspaceLabelId === workspaceLabelId,
				);

			queryClient.setQueryData<
				ApiResponse<{ cards: BoardCardItemResponse[] }>
			>([QueryKeys.GET_BOARD_CARDS, workspaceId], oldData => {
				if (!oldData || !matchedLabel) {
					return oldData;
				}

				return {
					...oldData,
					value: {
						cards: oldData.value.cards?.map(card => {
							if (card.cardId !== cardId) {
								return card;
							}

							const updatedLabels = isAssigned
								? [
										...(card.labels || []),
										{
											workspaceLabelId:
												matchedLabel.workspaceLabelId,
											colourId: matchedLabel.colourId,
											name: matchedLabel.name,
										},
									]
								: card.labels?.filter(
										el =>
											el.workspaceLabelId !==
											workspaceLabelId,
									) || [];
							return {
								...card,
								labels: updatedLabels,
							};
						}),
					},
				};
			});
		},
	});
};
