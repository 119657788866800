import React, { useCallback } from 'react';
import { Text, Button } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Icon } from '../../Icon/Icon';
import { useLogout } from '../../../queries';
import { useTelegram } from '../../../services';
import { RootStore } from '../../../redux/createStore';

export const LogoutButton: React.FC = () => {
	const tg = useTelegram();
	const { mutateAsync: logoutMutation } = useLogout();
	const { token } = useSelector((state: RootStore) => state.auth);

	const onLogout = useCallback(async () => {
		try {
			const crmLogoutRes = await logoutMutation({
				accessToken: token.accessToken,
				refreshToken: token.refreshToken,
			});

			if (!crmLogoutRes?.success || !tg) {
				return;
			}

			await tg.actions.proxy.signOut({
				forceInitApi: true,
			});
		} catch (error) {
			console.log('log out error: ', error);
		}
	}, [tg, logoutMutation, token]);

	return (
		<Button
			variant="ghost"
			gap={2}
			size="sm"
			p={1}
			w="100%"
			height="24px"
			justifyContent="flex-start"
			borderRadius="4px"
			onClick={onLogout}
			_hover={{ bg: 'transparentLight.10' }}>
			<Icon name="logout" width="16px" height="16px" />
			<Text lineHeight="16px" color="secondary">
				Log out
			</Text>
		</Button>
	);
};
