export const combineRegExps = (regExps: RegExp[]) => {
	const serializedRegexParser = /^\/(.+)\/(\w+)?$/;
	return new RegExp(
		regExps
			.map(regex => {
				const [, regexString, regexFlags] = serializedRegexParser.exec(
					regex.toString(),
				)!;

				if (regexFlags) {
					console.warn(
						`RegExp flags are not supported. Change /${regexString}/${regexFlags} into /${regexString}/`,
					);
				}

				return `(${regexString})`;
			})
			.join('|'),
		'g',
	);
};
