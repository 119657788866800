import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, ApiResponseEmpty, CardTaskStatusEnum } from '../types';
import API from '../api/api.base';
import { QueryKeys } from '../constants';
import { Alerter } from '../utils';
import { CardTaskItem, UpdateTaskPayload } from '../pages/Chat/types';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';

export const useUpdateTask = (isTaskPage?: boolean) => {
	const queryClient = useQueryClient();
	const { id } = useSelector((state: RootStore) => state.auth.user);

	return useMutation<ApiResponseEmpty, unknown, UpdateTaskPayload>({
		mutationKey: [QueryKeys.UPDATE_TEMPLATE],
		mutationFn: payload => {
			const { workspaceId, cardTaskId, ...bodyPayload } = payload;
			return API.put(
				`/api/workspaces/${workspaceId}/cards/tasks/${cardTaskId}/update-task`,
				bodyPayload,
			);
		},
		onSuccess: async (data, payload) => {
			const { cardId, workspaceId, userId } = payload;
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Error occurred while updating task',
				);
				return;
			}
			Alerter.success('Task successfully updated');

			if (isTaskPage) {
				const currentUserTask = queryClient
					.getQueryData<{
						pageParams: number[];
						pages: { count: number; tasks: CardTaskItem[] }[];
					}>([QueryKeys.GET_ALL_CURRENT_USER_TASKS, workspaceId])
					?.pages?.flatMap(page => page.tasks)
					?.find(task => task.id === payload.cardTaskId);

				if (currentUserTask) {
					queryClient.invalidateQueries({
						queryKey: [
							QueryKeys.GET_ALL_CURRENT_USER_TASKS,
							workspaceId,
						],
					});
					if (userId !== id) {
						queryClient.invalidateQueries({
							queryKey: [
								QueryKeys.GET_ALL_TASKS_OTHER_USERS,
								workspaceId,
							],
						});
					}
					queryClient.setQueryData<ApiResponse<number> | undefined>(
						[QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
						oldData => {
							if (
								oldData &&
								!currentUserTask.isArchived &&
								userId !== id
							) {
								const newValue =
									oldData.value &&
									currentUserTask.status ===
										CardTaskStatusEnum.TODO
										? oldData.value - 1
										: oldData.value;

								return {
									...oldData,
									value: newValue,
								};
							}

							return oldData;
						},
					);
					return;
				}
				const otherUsersTask = queryClient
					.getQueryData<{
						pageParams: number[];
						pages: { count: number; tasks: CardTaskItem[] }[];
					}>([QueryKeys.GET_ALL_TASKS_OTHER_USERS, workspaceId])
					?.pages?.flatMap(page => page.tasks)
					?.find(task => task.id === payload.cardTaskId);
				if (otherUsersTask) {
					queryClient.invalidateQueries({
						queryKey: [
							QueryKeys.GET_ALL_TASKS_OTHER_USERS,
							workspaceId,
						],
					});
					if (userId === id) {
						queryClient.invalidateQueries({
							queryKey: [
								QueryKeys.GET_ALL_CURRENT_USER_TASKS,
								workspaceId,
							],
						});
					}
					queryClient.setQueryData<ApiResponse<number> | undefined>(
						[QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
						oldData => {
							if (
								oldData &&
								!otherUsersTask.isArchived &&
								userId === id
							) {
								const newValue =
									oldData.value &&
									otherUsersTask.status ===
										CardTaskStatusEnum.TODO
										? oldData.value + 1
										: oldData.value;

								return {
									...oldData,
									value: newValue,
								};
							}

							return oldData;
						},
					);
					return;
				}
				return;
			}
			if (!cardId) {
				return;
			}
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
			});
		},
	});
};
