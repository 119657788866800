import { colors } from '../theme/colors';

export const getColumnDragBackgroundColor = (
	isDraggingOver: boolean,
	isDraggingFrom: boolean,
) => {
	if (isDraggingOver) {
		return colors.green[20];
	}
	if (isDraggingFrom) {
		return colors.sky[20];
	}
	return 'transparent';
};
