import { CardTaskPriority } from '../../types';

export enum NotificationTypeEnum {
	TASK = 1,
	REMINDER = 2,
}

export enum NotificationStatusEnum {
	INBOX = 1,
	ARCHIVED = 2,
}

export type NotificationItemData = {
	id: number;
	type: NotificationTypeEnum;
	cardId: number;
	cardName: string;
	description: string;
	scheduledAt: string;
	priorityId: number | null;
	priorityName: string | null;
	telegramChatId: number;
};

export type NotificationGroupData = {
	date: string;
	notifications: NotificationItemData[];
};

export type UpdateNotificationStatus = {
	id: number;
	status: NotificationStatusEnum;
};
