import { iterateMentionsMarkup } from './iterateMentionsMarkup';
import { ConfigFromChildren } from '../types';

export const getPlainText = (value: string, config: ConfigFromChildren[]) => {
	let result = '';
	iterateMentionsMarkup(
		value,
		config,
		(match, index, plainTextIndex, id, display) => {
			result += display;
		},
		plainText => {
			result += plainText;
		},
	);
	return result;
};
