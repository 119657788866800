import { useEffect, useState } from 'react';

export const useDebounce = (
	value: string,
	timeout = 500,
	cb?: () => void,
): string => {
	const [state, setState] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setState(value);
			cb?.();
		}, timeout);

		return () => clearTimeout(handler);
	}, [value, timeout]);

	return state;
};
