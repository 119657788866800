import React, { useMemo } from 'react';
import { Flex, Center, HStack, Text, Avatar, Box } from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { CardActivityItem } from '../../../types';
import {
	getActivityIconByTypeId,
	getActivitySubjectName,
	getTimePassedActivity,
} from '../../../../../utils';
import { colors } from '../../../../../theme/colors';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../../../redux/createStore';

interface ActivityItemProps extends CardActivityItem {}

export const ActivityItem: React.FC<ActivityItemProps> = ({
	authorInfo,
	content,
	createdAt,
	operation,
	subjectInfo,
	activityTypeId,

	// workspaceActivityId,
	// id,
}) => {
	const avatars = useSelector((state: RootStore) => state.avatars);

	const iconName = getActivityIconByTypeId(activityTypeId);
	const passedTime = getTimePassedActivity(createdAt);
	const subjectName = getActivitySubjectName(subjectInfo);
	const authorName = getActivitySubjectName(authorInfo);

	const isTask = activityTypeId === 6;
	const avatarUrl = useMemo(() => {
		const userId = isTask
			? subjectInfo?.telegramUserId
			: authorInfo?.telegramUserId;

		const avatar = userId ? avatars[`${userId}`] : '';

		return avatar;
	}, [subjectInfo, authorInfo, isTask, avatars]);
	return (
		<Flex gap="16px" alignItems="center">
			<Center
				bg="gray.10"
				width="24px"
				height="auto"
				alignSelf="stretch"
				borderLeftWidth="1px"
				borderRightWidth="1px"
				flexShrink={0}
				borderColor="gray.15">
				<Icon
					name={iconName}
					height="14"
					width="14"
					color={colors.gray[40]}
				/>
			</Center>
			<HStack spacing={1} flexWrap="wrap">
				<Box bg="transparentLight.5" px="3px" borderRadius="4px">
					<Text color="primary" fontSize="13px">
						{content}
					</Text>
				</Box>
				<Text color="secondary">{operation}</Text>

				<Box w="14px" h="14px">
					<Avatar boxSize="14px" src={avatarUrl || ''} />
				</Box>
				<Text color="primary" fontSize="13px">
					{isTask ? subjectName : authorName}
				</Text>

				{/* <IconButton
					aria-label="Go to details"
					boxSize="24px"
					variant="outline"
					minW="auto">
					<Icon name="fast-forward" height="16" width="16" />
				</IconButton> */}
			</HStack>
			<Text fontSize="12px" color="gray.45" ml="auto" flexShrink={0}>
				{passedTime}
			</Text>
		</Flex>
	);
};
