import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import {
	Button,
	ButtonProps,
	HStack,
	IconButton,
	PlacementWithLogical,
	Text,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Select } from '../RadixSelect/Select';
import { SelectItem } from '../RadixSelect/SelectItem';
import { colors } from '../../theme/colors';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '../RadixPopover/Popover';
import { generateYearsArray } from '../../utils';

import styles from './datepicker.module.css';
import './react-datepicker.css';

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

interface CustomDatePickerProps {
	buttonProps?: ButtonProps;
	buttonTrigger?: React.ReactNode;
	startDate: Date | null;
	setStartDate: (payload: Date) => void;
	minDate?: Date;
	maxDate?: Date;
	isOpen: boolean;
	onOpenChange: VoidFunction;
	placement?: PlacementWithLogical;
	datePickerHeight?: string;
	avoidDays?: boolean;
	children?: ReactNode;
	showYearDropdown?: boolean;
	showMonthDropdown?: boolean;
	datePickerAlignPlacementAlignOffset?: number;
	datePickerAlignPlacementSideOffset?: number;
	datePickerPlacementSide?: 'top' | 'right' | 'bottom' | 'left';
	datePickerPlacementAlign?: 'center' | 'start' | 'end';
	pickerMinYear?: number;
	pickerMaxYear?: number;
}
export const CustomDatePicker = React.forwardRef<
	HTMLDivElement | null,
	CustomDatePickerProps
>(
	(
		{
			buttonProps,
			buttonTrigger,
			startDate,
			setStartDate,
			minDate,
			maxDate,
			isOpen,
			onOpenChange,
			avoidDays,
			children,
			showYearDropdown,
			showMonthDropdown,
			datePickerAlignPlacementAlignOffset,
			datePickerAlignPlacementSideOffset,
			datePickerPlacementSide = 'left',
			datePickerPlacementAlign = 'end',
			pickerMinYear = 2024,
			pickerMaxYear,
		},
		ref,
	) => {
		const years = generateYearsArray(pickerMinYear, pickerMaxYear);

		const onChange = (date: Date | null) => {
			if (!date) {
				return;
			}
			setStartDate(date);
		};

		return (
			<Popover open={isOpen} onOpenChange={onOpenChange}>
				<PopoverTrigger asChild={true}>
					{buttonTrigger ? (
						buttonTrigger
					) : (
						<Button {...buttonProps}>
							<Text fontSize="14px">
								{dayjs(startDate).format('MMM D, YYYY')}
							</Text>
						</Button>
					)}
				</PopoverTrigger>
				<PopoverContent
					ref={ref}
					sideOffset={datePickerAlignPlacementSideOffset}
					alignOffset={datePickerAlignPlacementAlignOffset}
					align={datePickerPlacementAlign}
					side={datePickerPlacementSide}
					className={styles.DatePickerPopoverContent}>
					<DatePicker
						calendarStartDay={1}
						renderCustomHeader={({
							date,
							changeYear,
							changeMonth,
							decreaseMonth,
							increaseMonth,
						}) => {
							const isPrevMonthButtonDisabled =
								dayjs(date).subtract(1, 'month').year() <
								pickerMinYear;
							const isNextMonthButtonDisabled = pickerMaxYear
								? dayjs(date).add(1, 'month').year() >
									pickerMaxYear
								: false;
							return (
								<HStack justify="space-between" mb="12px">
									<HStack spacing={1}>
										<Select
											placeholder="month"
											rootProps={{
												value: months[date.getMonth()],
												onValueChange: value => {
													const monthNum =
														months.indexOf(value);

													changeMonth(
														Number(monthNum),
													);
												},
											}}
											triggerStyles={{
												width: '98px',
												border: 'none',
												background:
													colors.transparentLight[5],
											}}>
											{months.map((month, index) => (
												<SelectItem
													key={index}
													value={month}
													disableIndicator={true}
													className="NormalizedPaddings">
													{month}
												</SelectItem>
											))}
										</Select>

										<Select
											placeholder="year"
											rootProps={{
												value: date
													.getFullYear()
													.toString(),
												onValueChange: value => {
													changeYear(Number(value));
												},
											}}
											triggerStyles={{
												width: '66px',
												border: 'none',
												background:
													colors.transparentLight[5],
											}}>
											{years.map(year => (
												<SelectItem
													key={year}
													value={year.toString()}
													disableIndicator={true}
													className="NormalizedPaddings">
													{year}
												</SelectItem>
											))}
										</Select>
									</HStack>
									<HStack gap="2px">
										<IconButton
											aria-label="previous month"
											color="primary"
											bg="transparent"
											w="32px"
											minW="32px"
											h="36px"
											onClick={decreaseMonth}
											isDisabled={
												isPrevMonthButtonDisabled
											}
											_hover={{
												color: 'primary',
												bg: 'transparent',
											}}>
											<ChevronLeftIcon
												fontSize="16px"
												color="gray.40"
											/>
										</IconButton>
										<IconButton
											aria-label="next month"
											bg="transparent"
											w="32px"
											minW="32px"
											h="36px"
											onClick={increaseMonth}
											isDisabled={
												isNextMonthButtonDisabled
											}
											_hover={{
												color: 'primary',
												bg: 'transparent',
											}}>
											<ChevronRightIcon
												fontSize="16px"
												color="gray.40"
											/>
										</IconButton>
									</HStack>
								</HStack>
							);
						}}
						selected={startDate}
						startDate={startDate || undefined}
						onChange={onChange}
						minDate={minDate}
						maxDate={maxDate}
						showMonthYearPicker={avoidDays}
						children={children}
						showYearDropdown={showYearDropdown}
						showMonthDropdown={showMonthDropdown}
						inline={true}
					/>
				</PopoverContent>
			</Popover>
		);
	},
);
