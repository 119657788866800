import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
	variants: {
		solid: {
			h: '32px',
			bg: 'radial-gradient(50% 62.62% at 50% 0%, #505050 0%, #333333 100%) #333333',
			color: 'white',
			borderRadius: '8px',
			fontWeight: '600',
			fontSize: '13px',
			transition: 'background-color 0.3s, opacity 0.3s',
			_hover: {
				background:
					'radial-gradient(50% 62.62% at 50% 0%, #505050 0%, #333333 100%) #333333',
				opacity: 0.8,
				_disabled: {
					background: 'gray.15',
					color: 'gray.40',
				},
			},
			_disabled: {
				background: 'gray.15',
				color: 'gray.40',
			},
			_active: {
				bg: 'gray.300',
			},
		},
		outline: {
			h: '32px',
			borderRadius: '8px',
			fontSize: '13px',
			color: 'primary',
			fontWeight: '600',
			border: '1px solid',
			borderColor: 'gray.20',
			_hover: {
				bg: 'gray.20',
			},
		},
		ghost: {
			h: '32px',
			borderRadius: '8px',
			fontWeight: '400',
			fontSize: '13px',
			color: 'gray.40',
			_hover: {
				bg: 'transparentLight.5',
				// color: 'white',
			},
		},
		popup: {
			h: '24px',
			px: 2,
			borderRadius: '4px',
			fontSize: '13px',
			fontWeight: '500',
			color: 'white',
			bg: 'radial-gradient(50% 62.62% at 50% 0%, #505050 0%, #333333 100%) #333333',
			borderWidth: '1px',
			borderColor: 'transparentLight.5',
			_hover: {
				bg: 'radial-gradient(50% 62.62% at 50% 0%, #505050 0%, #333333 100%) #333333',
				opacity: 0.8,
				_disabled: {
					background: 'gray.15',
					color: 'gray.40',
				},
			},
			_disabled: {
				background: 'gray.15',
				color: 'gray.40',
			},
			_active: {
				bg: 'gray.300',
			},
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'solid',
	},
};
