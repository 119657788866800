import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from './auth.reducer';

const initialState: Record<string, string> = {};

const avatarsReducer = createSlice({
	name: 'avatars',
	initialState,
	reducers: {
		setAvatar: (state, action: PayloadAction<Record<string, string>>) => {
			return { ...state, ...action.payload };
		},
	},
	extraReducers(builder) {
		builder.addCase(logout, () => {
			return initialState;
		});
	},
});

export const { setAvatar } = avatarsReducer.actions;

export default avatarsReducer.reducer;
