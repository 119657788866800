/* eslint-disable indent */
import React, { useEffect, PropsWithChildren } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import { RootStore } from '../../redux/createStore';
import { AuthModal } from '../AuthModal/AuthModal';
import { setShowLoginFlow } from '../../redux/reducers/login-flow.reducer';
import { TelegramHiddenFrame } from '../../components';
import { Sidebar } from '../../components/MainLayout/components';
import { ChatPageHeader } from '../ChatPageHeader/ChatPageHeader';
import { useGetWorkspaces } from '../../queries';
import {
	setOnboardingVisibility,
	setOnboardingActiveStep,
} from '../../redux/reducers/onboarding.reducer';
import { OnboardingSteps } from '../../types';
import {
	OnboardingModal,
	InviteMembersModal,
	SyncSettingsModal,
	CreateWorkspaceModal,
} from '../../modals';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from '../../state/auth/context';
import { DID } from '../../state/auth/did';

// react hook that checks that telegram is connected and we have user id, otherwise redirects 
export function useTelegramUserId()
{
	// const navigate = useNavigate()
	
	const { telegramConnected, /* showLoginFlow, */ userId } = useSelector((state: RootStore) => state.loginFlow)

	// useEffect(() =>
	// {
	// 	if (!telegramConnected && showLoginFlow)
	// 		navigate('/')

	// }, [showLoginFlow, telegramConnected, navigate])

	if (telegramConnected && userId)
		return parseInt(userId) || undefined

	return undefined
}

export const FrameWrapper: React.FC<PropsWithChildren> = () => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { pathname } = useLocation();
	const { data: workspacesData } = useGetWorkspaces();
	
	const { user } = useSelector((store: RootStore) => store.auth);
	const { workSpace, isOnboarded } = useSelector(
		(store: RootStore) => store.auth.user,
	);
	const { showTelegramLoginModal } = useSelector(
		(store: RootStore) => store.loginFlow,
	);
	const { isOpen } = useSelector((store: RootStore) => store.onboarding);

	const navigate = useNavigate();

	const isChat = pathname.includes('/chat');
	const isOnboarding = pathname.includes('/onboarding');
	const isSelectWorkspace = pathname.includes('/select-workspace');
	const isLogin = pathname.includes('/login');

	// useTelegramUserId
	const tgid = useTelegramUserId()

	// new auth
	const { state, msgs } = useAuthContext();
	const isAuthenticated = state.type === 'authed';
	
	useEffect(() => {
		if (tgid && (state.type != "authed")) {
			if (isLogin)
				return

			msgs['ui.auth_requested']({ tgid, did: DID })
			return
		}

		if (!tgid) {
			if (state.type != 'authed') {
				dispatch(setShowLoginFlow(true));
				navigate('/login');
				queryClient.resetQueries();
			}
		}
	}, [state.type, tgid]);

	useEffect(() => {
		if (
			!workSpace &&
			user.id &&
			!location.pathname.includes('/login') &&
			!workspacesData?.value?.length
		) {
			dispatch(setOnboardingVisibility(true));
		} else if (
			!isOnboarded &&
			!isOpen &&
			user.id &&
			!location.pathname.includes('/login')
		) {
			dispatch(setOnboardingActiveStep(OnboardingSteps.SYNC_CHATS));
			dispatch(setOnboardingVisibility(true));
		}
	}, [workSpace, isOnboarded, isOpen, user, workspacesData, dispatch]);

	return (
		<>
			<Flex minHeight="100vh" bgImage="url('/background-noise.png')">
				{isAuthenticated &&
				!isLogin &&
				!isOnboarding &&
				!isSelectWorkspace ? (
					<Sidebar
						data={
							user || {
								avatarThumbnailLink: '',
								avatarUrl: '',
								firstName: '',
								id: 0,
								isOnboarded: true,
								lastName: '',
							}
						}
					/>
				) : null}
				<Box
					w={
						showTelegramLoginModal
							? '100%'
							: {
									base: '100%',
									md:
										isOnboarding || isSelectWorkspace
											? '100%'
											: 'calc(100vw - 240px)',
								}
					}
					flexDirection="column"
					pr={3}
					display={showTelegramLoginModal ? 'flex' : 'block'}>
					<Box flex={1} className="frame-top" />
					{isChat && isAuthenticated ? <ChatPageHeader /> : null}
					<Flex>
						<Box
							width={showTelegramLoginModal ? '100%' : 0}
							className="frame-left"
						/>
						<TelegramHiddenFrame />
						{isAuthenticated ? (
							<Outlet />
						) : (
							<Box
								width={showTelegramLoginModal ? '100%' : 0}
								className="frame-right"
							/>
						)}
					</Flex>
					<Box flex={1} className="frame-bottom" />
				</Box>
			</Flex>
			<AuthModal />
			{showTelegramLoginModal ? (
				<Box
					position="fixed"
					left={0}
					top={0}
					width="100vw"
					zIndex={5}
					height="100vh"
					background="blackAlpha.600"
				/>
			) : null}
			<OnboardingModal />
			<InviteMembersModal />
			<SyncSettingsModal />
			<CreateWorkspaceModal />
		</>
	);
};
