import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Icon } from '../../Icon/Icon';
import { colors } from '../../../theme/colors';
import { setSelectTeamChatModalOpenState } from '../../../redux/reducers/workspace.reducer';
interface EmptyTeamChatViewProps {
	isEmpty?: boolean;
	isAdmin?: boolean;
}

export const EmptyTeamChatView: React.FC<EmptyTeamChatViewProps> = ({
	isEmpty,
	isAdmin,
}) => {
	const dispatch = useDispatch();

	if (!isEmpty) {
		return null;
	}
	return (
		<Box mx="auto" pt={isAdmin ? 3 : 1.5} pb={isAdmin ? 4 : 2}>
			{isAdmin ? (
				<Button
					h="24px"
					mx="auto"
					borderRadius="4px"
					bg="transparent"
					borderWidth="1px"
					borderColor="transparentLight.5"
					fontSize="13px"
					alignItems="center"
					transition="background 0.3s ease"
					leftIcon={
						<Icon
							name="plus"
							width="14px"
							height="14px"
							color={colors.secondary}
						/>
					}
					color="secondary"
					onClick={() =>
						dispatch(setSelectTeamChatModalOpenState(true))
					}
					_hover={{
						bg: 'transparentLight.5',
					}}>
					Select a chat
				</Button>
			) : (
				<Text align="center">
					The admin has not yet selected the team chat
				</Text>
			)}
		</Box>
	);
};
