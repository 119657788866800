import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
	ApiResponse,
	ApiResponseEmpty,
	CurrentUser,
	WorkspaceSelectItem,
} from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { useGetWorkspaceId } from '../../../hooks';

export const useUpdateWorkspaceInfo = () => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	return useMutation<ApiResponseEmpty, unknown, string>({
		mutationKey: [QueryKeys.UPDATE_WORKSPACE_INFO],
		mutationFn: payload =>
			API.put(`/api/settings/workspaces/${workspaceId}/info`, {
				name: payload,
			}),
		onSuccess(data, payload) {
			if (!data.success) {
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Error while updating workspace',
				);
				return;
			}

			Alerter.success('Workspace name successfully updated');

			queryClient.setQueryData<ApiResponse<CurrentUser> | undefined>(
				[QueryKeys.GET_CURRENT_USER],
				oldData => {
					if (oldData?.value?.workSpace) {
						return {
							...oldData,
							value: {
								...oldData.value,
								workSpace: {
									...oldData.value.workSpace,
									name: payload,
								},
							},
						};
					}
					return oldData;
				},
			);
			queryClient.setQueryData<
				ApiResponse<WorkspaceSelectItem[] | null> | undefined
			>([QueryKeys.GET_WORKSPACES], oldData => {
				if (oldData?.value?.length) {
					const updatedWorkspaces = oldData.value.map(workspace => {
						if (workspace.id !== workspaceId) {
							return workspace;
						}

						return {
							...workspace,
							name: payload,
						};
					});
					return {
						...oldData,
						value: updatedWorkspaces,
					};
				}
				return oldData;
			});
		},
		onError(error) {
			Alerter.error(
				error?.toString() || 'Error occurred while updating workspace',
			);
		},
	});
};
