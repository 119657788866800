import React from 'react';
import { Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';

interface EmptyItemsTextProps {
	text: string;
	wrapperProps?: FlexProps;
	textProps?: TextProps;
}

export const EmptyItemsText: React.FC<EmptyItemsTextProps> = ({
	text,
	wrapperProps,
	textProps,
}) => (
	<Flex
		borderColor="gray.20"
		borderRadius="8px"
		background="gray.10"
		borderWidth="1px"
		py={3}
		align="center"
		justify="center"
		{...wrapperProps}>
		<Text {...textProps}>{text}</Text>
	</Flex>
);
