import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalWrapper } from '../../components';
import { CreateWorkspace } from '../Onboarding/steps';
import { RootStore } from '../../redux/createStore';
import { setCreateWorkspaceModalOpen } from '../../redux/reducers/onboarding.reducer';

export const CreateWorkspaceModal: React.FC = () => {
	const dispatch = useDispatch();
	const { createWorkspaceModalOpen } = useSelector(
		(state: RootStore) => state.onboarding,
	);

	return (
		<ModalWrapper
			isOpen={createWorkspaceModalOpen}
			onClose={() => dispatch(setCreateWorkspaceModalOpen(false))}>
			<CreateWorkspace isIsolated={true} />
		</ModalWrapper>
	);
};
