import { useMutation } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { CreateActivityPayload } from '../types';
import { Alerter } from '../../../utils';
import { QueryKeys } from '../../../constants';

export const useCreateCardActivity = () => {
	return useMutation<ApiResponseEmpty, unknown, CreateActivityPayload>({
		mutationKey: [QueryKeys.CREATE_CARD_ACTIVITY],
		mutationFn: payload => {
			const { workspaceId, cardId, ...bodyPayload } = payload;

			return API.post(
				`/api/workspaces/${workspaceId}/cards/${cardId}/timelines/create`,
				bodyPayload,
			);
		},
		onSuccess: data => {
			if (!data.success) {
				return;
			}

			Alerter.success('Activity was successfully created!');
		},
	});
};
