import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseUserData } from '../../types';

type InitialState = {
	teamMembers: {
		telegramIds: number[];
		data: BaseUserData[];
		isLoaded: boolean;
	};
};

const initialState: InitialState = {
	teamMembers: {
		telegramIds: [],
		data: [],
		isLoaded: false,
	},
};

const chatStateReducer = createSlice({
	name: 'chatState',
	initialState,
	reducers: {
		setChatTeamMembersData: (
			state,
			action: PayloadAction<{
				telegramIds: number[];
				data: BaseUserData[];
				isLoaded: boolean;
			}>,
		) => {
			state.teamMembers = action.payload;
		},
		resetChatTeamMembersData: state => {
			state.teamMembers = initialState.teamMembers;
		},
	},
});

export const { setChatTeamMembersData, resetChatTeamMembersData } =
	chatStateReducer.actions;

export default chatStateReducer.reducer;
