import dayjs from 'dayjs';

export const generateYearsArray = (
	minYear: number,
	maxYear?: number,
): number[] => {
	const maxGeneratedYear = maxYear || dayjs().year();
	return Array.from(
		{ length: maxGeneratedYear - minYear + 1 },
		(_, index) => maxGeneratedYear - index,
	);
};
