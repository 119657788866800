import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
	showLoginFlow: boolean;
	telegramConnected: boolean;
	showTelegramLoginModal: boolean;
	userId: string | null;
	exchangingCodesLoading: boolean;
};

const initialState: InitialState = {
	showLoginFlow: false,
	telegramConnected: false,
	showTelegramLoginModal: false,
	userId: null,
	exchangingCodesLoading: false,
};

const loginFlowReducer = createSlice({
	name: 'login-flow',
	initialState,
	reducers: {
		setShowTelegramModal: (state, action: PayloadAction<boolean>) => {
			state.showTelegramLoginModal = action.payload;
		},
		setShowLoginFlow: (state, action: PayloadAction<boolean>) => {
			state.showLoginFlow = action.payload;
		},
		setTelegramConnected: (
			state,
			action: PayloadAction<{ isConnected: boolean; userId?: string }>,
		) => {
			state.telegramConnected = action.payload.isConnected;
			state.userId = action.payload?.userId || null;
		},
		setExchangingCodeLoading: (state, action: PayloadAction<boolean>) => {
			state.exchangingCodesLoading = action.payload;
		},
	},
});

export const {
	setShowTelegramModal,
	setShowLoginFlow,
	setTelegramConnected,
	setExchangingCodeLoading,
} = loginFlowReducer.actions;

export default loginFlowReducer.reducer;
