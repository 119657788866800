import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { Task } from '../../../../../components';
import { useGetCardTasks, useUpdateCardTaskStatus } from '../../../queries';
import {
	CardTaskStatusEnum,
	ApiResponse,
	CardDefaultStatusEnum,
} from '../../../../../types';
import { Alerter } from '../../../../../utils';
import { BoardCardItemResponse } from '../../../../Dashboard/types';
import { QueryKeys } from '../../../../../constants';

interface ITasksPreviewProps {
	cardId: number;
	workspaceId?: number;
}

export const TasksPreview: React.FC<ITasksPreviewProps> = ({
	cardId,
	workspaceId,
}) => {
	const { state } = useLocation();
	const { data } = useGetCardTasks(workspaceId, cardId);
	const { mutateAsync: updateTaskStatus } = useUpdateCardTaskStatus();
	const queryClient = useQueryClient();

	const { chatTelegramId } = state || {};

	const cardsData = queryClient.getQueryData<
		ApiResponse<{ cards: BoardCardItemResponse[] }>
	>([QueryKeys.GET_BOARD_CARDS, workspaceId]);

	const isCurrentCardArchived = useMemo(() => {
		const cardData = cardsData?.value?.cards?.find(
			card => card.cardId === cardId,
		);
		return cardData?.status?.typeId === CardDefaultStatusEnum.ARCHIVE;
	}, [cardsData, cardId]);

	const onUpdateTaskStatus = async (
		cardTaskId: number,
		status: CardTaskStatusEnum,
		isCardArchived: boolean,
		telegramUsersIds: number[],
		workspaceId?: number,
	) => {
		try {
			if (!workspaceId) {
				return Alerter.error('Workspace is missing');
			}

			await updateTaskStatus({
				bodyPayload: {
					workspaceId,
					status,
					cardTaskId,
					cardId,
					telegramUsersIds,
				},
				isCardArchived,
			});
		} catch (error) {
			console.log('onUpdateTaskStatus: ', error);
		}
	};

	return (
		<Box
			height={
				(data?.value?.yourTasks.length || 0) > 3
					? '150px'
					: `${(data?.value?.yourTasks.length || 0) * 50}px`
			}
			bg="white"
			flexShrink={0}
			zIndex={10}
			overflowY="auto"
			width="100%"
			borderBottomWidth="1px"
			borderBottomColor="gray.15"
			sx={{ scrollbarGutter: 'stable' }}>
			{data?.value?.yourTasks.map(task => (
				<Task
					key={task.id}
					onUpdateTaskStatus={(status, telegramUsersIds) =>
						onUpdateTaskStatus(
							task.id,
							status,
							isCurrentCardArchived,
							telegramUsersIds,
							workspaceId,
						)
					}
					chatTelegramId={chatTelegramId}
					{...task}
				/>
			))}
		</Box>
	);
};
