/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { Children } from 'react';
import {
	applyChangeToValue,
	countSuggestions,
	escapeRegex,
	findStartOfMentionInPlainText,
	getEndOfLastMention,
	getMentions,
	getPlainText,
	getSubstringIndex,
	makeMentionsMarkup,
	mapPlainTextIndex,
	readConfigFromChildren,
	spliceString,
	isNumber,
	keys,
	omit,
	getSuggestionHtmlId,
} from './utils';
import { Box, Center } from '@chakra-ui/react';
import TextareaAutosize from 'react-textarea-autosize';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import SuggestionsOverlay from './SuggestionsOverlay';

export const makeTriggerRegex = function (trigger, options = {}) {
	if (trigger instanceof RegExp) {
		return trigger;
	} else {
		const { allowSpaceInQuery } = options;
		const escapedTriggerChar = escapeRegex(trigger);

		// first capture group is the part to be replaced on completion
		// second capture group is for extracting the search query
		return new RegExp(
			`(?:^|\\s)(${escapedTriggerChar}([^${
				allowSpaceInQuery ? '' : '\\s'
			}${escapedTriggerChar}]*))$`,
		);
	}
};

const getDataProvider = function (data, ignoreAccents) {
	if (data instanceof Array) {
		// if data is an array, create a function to query that
		return function (query) {
			const results = [];
			for (let i = 0, l = data.length; i < l; ++i) {
				const display = data[i].name || data[i].id;
				if (getSubstringIndex(display, query, ignoreAccents) >= 0) {
					results.push(data[i]);
				}
			}
			return results;
		};
	} else {
		// expect data to be a query function
		return data;
	}
};

const KEY = { TAB: 9, RETURN: 13, ESC: 27, UP: 38, DOWN: 40 };

let isComposing = false;

const propTypes = {
	allowSpaceInQuery: PropTypes.bool,
	allowSuggestionsAboveCursor: PropTypes.bool,
	forceSuggestionsAboveCursor: PropTypes.bool,
	ignoreAccents: PropTypes.bool,
	a11ySuggestionsListLabel: PropTypes.string,

	value: PropTypes.string,
	onKeyDown: PropTypes.func,
	onSelect: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	suggestionsPortalHost:
		typeof Element === 'undefined'
			? PropTypes.any
			: PropTypes.PropTypes.instanceOf(Element),
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({
			current:
				typeof Element === 'undefined'
					? PropTypes.any
					: PropTypes.instanceOf(Element),
		}),
	]),

	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]).isRequired,
};

class MentionsInput extends React.Component {
	static propTypes = propTypes;

	static defaultProps = {
		ignoreAccents: false,
		allowSuggestionsAboveCursor: false,
		onKeyDown: () => null,
		onSelect: () => null,
		onBlur: () => null,
	};

	constructor(props) {
		super(props);
		this.suggestions = {};
		this.uuidSuggestionsOverlay = Math.random().toString(16).substring(2);

		this.handleCopy = this.handleCopy.bind(this);
		this.handleCut = this.handleCut.bind(this);
		this.handlePaste = this.handlePaste.bind(this);

		this.state = {
			focusIndex: 0,

			selectionStart: null,
			selectionEnd: null,

			suggestions: {},

			caretPosition: null,
			suggestionsPosition: {},

			setSelectionAfterHandlePaste: false,

			maxRows: 2,
			rows: 1,
			textareaLineHeight: 22,
		};
	}

	componentDidMount() {
		document.addEventListener('copy', this.handleCopy);
		document.addEventListener('cut', this.handleCut);
		document.addEventListener('paste', this.handlePaste);
		this.calculateMaxRows();
		window.addEventListener('resize', this.calculateMaxRows);
	}

	componentDidUpdate() {
		// maintain selection in case a mention is added/removed causing
		// the cursor to jump to the end
		if (this.state.setSelectionAfterMentionChange) {
			this.setState({ setSelectionAfterMentionChange: false });
			this.setSelection(
				this.state.selectionStart,
				this.state.selectionEnd,
			);
		}
		if (this.state.setSelectionAfterHandlePaste) {
			this.setState({ setSelectionAfterHandlePaste: false });
			this.setSelection(
				this.state.selectionStart,
				this.state.selectionEnd,
			);
		}
	}

	componentWillUnmount() {
		document.removeEventListener('copy', this.handleCopy);
		document.removeEventListener('cut', this.handleCut);
		document.removeEventListener('paste', this.handlePaste);
		window.removeEventListener('resize', this.calculateMaxRows);
	}

	calculateMaxRows = () => {
		const availableHeight = window.innerHeight - 525;
		const limitedHeight = Math.min(availableHeight, 264);
		const lineHeight = this.state.textareaLineHeight;
		const rows = Math.floor(limitedHeight / lineHeight);

		this.setState({ maxRows: rows > 2 ? rows : 2 });
	};

	handleHeightChange = height => {
		const lineHeight = this.state.textareaLineHeight;
		const calculatedRows = Math.floor(height / lineHeight);
		this.setState({ rows: calculatedRows });
	};

	render() {
		return (
			<Box width="100%" ref={this.setContainerElement}>
				{this.renderSuggestionsOverlay()}
				{this.renderControl()}
			</Box>
		);
	}

	setContainerElement = el => {
		this.containerElement = el;
	};

	getInputProps = () => {
		let { readOnly, disabled } = this.props;

		// pass all props that neither we, nor substyle, consume through to the input control
		let props = omit(
			this.props,
			['style', 'classNames', 'className'], // substyle props
			keys(propTypes),
		);

		return {
			...props,

			value: this.getPlainText(),
			onScroll: this.updateHighlighterScroll,

			...(!readOnly &&
				!disabled && {
					onChange: this.handleChange,
					onSelect: this.handleSelect,
					onKeyDown: this.handleKeyDown,
					onBlur: this.handleBlur,
					onCompositionStart: this.handleCompositionStart,
					onCompositionEnd: this.handleCompositionEnd,
				}),

			...(this.isOpened() && {
				role: 'combobox',
				'aria-controls': this.uuidSuggestionsOverlay,
				'aria-expanded': true,
				'aria-autocomplete': 'list',
				'aria-haspopup': 'listbox',
				'aria-activedescendant': getSuggestionHtmlId(
					this.uuidSuggestionsOverlay,
					this.state.focusIndex,
				),
			}),
		};
	};

	renderControl = () => {
		let inputProps = this.getInputProps();

		return (
			<Center
				width="100%"
				h="100%"
				pt={this.state.rows > 2 ? '12px' : '0px'}>
				{this.renderInput(inputProps)}
			</Center>
		);
	};

	renderInput = props => {
		return (
			<TextareaAutosize
				{...props}
				ref={this.setInputRef}
				autoFocus={true}
				style={{
					resize: 'none',
					width: '100%',
					fontSize: '15px',
					border: 'none',
					boxSizing: 'border-box',
					lineHeight: `${this.state.textareaLineHeight}px`,
					outline: 'none',
					scrollbarWidth: 'thin',
					...props.style,
				}}
				maxRows={this.state.maxRows}
				minRows={1}
				onHeightChange={this.handleHeightChange}
			/>
		);
	};

	setInputRef = el => {
		this.inputElement = el;
		const { inputRef } = this.props;
		if (typeof inputRef === 'function') {
			inputRef(el);
		} else if (inputRef) {
			inputRef.current = el;
		}
	};

	setSuggestionsElement = el => {
		this.suggestionsElement = el;
	};

	renderSuggestionsOverlay = () => {
		if (!isNumber(this.state.selectionStart)) {
			// do not show suggestions when the input does not have the focus
			return null;
		}

		const suggestionsNode = (
			<SuggestionsOverlay
				id={this.uuidSuggestionsOverlay}
				// position={position}
				// position="fixed"
				// left={left}
				// top={top}
				// right={right}
				currentInputRows={this.state.rows}
				focusIndex={this.state.focusIndex}
				scrollFocusedIntoView={this.state.scrollFocusedIntoView}
				containerRef={this.setSuggestionsElement}
				suggestions={this.state.suggestions}
				onSelect={this.addMention}
				onMouseDown={this.handleSuggestionsMouseDown}
				onMouseEnter={this.handleSuggestionsMouseEnter}
				isLoading={this.isLoading()}
				isOpened={this.isOpened()}
				ignoreAccents={this.props.ignoreAccents}
				a11ySuggestionsListLabel={this.props.a11ySuggestionsListLabel}>
				{this.props.children}
			</SuggestionsOverlay>
		);
		if (this.props.suggestionsPortalHost) {
			return ReactDOM.createPortal(
				suggestionsNode,
				this.props.suggestionsPortalHost,
			);
		} else {
			return suggestionsNode;
		}
	};
	// Returns the text to set as the value of the textarea with all markups removed
	getPlainText = () => {
		return getPlainText(
			this.props.value || '',
			readConfigFromChildren(this.props.children),
		);
	};

	executeOnChange = (event, ...args) => {
		if (this.props.onChange) {
			return this.props.onChange(event, ...args);
		}

		if (this.props.valueLink) {
			return this.props.valueLink.requestChange(
				event.target.value,
				...args,
			);
		}
	};

	handlePaste(event) {
		if (event.target !== this.inputElement) {
			return;
		}
		if (!this.supportsClipboardActions(event)) {
			return;
		}

		event.preventDefault();

		const { selectionStart, selectionEnd } = this.state;
		const { value, children } = this.props;

		const config = readConfigFromChildren(children);

		const markupStartIndex = mapPlainTextIndex(
			value,
			config,
			selectionStart,
			'START',
		);
		const markupEndIndex = mapPlainTextIndex(
			value,
			config,
			selectionEnd,
			'END',
		);

		const pastedMentions = event.clipboardData.getData(
			'text/react-mentions',
		);
		const pastedData = event.clipboardData.getData('text/plain');

		const newValue = spliceString(
			value,
			markupStartIndex,
			markupEndIndex,
			pastedMentions || pastedData,
		).replace(/\r/g, '');

		const newPlainTextValue = getPlainText(newValue, config);

		const eventMock = { target: { ...event.target, value: newValue } };

		this.executeOnChange(
			eventMock,
			newValue,
			newPlainTextValue,
			getMentions(newValue, config),
		);

		// Move the cursor position to the end of the pasted data
		const startOfMention = findStartOfMentionInPlainText(
			value,
			config,
			selectionStart,
		);
		const nextPos =
			(startOfMention || selectionStart) +
			getPlainText(pastedMentions || pastedData, config).length;
		this.setState({
			selectionStart: nextPos,
			selectionEnd: nextPos,
			setSelectionAfterHandlePaste: true,
		});
	}

	saveSelectionToClipboard(event) {
		// use the actual selectionStart & selectionEnd instead of the one stored
		// in state to ensure copy & paste also works on disabled inputs & textareas
		const selectionStart = this.inputElement.selectionStart;
		const selectionEnd = this.inputElement.selectionEnd;
		const { children, value } = this.props;

		const config = readConfigFromChildren(children);

		const markupStartIndex = mapPlainTextIndex(
			value,
			config,
			selectionStart,
			'START',
		);
		const markupEndIndex = mapPlainTextIndex(
			value,
			config,
			selectionEnd,
			'END',
		);

		event.clipboardData.setData(
			'text/plain',
			event.target.value.slice(selectionStart, selectionEnd),
		);
		event.clipboardData.setData(
			'text/react-mentions',
			value.slice(markupStartIndex, markupEndIndex),
		);
	}

	supportsClipboardActions(event) {
		return !!event.clipboardData;
	}

	handleCopy(event) {
		if (event.target !== this.inputElement) {
			return;
		}
		if (!this.supportsClipboardActions(event)) {
			return;
		}

		event.preventDefault();

		this.saveSelectionToClipboard(event);
	}

	handleCut(event) {
		if (event.target !== this.inputElement) {
			return;
		}
		if (!this.supportsClipboardActions(event)) {
			return;
		}

		event.preventDefault();

		this.saveSelectionToClipboard(event);

		const { selectionStart, selectionEnd } = this.state;
		const { children, value } = this.props;

		const config = readConfigFromChildren(children);

		const markupStartIndex = mapPlainTextIndex(
			value,
			config,
			selectionStart,
			'START',
		);
		const markupEndIndex = mapPlainTextIndex(
			value,
			config,
			selectionEnd,
			'END',
		);

		const newValue = [
			value.slice(0, markupStartIndex),
			value.slice(markupEndIndex),
		].join('');
		const newPlainTextValue = getPlainText(newValue, config);

		const eventMock = {
			target: { ...event.target, value: newPlainTextValue },
		};

		this.executeOnChange(
			eventMock,
			newValue,
			newPlainTextValue,
			getMentions(value, config),
		);
	}

	// Handle input element's change event
	handleChange = ev => {
		isComposing = false;

		const value = this.props.value || '';
		const config = readConfigFromChildren(this.props.children);

		let newPlainTextValue = ev.target.value;

		let selectionStartBefore = this.state.selectionStart;
		if (selectionStartBefore == null) {
			selectionStartBefore = ev.target.selectionStart;
		}

		let selectionEndBefore = this.state.selectionEnd;
		if (selectionEndBefore == null) {
			selectionEndBefore = ev.target.selectionEnd;
		}

		// Derive the new value to set by applying the local change in the textarea's plain text
		let newValue = applyChangeToValue(
			value,
			newPlainTextValue,
			{
				selectionStartBefore,
				selectionEndBefore,
				selectionEndAfter: ev.target.selectionEnd,
			},
			config,
		);

		// In case a mention is deleted, also adjust the new plain text value
		newPlainTextValue = getPlainText(newValue, config);

		// Save current selection after change to be able to restore caret position after rerendering
		let selectionStart = ev.target.selectionStart;
		let selectionEnd = ev.target.selectionEnd;
		let setSelectionAfterMentionChange = false;

		// Adjust selection range in case a mention will be deleted by the characters outside the
		// selection range that are automatically deleted
		let startOfMention = findStartOfMentionInPlainText(
			value,
			config,
			selectionStart,
		);

		if (
			startOfMention !== undefined &&
			this.state.selectionEnd > startOfMention
		) {
			// only if a deletion has taken place
			selectionStart =
				startOfMention +
				(ev.nativeEvent.data ? ev.nativeEvent.data.length : 0);
			selectionEnd = selectionStart;
			setSelectionAfterMentionChange = true;
		}

		this.setState({
			selectionStart,
			selectionEnd,
			setSelectionAfterMentionChange: setSelectionAfterMentionChange,
		});

		let mentions = getMentions(newValue, config);

		if (ev.nativeEvent.isComposing && selectionStart === selectionEnd) {
			this.updateMentionsQueries(this.inputElement.value, selectionStart);
		}

		// Propagate change
		// let handleChange = this.getOnChange(this.props) || emptyFunction;
		let eventMock = { target: { value: newValue } };
		// this.props.onChange.call(this, eventMock, newValue, newPlainTextValue, mentions);
		this.executeOnChange(eventMock, newValue, newPlainTextValue, mentions);
	};

	// Handle input element's select event
	handleSelect = ev => {
		// keep track of selection range / caret position
		this.setState({
			selectionStart: ev.target.selectionStart,
			selectionEnd: ev.target.selectionEnd,
		});

		// do nothing while a IME composition session is active
		if (isComposing) {
			return;
		}

		// refresh suggestions queries
		const el = this.inputElement;
		if (ev.target.selectionStart === ev.target.selectionEnd) {
			this.updateMentionsQueries(el.value, ev.target.selectionStart);
		} else {
			this.clearSuggestions();
		}

		// sync highlighters scroll position
		// this.updateHighlighterScroll();
		this.props.onSelect(ev);
	};

	handleKeyDown = ev => {
		// do not intercept key events if the suggestions overlay is not shown
		const suggestionsCount = countSuggestions(this.state.suggestions);

		if (suggestionsCount === 0 || !this.suggestionsElement) {
			this.props.onKeyDown(ev);

			return;
		}

		if (Object.values(KEY).indexOf(ev.keyCode) >= 0) {
			ev.preventDefault();
			ev.stopPropagation();
		}

		switch (ev.keyCode) {
			case KEY.ESC: {
				this.clearSuggestions();
				return;
			}
			case KEY.DOWN: {
				this.shiftFocus(+1);
				return;
			}
			case KEY.UP: {
				this.shiftFocus(-1);
				return;
			}
			case KEY.RETURN: {
				this.selectFocused();
				return;
			}
			case KEY.TAB: {
				this.selectFocused();
				return;
			}
			default: {
				return;
			}
		}
	};

	shiftFocus = delta => {
		const suggestionsCount = countSuggestions(this.state.suggestions);

		this.setState({
			focusIndex:
				(suggestionsCount + this.state.focusIndex + delta) %
				suggestionsCount,
			scrollFocusedIntoView: true,
		});
	};

	selectFocused = () => {
		const { suggestions, focusIndex } = this.state;

		const { result, queryInfo } = Object.values(suggestions).reduce(
			(acc, { results, queryInfo }) => [
				...acc,
				...results.map(result => ({ result, queryInfo })),
			],
			[],
		)[focusIndex];

		this.addMention(result, queryInfo);

		this.setState({
			focusIndex: 0,
		});
	};

	handleBlur = ev => {
		const clickedSuggestion = this._suggestionsMouseDown;
		this._suggestionsMouseDown = false;

		// only reset selection if the mousedown happened on an element
		// other than the suggestions overlay
		if (!clickedSuggestion) {
			this.setState({
				selectionStart: null,
				selectionEnd: null,
			});
		}

		// window.setTimeout(() => {
		// 	this.updateHighlighterScroll();
		// }, 1);

		this.props.onBlur(ev, clickedSuggestion);
	};

	handleSuggestionsMouseDown = () => {
		this._suggestionsMouseDown = true;
	};

	handleSuggestionsMouseEnter = focusIndex => {
		this.setState({
			focusIndex,
			scrollFocusedIntoView: false,
		});
	};

	// updateHighlighterScroll = () => {
	// 	const input = this.inputElement;
	// 	const highlighter = this.highlighterElement;
	// 	if (!input || !highlighter) {
	// 		// since the invocation of this function is deferred,
	// 		// the whole component may have been unmounted in the meanwhile
	// 		return;
	// 	}
	// 	highlighter.scrollLeft = input.scrollLeft;
	// 	highlighter.scrollTop = input.scrollTop;
	// 	highlighter.height = input.height;
	// };

	handleCompositionStart = () => {
		isComposing = true;
	};

	handleCompositionEnd = () => {
		isComposing = false;
	};

	setSelection = (selectionStart, selectionEnd) => {
		if (selectionStart === null || selectionEnd === null) {
			return;
		}

		const el = this.inputElement;
		if (el.setSelectionRange) {
			el.setSelectionRange(selectionStart, selectionEnd);
		} else if (el.createTextRange) {
			const range = el.createTextRange();
			range.collapse(true);
			range.moveEnd('character', selectionEnd);
			range.moveStart('character', selectionStart);
			range.select();
		}
	};

	updateMentionsQueries = (plainTextValue, caretPosition) => {
		// Invalidate previous queries. Async results for previous queries will be neglected.
		this._queryId++;
		this.suggestions = {};
		this.setState({
			suggestions: {},
		});

		const value = this.props.value || '';
		const { children } = this.props;
		const config = readConfigFromChildren(children);

		const positionInValue = mapPlainTextIndex(
			value,
			config,
			caretPosition,
			'NULL',
		);

		// If caret is inside of mention, do not query
		if (positionInValue === null) {
			return;
		}

		// Extract substring in between the end of the previous mention and the caret
		const substringStartIndex = getEndOfLastMention(
			value.substring(0, positionInValue),
			config,
		);
		const substring = plainTextValue.substring(
			substringStartIndex,
			caretPosition,
		);

		// Check if suggestions have to be shown:
		// Match the trigger patterns of all Mention children on the extracted substring
		React.Children.forEach(children, (child, childIndex) => {
			if (!child) {
				return;
			}

			const regex = makeTriggerRegex(child.props.trigger, this.props);
			const match = substring.match(regex);
			if (match) {
				const querySequenceStart =
					substringStartIndex +
					substring.indexOf(match[1], match.index);
				this.queryData(
					match[2],
					childIndex,
					querySequenceStart,
					querySequenceStart + match[1].length,
					plainTextValue,
				);
			}
		});
	};

	clearSuggestions = () => {
		// Invalidate previous queries. Async results for previous queries will be neglected.
		this._queryId++;
		this.suggestions = {};
		this.setState({
			suggestions: {},
			focusIndex: 0,
		});
	};

	queryData = (
		query,
		childIndex,
		querySequenceStart,
		querySequenceEnd,
		plainTextValue,
	) => {
		const { children, ignoreAccents } = this.props;
		const mentionChild = Children.toArray(children)[childIndex];
		const provideData = getDataProvider(
			mentionChild.props.data,
			ignoreAccents,
		);
		const syncResult = provideData(
			query,
			this.updateSuggestions.bind(
				null,
				this._queryId,
				childIndex,
				query,
				querySequenceStart,
				querySequenceEnd,
				plainTextValue,
			),
		);
		if (syncResult instanceof Array) {
			this.updateSuggestions(
				this._queryId,
				childIndex,
				query,
				querySequenceStart,
				querySequenceEnd,
				plainTextValue,
				syncResult,
			);
		}
	};

	updateSuggestions = (
		queryId,
		childIndex,
		query,
		querySequenceStart,
		querySequenceEnd,
		plainTextValue,
		results,
	) => {
		// neglect async results from previous queries
		if (queryId !== this._queryId) {
			return;
		}

		// save in property so that multiple sync state updates from different mentions sources
		// won't overwrite each other
		this.suggestions = {
			...this.suggestions,
			[childIndex]: {
				queryInfo: {
					childIndex,
					query,
					querySequenceStart,
					querySequenceEnd,
					plainTextValue,
				},
				results,
			},
		};

		const { focusIndex } = this.state;
		const suggestionsCount = countSuggestions(this.suggestions);
		this.setState({
			suggestions: this.suggestions,
			focusIndex:
				focusIndex >= suggestionsCount
					? Math.max(suggestionsCount - 1, 0)
					: focusIndex,
		});
	};

	addMention = (
		{ id, name, username },
		{ childIndex, querySequenceStart, querySequenceEnd, plainTextValue },
	) => {
		// Insert mention in the marked up value at the correct position
		const value = this.props.value || '';
		const config = readConfigFromChildren(this.props.children);
		const mentionsChild = Children.toArray(this.props.children)[childIndex];
		const { markup, displayTransform, appendSpaceOnAdd, onAdd } =
			mentionsChild.props;

		const start = mapPlainTextIndex(
			value,
			config,
			querySequenceStart,
			'START',
		);
		const end = start + querySequenceEnd - querySequenceStart;

		let insert = makeMentionsMarkup(markup, id, name, username);

		if (appendSpaceOnAdd) {
			insert += ' ';
		}
		const newValue = spliceString(value, start, end, insert);

		// Refocus input and set caret position to end of mention
		this.inputElement.focus();

		let displayValue = displayTransform(id, name, username);
		if (appendSpaceOnAdd) {
			displayValue += ' ';
		}
		const newCaretPosition = querySequenceStart + displayValue.length;
		this.setState({
			selectionStart: newCaretPosition,
			selectionEnd: newCaretPosition,
			setSelectionAfterMentionChange: true,
		});

		// Propagate change
		const eventMock = { target: { value: newValue } };
		const mentions = getMentions(newValue, config);
		const newPlainTextValue = spliceString(
			plainTextValue,
			querySequenceStart,
			querySequenceEnd,
			displayValue,
		);

		this.executeOnChange(eventMock, newValue, newPlainTextValue, mentions);

		if (onAdd) {
			onAdd(id, name, start, end);
		}

		// Make sure the suggestions overlay is closed
		this.clearSuggestions();
	};

	isLoading = () => {
		let isLoading = false;
		React.Children.forEach(this.props.children, function (child) {
			isLoading = isLoading || (child && child.props.isLoading);
		});
		return isLoading;
	};

	isOpened = () => {
		return (
			isNumber(this.state.selectionStart) &&
			(countSuggestions(this.state.suggestions) !== 0 || this.isLoading())
		);
	};

	_queryId = 0;
}

export default MentionsInput;
