import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { ModalWrapper } from '../../components';
import { setInviteMembersModalOpen } from '../../redux/reducers/onboarding.reducer';
import { InviteTeam } from '../Onboarding/steps';

export const InviteMembersModal: React.FC = () => {
	const dispatch = useDispatch();
	const { inviteMembersModalOpen } = useSelector(
		(state: RootStore) => state.onboarding,
	);

	return (
		<ModalWrapper
			isOpen={inviteMembersModalOpen}
			// shouldClose={false}
			onClose={() => dispatch(setInviteMembersModalOpen(false))}>
			<InviteTeam isIsolated={true} />
		</ModalWrapper>
	);
};
