import { useGetWorkspaceId } from '../../../hooks';
import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';

export const useGetActiveStages = () => {
	const workspaceId = useGetWorkspaceId();

	return useQuery<
		ApiResponse<{
			stages: {
				id: number;
				name: string;
				position: number;
			}[];
		}>
	>({
		queryKey: [QueryKeys.GET_ACTIVE_STAGES, workspaceId],
		queryFn: () =>
			API.get(`/api/workspaces/${workspaceId}/stages/active`, {}),
		refetchOnWindowFocus: false,
	});
};
