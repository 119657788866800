import React from 'react';
import { DecoyBackground } from '../../components';
import { SelectWorkspaceModal } from '../../modals';

const SelectWorkspace: React.FC = () => {
	return (
		<div>
			<SelectWorkspaceModal />
			<DecoyBackground />
		</div>
	);
};

export default SelectWorkspace;
