import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../constants';
import { ApiResponse, CardTaskPriority } from '../../../types';
import { CardTaskItem, ChatTasksListResponse } from '../types';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { useSupabaseWithAuth } from '../../../state/auth/context';

export const useGetCardTasks = (workspaceId?: number, cardId?: number) => {
	const { id: userId } = useSelector((state: RootStore) => state.auth.user);
	const supabase = useSupabaseWithAuth();

	return useQuery<ApiResponse<ChatTasksListResponse | null> | null>({
		queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
		queryFn: async () => {
			if (!workspaceId || !cardId) {
				return null;
			}
			const { data, error, status } = await supabase
				.from('card_tasks')
				.select(
					`
					id,
					task_description,
					status,
					priority_id,
					user_id,
					created_by_id,
					created_at,
					changed_at,
					notifications ( 
						scheduled_at 
					),
					users (
						id,
						first_name,
						last_name,
						telegram_user_id,
						avatar_id (storage_path),
						thumbnail_id (storage_path)
					),
					task_priorities (
						id,
						name
					),
					workspace_cards (
						id,
						card_name,
						cards (
							id,
							telegram_chat_id
						),
						is_archived
					)
				`,
				)
				.eq('workspace_card_id.card_id', cardId)
				.order('created_at', { ascending: false });

			if (error) {
				return {
					success: false,
					status: status,
					errors: [{ key: error.code, message: error.message }],
					value: null,
					messages: [],
				};
			}

			const mapToCardTaskItem = (
				task: (typeof data)[number],
			): CardTaskItem => ({
				id: task.id,
				taskDescription: task.task_description,
				priority: task.task_priorities as CardTaskPriority | null,
				createdAt: task.created_at,
				status: task.status,
				firstName: task.users?.first_name ?? undefined,
				lastName: task.users?.last_name ?? null,
				telegramUserId: task.users?.telegram_user_id ?? null,
				isArchived: task.workspace_cards?.is_archived ?? null,
				userId: task.user_id,
				authorUserId: task.created_by_id ?? undefined,
				remindOn: task.notifications?.scheduled_at ?? null,
				workspaceCard: task.workspace_cards?.cards
					? {
							id: task.workspace_cards?.cards!!.id,
							name: task.workspace_cards!!.card_name,
							telegramChatId:
								task.workspace_cards!!.cards!!.telegram_chat_id,
						}
					: null,
			});

			const yourTasks = data
				.filter(task => task.user_id === userId)
				.map(mapToCardTaskItem);
			const otherTasks = data
				.filter(task => task.user_id !== userId)
				.map(mapToCardTaskItem);

			return {
				success: true,
				status: status,
				value: {
					yourTasks,
					otherTasks,
					yourTasksCount: yourTasks.length,
					otherTasksCount: otherTasks.length,
				},
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && !!cardId,
	});
};
