import { useMutation } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import { ApiResponse, CreateLabelPayload } from '../../../../../types';
import { Alerter } from '../../../../../utils';
import { QueryKeys } from '../../../../../constants';

export const useCreateLabel = () => {
	return useMutation<ApiResponse<number>, unknown, CreateLabelPayload>({
		mutationKey: [QueryKeys.CREATE_LABEL],
		mutationFn: payload => {
			const { workspaceId, ...bodyPayload } = payload;

			return API.post(
				`/api/workspaces/${workspaceId}/labels`,
				bodyPayload,
			);
		},
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}

			Alerter.success(
				data.messages?.[0]?.message ||
					'Label was successfully created!',
			);
		},
	});
};
