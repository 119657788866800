import React from 'react';
import { Avatar, Box, HStack, Skeleton, Text } from '@chakra-ui/react';
import { EventsIndicator } from '../../EventsIndicator/EventsIndicator';
import { Icon } from '../../Icon/Icon';
import { getActivityIconByTypeId, getAvatarBg } from '../../../utils';
import { InternalChatMessagePreview } from '../../../pages/Dashboard/types';
import { colors } from '../../../theme/colors';
import { IndicatorTypeEnum } from '../../../types';

interface CardFooterProps extends InternalChatMessagePreview {
	isLoaded?: boolean;
	avatar?: string;
	currentUserTagged?: boolean;
}

export const CardFooter: React.FC<CardFooterProps> = ({
	unreadCount,
	text,
	senderName,
	activityTypeId,
	isLoaded,
	avatar,
	telegramUserId,
	currentUserTagged,
	// senderId,
	// workspaceCardActivityId,
	// senderId,
}) => {
	const menuIconName = activityTypeId
		? getActivityIconByTypeId(activityTypeId)
		: null;

	return (
		<Box p="6px 8px" maxW="100%">
			<HStack spacing={2} align="center" position="relative">
				{menuIconName ? (
					<Skeleton
						w="100%"
						startColor="gray.40"
						endColor="gray.20"
						isLoaded={isLoaded}>
						<HStack spacing={2} align="center">
							<Icon
								name={menuIconName}
								color={colors.gray[40]}
								width="16px"
								height="16px"
							/>
							<Text color="gray.40" isTruncated={true}>
								{text}
							</Text>
						</HStack>
					</Skeleton>
				) : (
					<Skeleton
						w="100%"
						startColor="gray.40"
						endColor="gray.20"
						isLoaded={isLoaded}>
						<HStack spacing={2} align="center">
							<Box
								w="14px"
								minW="14px"
								h="14px"
								borderRadius="2px"
								overflow="hidden">
								<Avatar
									src={avatar || ''}
									w="100%"
									h="100%"
									name={senderName || ''}
									borderRadius="50%"
									bg={getAvatarBg(telegramUserId)}
									sx={{
										'> div': {
											fontSize: '8px',
										},
									}}
								/>
							</Box>
							<Text color="gray.40" isTruncated={true} pr="20px">
								{text}
							</Text>
						</HStack>
					</Skeleton>
				)}

				{isLoaded ? (
					<EventsIndicator
						count={unreadCount}
						type={
							currentUserTagged
								? IndicatorTypeEnum.MENTION
								: IndicatorTypeEnum.COUNT
						}
						wrapperProps={{
							position: 'absolute',
							right: 0,
							bg: 'transparentLight.10',
							p: currentUserTagged ? '2px' : '1px 3px',
							minW: currentUserTagged ? '13px' : '14px',
						}}
						textProps={{
							color: 'secondary',
						}}
					/>
				) : null}
			</HStack>
		</Box>
	);
};
