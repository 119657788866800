/* eslint-disable indent */

import React, { useCallback } from 'react';
import { ApiChatType, TgUserData } from '../types';
import { useTelegram } from '../services';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateTelegramChatState,
	updateTelegramUserState,
} from '../redux/reducers/telegram-state.reducer';
import { checkForGroupChat } from '../utils';
import { RootStore } from '../redux/createStore';
import { fetchTgFullChat, fetchTgUser } from '../services/telegram/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { sixHoursInMilliseconds } from '../constants';

type IPayload = {
	chatId?: number;
	accessHash?: string;
	type?: ApiChatType;
	title: string;
	isEnabled: boolean;
	msgSenderId?: number;
};

export const useGetFullChatInfo = ({
	chatId,
	accessHash,
	title,
	type,
	msgSenderId,
	isEnabled,
}: IPayload) => {
	const queryClient = useQueryClient();
	const tg = useTelegram();
	const dispatch = useDispatch();

	const isSynced = useSelector(
		(state: RootStore) => state.syncState.isSynced,
	);

	const filterUniqueById = (arr: TgUserData[]) => {
		const seenIds = new Set<number>();
		return arr.filter(el => {
			if (seenIds.has(+el.id)) {
				return false;
			}
			seenIds.add(+el.id);
			return true;
		});
	};
	const isGroupChat = checkForGroupChat((chatId || 0).toString());

	const updateUserState = useCallback(
		(id: number, userData: TgUserData) => {
			dispatch(updateTelegramUserState({ id, data: userData }));
		},
		[dispatch],
	);

	const fetchGroupChatInfo = useCallback(async () => {
		if (!type || !tg || !chatId) {
			return null;
		}

		const fullChatInfo = await fetchTgFullChat({
			accessHash,
			chatId,
			queryClient,
			tg,
			type,
			title,
		});

		const chatMembers: TgUserData[] =
			fullChatInfo?.users?.map(user => {
				const userData = {
					id: +user.id,
					username: user.usernames?.[0]?.username || null,
				};
				updateUserState(+user.id, userData);
				return userData;
			}) || [];

		const chatAvatar =
			fullChatInfo?.fullInfo?.profilePhoto?.thumbnail?.dataUri || '';
		updateTelegramChatState({
			id: +chatId,
			data: {
				chatMembers: filterUniqueById(chatMembers),
				isChatFullyLoaded: true,
			},
		});
		return chatAvatar;
	}, [accessHash, chatId, queryClient, tg, title, type, updateUserState]);

	const fetchPrivateChatInfo = useCallback(async () => {
		if (!tg || !chatId) {
			return null;
		}

		const privateChatUserInfo = await fetchTgUser(queryClient, tg, chatId);

		const userData = privateChatUserInfo?.userShortInfo
			? {
					id: chatId,
					username:
						privateChatUserInfo.userShortInfo.usernames?.[0]
							?.username || null,
				}
			: null;

		if (userData) {
			updateUserState(chatId, userData);
		}

		const chatAvatar =
			privateChatUserInfo?.userFullInfo?.profilePhoto?.thumbnail
				?.dataUri ||
			privateChatUserInfo?.userFullInfo?.fallbackPhoto?.thumbnail
				?.dataUri ||
			'';
		updateTelegramChatState({
			id: +chatId,
			data: {
				chatMembers: userData ? [userData] : [],
				isChatFullyLoaded: true,
			},
		});
		return chatAvatar;
	}, [chatId, queryClient, tg, updateUserState]);

	const { data: chatAvatar } = useQuery({
		queryKey: ['tg-api-card-full-chat-info', chatId],
		queryFn: async () => {
			if (!tg || !chatId) {
				return null;
			}

			if (isGroupChat && type) {
				return fetchGroupChatInfo();
			}
			if (!isGroupChat && msgSenderId !== chatId) {
				return fetchPrivateChatInfo();
			}

			return null;
		},
		enabled: isEnabled && !!tg && !!chatId && !!isSynced && !!type,
		staleTime: sixHoursInMilliseconds,
		refetchOnWindowFocus: false,
	});

	return chatAvatar;
};
