import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
	baseStyle: {
		color: 'primary',
		fontSize: '13px',
		lineHeight: '19.5px',
		fontWeight: 400,
	},
	variants: {
		bodyLarge: {
			fontSize: '15px',
			lineHeight: '22px',
		},
		bodyMedium: {
			fontSize: '12px',
			lineHeight: '18px',
		},
		bodySmall: {
			fontSize: '11px',
			lineHeight: '16.5px',
		},
	},
};
