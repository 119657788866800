import React, { useCallback, useMemo } from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	chakra,
	useDisclosure,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Template, TemplatePopup } from './components';
import { useGetTemplates } from './queries';
import { useTelegram } from '../../../../services';
import { Loader, EmptyItemsText, Icon } from '../../../../components';
import { parseHtmlAsFormattedText } from '../../../../utils';
import { useGetBoardCards } from '../../../Dashboard/queries';
import { CardDefaultStatusEnum } from '../../../../types';
import { colors } from '../../../../theme/colors';

interface TemplatesProps {
	cardId: number;
	isUserHasAccess?: boolean;
}

export const Templates: React.FC<TemplatesProps> = ({
	cardId,
	isUserHasAccess,
}) => {
	const { data, isLoading } = useGetTemplates();
	const { data: cardsData } = useGetBoardCards();
	const {
		onToggle: onToggleTemplatePopup,
		isOpen: isTemplatePopupOpen,
		onClose: onCloseTemplatePopup,
	} = useDisclosure();
	const { state } = useLocation();
	const tg = useTelegram();

	const isCardArchived = useMemo(
		() =>
			cardsData?.value?.cards?.find(elem => elem.cardId === cardId)
				?.status.typeId === CardDefaultStatusEnum.ARCHIVE,
		[cardsData, cardId],
	);

	const onContentClick = useCallback(
		(content: string) => {
			(async () => {
				if (state?.chatTelegramId) {
					const { text, entities } =
						parseHtmlAsFormattedText(content);
					await tg?.actions.proxy.clearDraft({
						chatId: state.chatTelegramId.toString(),
						threadId: -1,
					});
					await tg?.actions.proxy.openChatWithDraft({
						chatId: state.chatTelegramId.toString(),
						text: {
							text,
							entities,
						},
						threadId: -1,
					});
				}
			})();
		},
		[tg, state],
	);

	if (isLoading) {
		return <Loader centerHeight="calc(100vh - 105px)" />;
	}

	return (
		<Box p="24px">
			<Flex alignItems="center" justify="space-between" mb="16px">
				<Heading color="primary" fontSize="16px" fontWeight="600">
					Your templates
					<chakra.span color="gray.35" ml={2}>
						{data?.value?.yourTemplateCount || 0}
					</chakra.span>
				</Heading>
				<TemplatePopup
					isOpen={isTemplatePopupOpen}
					onToggle={onToggleTemplatePopup}
					onClose={onCloseTemplatePopup}>
					<Button
						variant="outline"
						height="24px"
						shadow="none"
						px="8px"
						py="3px"
						borderRadius="4px"
						color="secondary"
						iconSpacing={1}
						leftIcon={
							<Icon
								name="plus"
								width="14"
								height="14"
								color={colors.secondary}
							/>
						}
						isActive={isTemplatePopupOpen}>
						Add template
					</Button>
				</TemplatePopup>
			</Flex>
			{data?.value?.yourTemplate?.length ? (
				<Box
					borderColor="gray.20"
					borderRadius="8px"
					background="gray.10"
					borderWidth="1px"
					maxH="calc(100vh - 200px)"
					overflowY="auto">
					{data?.value?.yourTemplate?.map(elem => (
						<Template
							key={elem.id}
							{...elem}
							onContentClick={() => {
								if (isCardArchived || !isUserHasAccess) {
									return;
								}
								onContentClick(elem.content);
							}}
						/>
					))}
				</Box>
			) : (
				<EmptyItemsText text="There are no templates at the moment" />
			)}
		</Box>
	);
};
