import React from 'react';
import { DecoyBackground } from '../../components';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

const Onboarding: React.FC = () => {
	const { isOpen } = useSelector((state: RootStore) => state.onboarding);

	if (!isOpen) {
		return <div></div>;
	}

	return <DecoyBackground />;
};

export default Onboarding;
