import { useMutation } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { QueryKeys } from '../../../../../constants';
import { MessageTaggedUsers } from '../../../types';

export const useSendMessage = (workspaceId?: number, cardId?: number) => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ text: string; taggedUsers: MessageTaggedUsers[] }
	>({
		mutationKey: [QueryKeys.SEND_MESSAGE],
		mutationFn: payload =>
			API.post(
				`/api/workspaces/${workspaceId}/cards/${cardId}/chat/send`,
				payload,
			),
	});
};
