import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalWrapper } from '../../components';
import { ChatsSync } from '../Onboarding/steps/ChatsSync';
import { RootStore } from '../../redux/createStore';
import { setSyncSettingsModalOpen } from '../../redux/reducers/onboarding.reducer';
import { queryClient, QueryKeys } from '../../constants';

export const SyncSettingsModal: React.FC = () => {
	const dispatch = useDispatch();
	const { syncSettingsModalOpen } = useSelector(
		(state: RootStore) => state.onboarding,
	);
	const { workSpace } = useSelector((state: RootStore) => state.auth.user);
	return (
		<ModalWrapper
			isOpen={syncSettingsModalOpen}
			onClose={() => dispatch(setSyncSettingsModalOpen(false))}>
			<ChatsSync
				includeSkip={false}
				onFinish={() => {
					dispatch(setSyncSettingsModalOpen(false));
					if (!workSpace?.id) {
						return;
					}
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_TG_FOLDERS, workSpace.id],
					});
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_BOARD_CARDS, workSpace.id],
					});
				}}
			/>
		</ModalWrapper>
	);
};
