import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { CheckIcon } from '@chakra-ui/icons';
import styles from './RadixCheckbox.module.css';

export const RadixCheckbox: React.FC<Checkbox.CheckboxProps> = props => {
	return (
		<Checkbox.Root className={styles.CheckboxRoot} {...props}>
			<Checkbox.Indicator className={styles.CheckboxIndicator}>
				<CheckIcon boxSize="10px" color="white" />
			</Checkbox.Indicator>
		</Checkbox.Root>
	);
};
