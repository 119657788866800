import { theme as base, ComponentStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
	baseStyle: {
		fontFamily: `'Inter', ${base.fonts.body}`,
		color: 'primary',
		fontWeight: 600,
	},
	variants: {
		h1: {
			fontSize: '24px',
			lineHeight: '28px',
		},
		h2: {
			fontSize: '20px',
			lineHeight: '24px',
		},
	},
};
