import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import API from '../../../api/api.base';
import {
	ApiResponse,
	UserInvitePayload,
	OnboardingSteps,
} from '../../../types';
import {
	setOnboardingActiveStep,
	setInviteMembersModalOpen,
} from '../../../redux/reducers/onboarding.reducer';
import { Alerter } from '../../../utils';
import { QueryKeys, queryClient } from '../../../constants';

interface IPayload {
	isIsolated: boolean;
	isDisabledRefetch?: boolean;
}

export const useInviteMembersToWorkspace = ({
	isIsolated,
	isDisabledRefetch = false,
}: IPayload) => {
	const dispatch = useDispatch();

	return useMutation<
		ApiResponse<{
			invitedUsersIds: number[];
			notInvitedUsers: string[];
		}> | null,
		unknown,
		{ workspaceId: number; payload: UserInvitePayload[] }
	>({
		mutationKey: [QueryKeys.INVITE_MEMBERS_TO_WORKSPACE],
		mutationFn: ({ workspaceId, payload }) => {
			if (!workspaceId) {
				throw new Error('Workspace ID is missing');
			}
			return API.post(`/api/workspaces/${workspaceId}/invite`, {
				telegramUsers: payload,
			});
		},
		onSettled(data, _, variables) {
			if (!data?.success) {
				console.error(`Error in useInviteMembersToWorkspace: ${data}`);
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Not possible to add these users, or format was incorrect',
				);
				return;
			}
			if (data?.value?.invitedUsersIds.length) {
				const isMultiple = data.value.invitedUsersIds.length > 1;
				Alerter.success(
					data?.messages?.[0]?.message ||
						`User${isMultiple ? 's' : ''} ${isMultiple ? 'were' : 'was'} successfully invited`,
				);
				if (!isDisabledRefetch) {
					queryClient.invalidateQueries({
						queryKey: [
							QueryKeys.GET_WORKSPACE_MEMBERS,
							variables.workspaceId,
						],
					});
					queryClient.invalidateQueries({
						queryKey: [
							QueryKeys.GET_WORKSPACE_TELEGRAM_IDS,
							variables.workspaceId,
						],
					});
				}
			}

			if (data?.value?.notInvitedUsers?.length) {
				const failedUserNames =
					data?.value?.notInvitedUsers?.join(', ');
				const isMultiple = data.value.notInvitedUsers.length > 1;
				Alerter.warning(
					data?.messages?.[0]?.message ||
						`User${isMultiple ? 's' : ''} ${failedUserNames} ${isMultiple ? 'are' : 'is'} already a part of your workspace`,
				);
			}

			if (!isIsolated) {
				dispatch(setOnboardingActiveStep(OnboardingSteps.SYNC_CHATS));
				return;
			}

			dispatch(setInviteMembersModalOpen(false));
		},
	});
};
