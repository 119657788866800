import { colors } from './colors';

export const shadows = {
	light: `0px 2px 4px 0px ${colors.transparentLight[5]}, 0px 0px 4px 0px ${colors.transparentLight[10]}`,
	underline: `0px 1px 0px 0px ${colors.transparentLight[40]}`,
	strong: {
		dark: `2px 4px 16px 0px ${colors.transparentLight[20]}, 0px 2px 4px 0px ${colors.transparentLight[10]}`,
		light: `2px 4px 16px 0px ${colors.transparentLight[15]}, 0px 2px 4px 0px ${colors.transparentLight[5]}`,
	},
	superHeavy: {
		dark: `2px 4px 16px 0px ${colors.transparentLight[15]}, 0px 2px 4px 0px ${colors.transparentLight[5]}`,
		light: `0px 0px 8px 0px ${colors.transparentLight[20]}, 0px 8px 64px -16px ${colors.transparentLight[60]}, 0px 24px 56px -16px ${colors.transparentLight[10]}`,
	},
	blue: {
		light: `0px 0px 0px 3px ${colors.lightBlue[10]}`,
	},
};
