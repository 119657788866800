import React from 'react';
import {
	Avatar,
	AvatarProps,
	Box,
	BoxProps,
	HStack,
	Image,
	StackProps,
	Text,
	TextProps,
} from '@chakra-ui/react';

interface LabelWithImageProps {
	label: string;
	imageLink?: string | null;
	includeAvatar?: boolean;
	includeImage?: boolean;
	wrapperProps?: StackProps;
	imageWrapperProps?: BoxProps;
	labelProps?: TextProps;
	avatarProps?: AvatarProps;
}

export const LabelWithImage: React.FC<LabelWithImageProps> = ({
	label,
	imageLink,
	includeAvatar,
	includeImage,
	wrapperProps,
	imageWrapperProps,
	labelProps,
	avatarProps,
}) => {
	return (
		<HStack
			spacing={1}
			bg="transparentLight.5"
			borderRadius="4px"
			px="4px"
			align="center"
			isTruncated={true}
			{...wrapperProps}>
			<Box
				width="14px"
				minW="14px"
				height="14px"
				borderRadius="50%"
				bg="blue.40"
				{...imageWrapperProps}>
				{includeAvatar ? (
					<Avatar
						width="100%"
						height="100%"
						name={label}
						color="white"
						src={imageLink || ''}
						sx={{
							div: {
								fontSize: '8px',
							},
						}}
						{...avatarProps}
					/>
				) : null}
				{includeImage && imageLink ? (
					<Image
						src={imageLink}
						alt=""
						w="full"
						h="full"
						objectFit="contain"
					/>
				) : null}
			</Box>
			<Text isTruncated={true} lineHeight="20px" {...labelProps}>
				{label}
			</Text>
		</HStack>
	);
};
