import React, { useMemo } from 'react';
import {
	Box,
	GridItem,
	GridItemProps,
	HStack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { CustomTag } from '..';
import { DecoyBoardCardItemResponse } from '../../pages/Dashboard/types';
import { getTaskStatusByType } from '../../utils';
import { CardStatusItem } from '../../types';
import { DecoyTaskCard } from './DecoyTaskCard';

interface DecoyKanbanBoardColumnProps {
	tasks: DecoyBoardCardItemResponse[];
	gridProps?: GridItemProps;
	columnStatus: CardStatusItem;
}

export const DecoyKanbanBoardColumn: React.FC<DecoyKanbanBoardColumnProps> = ({
	tasks,
	gridProps,
	columnStatus,
}) => {
	const {
		name: statusName,
		typeId: statusSystemDefaultId,
		position,
	} = columnStatus;

	const dataByColumnType = useMemo(
		() => getTaskStatusByType({ position, typeId: statusSystemDefaultId }),
		[statusSystemDefaultId, position],
	);

	return (
		<GridItem
			p={2}
			borderRightWidth="1px"
			borderRightColor="gray.15"
			height="100%"
			{...gridProps}>
			<VStack align="flex-start" w="100%" spacing={1.5} height="100%">
				<HStack justify="space-between" spacing={2} w="full">
					<HStack spacing={2}>
						<CustomTag
							label={statusName}
							bgColor={dataByColumnType.bgColor}
							labelColor={dataByColumnType.textColor}
						/>
						{tasks.length ? <Text>{tasks.length}</Text> : null}
					</HStack>
				</HStack>
				<Box w="100%" h="100%">
					<Box
						w="100%"
						h="100%"
						transition="background .3s"
						bg="transparent">
						{tasks?.map(task => (
							<Box key={task.cardId} mb={1.5} p={0.5}>
								<DecoyTaskCard
									cardName={task.cardName}
									companyChatData={task.message}
									currentUserTagged={task.currentUserTagged}
									tgSenderName={
										task.telegramMessage.senderName
									}
									tgMessageText={task.telegramMessage.text}
									tgUnreadCount={task.tgUnreadCount}
									cardId={task.cardId}
								/>
							</Box>
						))}
					</Box>
				</Box>
			</VStack>
		</GridItem>
	);
};
