import React from 'react';
import { Box, HStack, Button, Text } from '@chakra-ui/react';
import { EventsIndicator, Icon } from '../../../components';
import { BoardTabsType } from '../types';
import { colors } from '../../../theme/colors';

interface BoardTabProps extends BoardTabsType {
	isActive: boolean;
	onClick: VoidFunction;
}

export const BoardTab: React.FC<BoardTabProps> = ({
	isActive,
	iconName,
	activityTextColor,
	activityBgColor,
	label,
	activity,
	onClick,
}) => {
	return (
		<Button
			borderRadius="4px"
			bg={isActive ? 'gray.15' : 'transparent'}
			px={2}
			flexShrink={0}
			py="5px"
			onClick={onClick}
			_hover={{
				bg: isActive ? 'gray.15' : 'gray.20',
			}}>
			<HStack spacing={1} align="center">
				{iconName ? (
					<Box width="16px" height="16px">
						<Icon
							name={iconName}
							width="16px"
							height="16px"
							color={isActive ? colors.primary : colors.secondary}
						/>
					</Box>
				) : null}
				<Text
					variant="bodyLarge"
					color={isActive ? 'primary' : 'secondary'}
					fontWeight={500}>
					{label}
				</Text>
				<EventsIndicator
					count={activity}
					wrapperProps={{
						bg: activityBgColor,
					}}
					textProps={{
						color: activityTextColor,
					}}
				/>
			</HStack>
		</Button>
	);
};
