import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from './auth.reducer';

type InitialState = {
	activeLabelId: number;
};

const initialState: InitialState = {
	activeLabelId: 0,
};

const dashboardStateReducer = createSlice({
	name: 'dashboardState',
	initialState,
	reducers: {
		setActiveLabelId: (state, action: PayloadAction<number>) => {
			state.activeLabelId = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(logout, () => {
			return initialState;
		});
	},
});

export const { setActiveLabelId } = dashboardStateReducer.actions;

export default dashboardStateReducer.reducer;
