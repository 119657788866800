import React, { useRef } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Text,
	Button,
	VStack,
} from '@chakra-ui/react';

interface IConfirmDeleteModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	onConfirm: VoidFunction;
	confirmLoading?: boolean;
	title: string;
	description: string;
	confirmLabel: string;
	cancelLabel?: string;
}

export const ConfirmDeleteModal: React.FC<IConfirmDeleteModalProps> = ({
	isOpen,
	onClose,
	onConfirm,
	confirmLoading,
	title,
	description,
	confirmLabel,
	cancelLabel = 'Cancel',
}) => {
	const cancelRef = useRef(null);
	return (
		<AlertDialog
			motionPreset="slideInBottom"
			leastDestructiveRef={cancelRef}
			size="xs"
			onClose={onClose}
			isOpen={isOpen}
			isCentered={true}>
			<AlertDialogOverlay />
			<AlertDialogContent>
				<AlertDialogHeader
					textAlign="center"
					color="primary"
					fontSize="16px"
					fontWeight="600">
					{title}
				</AlertDialogHeader>
				<AlertDialogBody>
					<Text
						textAlign="center"
						color="primary"
						fontSize="13px"
						fontWeight="400">
						{description}
					</Text>
				</AlertDialogBody>
				<AlertDialogFooter>
					<VStack w="100%">
						<Button
							ref={cancelRef}
							onClick={onClose}
							w="100%"
							variant="outline"
							color="secondary"
							borderColor="transparentLight.10"
							borderRadius="4px">
							{cancelLabel}
						</Button>
						<Button
							colorScheme="red"
							w="100%"
							variant="outline"
							borderColor="red.20"
							color="red.DEFAULT"
							borderRadius="4px"
							onClick={onConfirm}
							isLoading={confirmLoading}>
							{confirmLabel}
						</Button>
					</VStack>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};
