import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalWrapper } from '../../components';
import { ModalBody } from './components';
import { RootStore } from '../../redux/createStore';
import { setSelectTeamChatModalOpenState } from '../../redux/reducers/workspace.reducer';

export const SelectTeamChatModal: React.FC = () => {
	const dispatch = useDispatch();

	const { selectTeamChatModalOpen } = useSelector(
		(state: RootStore) => state.workspace,
	);

	const onClose = () => {
		dispatch(setSelectTeamChatModalOpenState(false));
	};

	return (
		<ModalWrapper isOpen={selectTeamChatModalOpen} onClose={onClose}>
			<ModalBody onClose={onClose} />
		</ModalWrapper>
	);
};
