import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	signalRConnected: boolean;
}

const initialState: InitialState = {
	signalRConnected: false,
};

const signalRReducer = createSlice({
	name: 'signalR',
	initialState,
	reducers: {
		setConnectionState: (state, action: PayloadAction<boolean>) => {
			state.signalRConnected = action.payload;
		},
	},
});
export const { setConnectionState } = signalRReducer.actions;

export default signalRReducer.reducer;
