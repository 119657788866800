import React from 'react';

// interface IMentionProps {
// 	display: string;
// 	onAdd: (id: string, display: string) => void;
// 	onRemove: () => void;
// }

const Mention = ({ display }) => {
	// const styles = useStyles(defaultStyle, { style, className, classNames });
	return <strong>{display}</strong>;
};

// Mention.propTypes = {
// 	/**
// 	 * Called when a new mention is added in the input
// 	 *
// 	 * Example:
// 	 *
// 	 * ```js
// 	 * function(id, display) {
// 	 *   console.log("user " + display + " was mentioned!");
// 	 * }
// 	 * ```
// 	 */
// 	onAdd: PropTypes.func,
// 	onRemove: PropTypes.func,

// 	renderSuggestion: PropTypes.func,

// 	trigger: PropTypes.oneOfType([
// 		PropTypes.string,
// 		PropTypes.instanceOf(RegExp),
// 	]),
// 	markup: PropTypes.string,
// 	displayTransform: PropTypes.func,
// 	/**
// 	 * If set to `true` spaces will not interrupt matching suggestions
// 	 */
// 	allowSpaceInQuery: PropTypes.bool,

// 	isLoading: PropTypes.bool,
// };

Mention.defaultProps = {
	trigger: '@',
	markup: '@[__display__](__id__)',
	displayTransform: function (id, display) {
		return display || id;
	},
	onAdd: () => null,
	onRemove: () => null,
	renderSuggestion: null,
	isLoading: false,
	appendSpaceOnAdd: false,
};

export default Mention;
