/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import * as signalR from '@microsoft/signalr';
import { useSelector, useDispatch } from 'react-redux';
import { setConnectionState } from '../../redux/reducers/signal-r.reducer';
import { setWorkspaceTelegramIds } from '../../redux/reducers/workspace.reducer';
import { RootStore } from '../../redux/createStore';
import {
	useGetWorkspaceCardsStatuses,
	useGetWorkspaceMembers,
	useGetWorkspaceTelegramIds,
	useGetTgFolders,
} from '../../queries';
import { SignalRProvider } from '../../services';
import { useSubscribeToEvents } from './hooks';
import { useGetWorkspaceId } from '../../hooks';
import { resetChatsIsRefetchDisabledState } from '../../redux/reducers/telegram-state.reducer';

export const MainLayout: React.FC = () => {
	const dispatch = useDispatch();
	const workspaceId = useGetWorkspaceId();
	const { user } = useSelector((state: RootStore) => state.auth);
	const { accessToken } = useSelector((state: RootStore) => state.auth.token);

	useGetWorkspaceCardsStatuses(user.isOnboarded && !!workspaceId);
	useGetWorkspaceMembers(user.isOnboarded && !!workspaceId);
	useGetTgFolders();
	useSubscribeToEvents();

	const { data: workspaceTelegramIds } = useGetWorkspaceTelegramIds();

	useEffect(() => {
		if (workspaceTelegramIds?.value) {
			dispatch(
				setWorkspaceTelegramIds(
					workspaceTelegramIds.value?.telegramUserIds,
				),
			);
		}
	}, [workspaceTelegramIds, dispatch]);

	useEffect(() => {
		dispatch(resetChatsIsRefetchDisabledState());
	}, []);

	return (
		<SignalRProvider
			connectEnabled={!!accessToken}
			accessTokenFactory={() => accessToken}
			dependencies={[accessToken]}
			url={import.meta.env.VITE_API_BASE_URL + '/hub'}
			skipNegotiation={true}
			onOpen={() => {
				dispatch(setConnectionState(true));
				console.log('[SignalR] connect open');
			}}
			onReconnect={() => {
				console.log('[SignalR] reconnect');
				dispatch(setConnectionState(true));
			}}
			onClosed={error => {
				dispatch(setConnectionState(false));
				console.log(error, '[SignalR] signalR closed');
			}}
			onError={async error => {
				console.log(error, '[SignalR] signal R onError');
			}}
			// logger={signalR.LogLevel.Trace}
			logMessageContent={true}
			transport={signalR.HttpTransportType.WebSockets}>
			<Box width="full">
				<Outlet />
			</Box>
		</SignalRProvider>
	);
};
