import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../types';
import API from '../../../api/api.base';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';
import { useGetWorkspaceId } from '../../../hooks';

export const useArchiveNotification = () => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: id => {
			return API.put(
				`/api/workspaces/${workspaceId}/notifications/${id}/archive`,
				{},
			);
		},
		onSuccess: async data => {
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Error occurred while updating notification',
				);
				return;
			}

			Alerter.success(
				data.messages?.[0]?.message ||
					'Notification status was successfully updated!',
			);
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_NOTIFICATIONS, workspaceId],
			});
		},
	});
};
