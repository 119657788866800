import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
	filterId: number;
	statusId: number;
};

const initialState: InitialState = {
	filterId: 1,
	statusId: 0,
};

const pageNavigationStateReducer = createSlice({
	name: 'pageNavigationState',
	initialState,
	reducers: {
		setCardsStatusId: (state, action: PayloadAction<number>) => {
			state.statusId = action.payload;
		},
		setCardsFilterId: (state, action: PayloadAction<number>) => {
			state.filterId = action.payload;
		},
	},
});

export const { setCardsStatusId, setCardsFilterId } =
	pageNavigationStateReducer.actions;

export default pageNavigationStateReducer.reducer;
