import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import { ApiResponseEmpty } from '../../../../../types';
import { CreateReminderPayload } from '../../../types';
import { Alerter } from '../../../../../utils';
import { QueryKeys } from '../../../../../constants';

export const useCreateReminder = () => {
	const queryClient = useQueryClient();

	return useMutation<ApiResponseEmpty, unknown, CreateReminderPayload>({
		mutationFn: payload => {
			const { workspaceId, cardId, ...bodyPayload } = payload;

			return API.post(
				`/api/workspaces/${workspaceId}/cards/${cardId}/reminders/create`,
				bodyPayload,
			);
		},
		onSuccess: (data, payload) => {
			const { workspaceId, cardId } = payload;

			if (!data.success) {
				Alerter.error(
					data?.errors?.[0].message || 'Something went wrong',
				);
				return;
			}

			queryClient.resetQueries({
				queryKey: [QueryKeys.GET_CARD_REMINDERS, cardId, workspaceId],
			});
			Alerter.success(
				data.messages?.[0]?.message ||
					'Reminder was successfully created!',
			);
		},
	});
};
