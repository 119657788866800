/* eslint-disable indent */
import React, { useEffect, useState, useMemo } from 'react';
import { Box, Button, Flex, Text, chakra } from '@chakra-ui/react';
import { useSelector as useReduxSelector } from 'react-redux';
import { queryClient, QueryKeys } from '../../../constants';
import { useTelegram } from '../../../services';
import { useSyncFolders } from '../queries';
import { FolderItem } from '../components';
import { EmptyItemsText, Icon, Loader } from '../../../components';
import { RootStore } from '../../../redux/createStore';
import { Alerter, prepareFoldersSyncPayload } from '../../../utils';
import { ApiResponse, TgFoldersResponseItem } from '../../../types';
import { colors } from '../../../theme/colors';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { useGetTgApiFolders } from '../../../services/telegram/queries';
import { fetchChatsInFolder } from '../../../services/telegram/utils';

interface ChatsProps {
	onFinish: VoidFunction;
	includeSkip?: boolean;
}

export const ChatsSync: React.FC<ChatsProps> = ({ onFinish, includeSkip }) => {
	const tg = useTelegram();

	const workspaceId = useGetMatchedWorkspaceId();

	const [foldersSelected, setFoldersSelected] = useState<Set<number>>(
		new Set(),
	);
	const { isSynced } = useReduxSelector(
		(state: RootStore) => state.syncState,
	);

	const { data: foldersData, isFetching: isFoldersFetching } =
		useGetTgApiFolders(tg?.methods, tg && isSynced);

	const [isLoading, setIsLoading] = useState(false);

	const tgFolders = queryClient.getQueryData<
		ApiResponse<{ folders: TgFoldersResponseItem[] }>
	>([QueryKeys.GET_TG_FOLDERS, workspaceId]);

	const syncFoldersMutation = useSyncFolders();

	const filteredFromEmptyFolders = useMemo(
		() =>
			foldersData?.list?.filter(elem => elem?.includedChatIds.length) ||
			[],
		[foldersData],
	);

	const folderIds = useMemo(
		() => filteredFromEmptyFolders.map(folder => folder?.id) || [],
		[filteredFromEmptyFolders],
	);

	useEffect(() => {
		if (tgFolders?.value?.folders?.length && folderIds?.length) {
			const updatedSet = new Set<number>();

			tgFolders?.value?.folders.forEach(elem => {
				if (folderIds.includes(elem.telegramFolderId)) {
					updatedSet.add(elem.telegramFolderId);
				}
			});

			setFoldersSelected(updatedSet);
		}
	}, [folderIds, tgFolders?.value?.folders]);

	useEffect(() => {
		if (!tgFolders?.value?.folders?.length) {
			setFoldersSelected(new Set());
		}
	}, [tgFolders?.value?.folders]);

	const onChangeSelectedFolders = (value: boolean, id: number) => {
		setFoldersSelected(prevSelected => {
			const updatedSet = new Set(prevSelected);
			if (value) {
				updatedSet.add(id);
			} else {
				updatedSet.delete(id);
			}
			return updatedSet;
		});
	};

	const getChats = async () => {
		try {
			setIsLoading(true);

			if (!tg?.custom?.proxy || !workspaceId) {
				throw new Error('WorkspaceId or Telegram Proxy is undefined');
			}

			const foldersSelectedArray = Array.from(foldersSelected);

			const chatPromises = foldersSelectedArray.map(folderId =>
				fetchChatsInFolder(queryClient, tg.custom, folderId),
			);

			const payload = await prepareFoldersSyncPayload({
				foldersArray: foldersSelectedArray,
				promises: chatPromises,
			});
			if (!payload?.length) {
				throw new Error('No chats synced');
			}
			await syncFoldersMutation.mutateAsync(
				{
					workspaceId: workspaceId,
					folders: payload,
				},
				{
					onSuccess(data) {
						if (!data.success) {
							console.error(
								`Error in API response in syncFoldersMutation: ${data}`,
							);
							Alerter.error(
								data?.errors
									? `Chat sync response error: ${data?.errors?.[0]?.message}`
									: 'Unknown error while syncing chats',
							);
							return;
						}
						onFinish();
					},
				},
			);
		} catch (error) {
			console.error('Error in getChats: ', error);
			Alerter.error(`Sync chats request error: ${error}`);
		} finally {
			setIsLoading(false);
		}
	};

	const continueWithoutSync = () => {
		syncFoldersMutation.mutate(
			{
				workspaceId: workspaceId,
				folders: [],
			},
			{
				onSuccess(data) {
					if (data.success) {
						onFinish();
					}
				},
			},
		);
	};

	return (
		<Flex flexDirection={'column'} align={'center'} gap={'30px'}>
			<Box>
				<Text
					fontWeight={'600'}
					fontSize={'20px'}
					lineHeight={'24px'}
					textAlign={'center'}>
					Chats sync
				</Text>
				<Text textAlign={'center'} mt={'16px'} color={'secondary'}>
					Sync your Telegram chats with DISE by choosing folders that
					you want to track.
				</Text>
			</Box>

			{!isFoldersFetching ? (
				filteredFromEmptyFolders.length && foldersData?.list?.length ? (
					<Flex
						flexDirection={'column'}
						align={'start'}
						borderRadius={'8px'}
						border={'1px solid'}
						borderColor="gray.20"
						maxH="50vh"
						overflowY="auto"
						bg="gray.10"
						w={'100%'}>
						{foldersData.list
							.filter(elem => elem?.includedChatIds.length)
							.map(folder => (
								<FolderItem
									name={folder?.title || ''}
									value={folder?.id.toString() || ''}
									checked={foldersSelected.has(
										folder?.id || 0,
									)}
									key={folder?.id}
									onCheckedChange={value =>
										onChangeSelectedFolders(
											value,
											folder?.id || 0,
										)
									}
								/>
							))}
					</Flex>
				) : (
					<EmptyItemsText
						text="No folders can be synced now"
						wrapperProps={{ width: '100%' }}
					/>
				)
			) : (
				<Loader centerHeight="180px" />
			)}
			<Flex
				p="8px"
				borderRadius="8px"
				border="1px solid"
				borderColor="gray.20"
				gap="4px"
				bg="gray.10">
				<Icon
					name="info-circle"
					width="16px"
					height="16px"
					style={{ flexShrink: 0 }}
					color={colors.tertiary}
				/>
				<Text
					fontSize="11px"
					color="secondary"
					fontWeight="400"
					lineHeight="16px">
					<chakra.span fontWeight="500">
						We don’t have access to your messages.
					</chakra.span>{' '}
					DISE is a Telegram client which displays messages served
					from Telegram servers and use local cache to enable CRM
					functionality (like “unanswered”). Only synced chats appear
					in DISE.
				</Text>
			</Flex>

			<Button
				w={'full'}
				maxW={'200px'}
				variant={'outline'}
				alignSelf={'center'}
				boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
				onClick={getChats}
				isDisabled={foldersSelected.size === 0}
				isLoading={
					foldersSelected.size !== 0 &&
					(isLoading || syncFoldersMutation.isPending)
				}>
				Sync with DISE
			</Button>

			{foldersSelected.size || !includeSkip ? null : (
				<Button
					onClick={continueWithoutSync}
					w={'65%'}
					variant={'ghost'}
					alignSelf={'center'}
					isLoading={
						foldersSelected.size === 0 &&
						syncFoldersMutation.isPending
					}>
					Continue without sync
				</Button>
			)}
		</Flex>
	);
};
