/* eslint-disable indent */

import React, { useEffect, useMemo } from 'react';
import {
	Box,
	Flex,
	Tabs,
	TabList,
	TabPanels,
	TabPanel,
	TabIndicator,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQueryClient, InfiniteData } from '@tanstack/react-query';
import { TabWithIcon } from '../../components';
import {
	InternalChat,
	Activity,
	Templates,
	Tasks,
	Reminders,
	Info,
} from './tabs';
import { RootStore } from '../../redux/createStore';
import { useTelegram } from '../../services';
import { useGetCardActivitiesOptions } from './queries';
import { useUserAvatars, useGetWorkspaceId } from '../../hooks';
import { ApiResponse } from '../../types';
import { QueryKeys, CHAT_TABS, TEAM_CHAT_TABS } from '../../constants';
import { resetChatTeamMembersData } from '../../redux/reducers/chat-state.reducer';
import { RestrictedPlaceholder } from './components';
import { ChatResponse } from './types';
import { useGetBoardCards } from '../Dashboard/queries';

const ChatPage: React.FC = () => {
	const { id } = useParams();
	const workspaceId = useGetWorkspaceId();
	const tg = useTelegram();
	const queryClient = useQueryClient();
	const dispatch = useDispatch();
	const { data: cards, isLoading } = useGetBoardCards();

	const { isSynced } = useSelector((state: RootStore) => state.syncState);
	const { workSpace } = useSelector((state: RootStore) => state.auth.user);

	const cardId = id ? +id : 0;
	const isTeamChat = useMemo(
		() => workSpace?.teamChat?.cardId === cardId,
		[workSpace, cardId],
	);

	const { chatTelegramId, cardTitle, cardLabels } = useMemo(() => {
		const currentCard = cards?.value?.cards?.find(
			card => card.cardId === cardId,
		);
		const chatData = {
			cardTitle: currentCard?.cardName || '',
			chatTelegramId: 0,
			cardLabels: [],
		};
		return isTeamChat && workSpace?.teamChat?.id
			? { ...chatData, chatTelegramId: workSpace.teamChat.id }
			: {
					...chatData,
					cardLabels: currentCard?.labels || [],
					chatTelegramId: currentCard?.chatTelegramId || 0,
				};
	}, [cards?.value?.cards, cardId, isTeamChat, workSpace?.teamChat?.id]);

	const cachedData = useSelector(
		(state: RootStore) => state.telegramState.chats[chatTelegramId],
	);

	const idToFetch = useMemo(
		() => (isTeamChat ? 0 : id ? +id : 0),
		[id, isTeamChat],
	);

	const teamTelegramIds = queryClient.getQueryData<
		ApiResponse<{ telegramUserIds: number[] }>
	>([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

	const chatData = queryClient.getQueryData<
		InfiniteData<ChatResponse & { notExists?: true }>
	>([QueryKeys.GET_CHAT_MESSAGES, cardId, workspaceId]);

	useUserAvatars(teamTelegramIds?.value?.telegramUserIds);

	//cover team chat
	useGetCardActivitiesOptions(
		!!workspaceId && !!idToFetch,
		workspaceId,
		idToFetch,
	);

	useEffect(() => {
		if (chatTelegramId && tg && isSynced && cachedData?.hasAccess) {
			tg?.actions.proxy.openChat({
				id: chatTelegramId.toString(),
			});
			tg?.iframe.contentWindow?.focus();

			return () => {
				dispatch(resetChatTeamMembersData());
			};
		}
	}, [tg, chatTelegramId, dispatch, isSynced, cachedData?.hasAccess]);

	if (!workspaceId || chatData?.pages[0]?.notExists) {
		return <RestrictedPlaceholder />;
	}

	return (
		<Box w="full" maxW="full" pl="8px">
			<Flex direction="row" gap="8px" h="calc(100vh - 68px)">
				<Box
					flex={1}
					flexShrink={0}
					backgroundColor="white"
					borderRadius="4px"
					borderWidth="1px"
					boxShadow="0px 0px 4px 0px #0000000A"
					borderColor="gray.20">
					<Tabs variant="unstyled" height="100%">
						<TabList
							borderBottomWidth="1px"
							borderBottomColor="gray.15"
							columnGap="8px">
							{(isTeamChat ? TEAM_CHAT_TABS : CHAT_TABS).map(
								(elem, index) => (
									<TabWithIcon
										key={index}
										iconName={elem.iconName}
										label={elem.label}
									/>
								),
							)}
						</TabList>
						<TabIndicator
							mt="-1.5px"
							height="1px"
							bg="primary"
							borderRadius="1px"
						/>
						<TabPanels height="calc(100vh - 105px)">
							{!isTeamChat ? (
								<TabPanel p="0" height="100%">
									<InternalChat
										key={id}
										cardId={cardId}
										workspaceId={workspaceId}
									/>
								</TabPanel>
							) : null}
							{!isTeamChat ? (
								<TabPanel p="0" height="100%">
									<Info
										cardTitle={cardTitle}
										workspaceId={workspaceId}
										cardId={cardId}
										isCardDataLoading={isLoading}
										cardLabels={cardLabels}
									/>
								</TabPanel>
							) : null}
							{!isTeamChat ? (
								<TabPanel p="0" height="100%">
									<Activity
										key={id}
										workspaceId={workspaceId}
										cardId={cardId}
									/>
								</TabPanel>
							) : null}
							{!isTeamChat ? (
								<TabPanel p="0">
									<Templates
										cardId={cardId}
										isUserHasAccess={cachedData?.hasAccess}
									/>
								</TabPanel>
							) : null}
							<TabPanel p="0">
								<Tasks
									workspaceId={workspaceId}
									cardId={cardId}
								/>
							</TabPanel>
							{!isTeamChat ? (
								<TabPanel p="0">
									<Reminders
										workspaceId={workspaceId}
										cardId={cardId}
									/>
								</TabPanel>
							) : null}
						</TabPanels>
					</Tabs>
				</Box>
			</Flex>
		</Box>
	);
};

export default ChatPage;
