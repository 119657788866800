import { getMentions } from './getMentions';
import { ConfigFromChildren } from '../types';

export const getEndOfLastMention = (
	value: string,
	config: ConfigFromChildren[],
) => {
	const mentions = getMentions(value, config);
	const lastMention = mentions[mentions.length - 1];
	return lastMention
		? lastMention.plainTextIndex + lastMention.display.length
		: 0;
};
