import React, { memo } from 'react';
import { NotificationTypeEnum } from '../types';
import { getPriorityStatusById } from '../../../utils';
import { CustomTag } from '../../../components';
import { CardTaskPriorityEnum } from '../../../types';

interface PriorityTagProps {
	priorityId: CardTaskPriorityEnum | null;
	priorityName: string | null;
	type: NotificationTypeEnum;
}

export const PriorityTag: React.FC<PriorityTagProps> = memo(
	({ type, priorityId, priorityName }) => {
		if (
			!priorityName ||
			!priorityId ||
			type !== NotificationTypeEnum.TASK
		) {
			return null;
		}

		const priorityData = getPriorityStatusById(priorityId);
		return (
			<CustomTag
				label={priorityName}
				bgColor={priorityData.bgColor}
				labelColor={priorityData.textColor}
				wrapperProps={{
					borderRadius: '4px',
				}}
			/>
		);
	},
);

PriorityTag.displayName = 'PriorityTag';
