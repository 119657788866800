import React, { PropsWithChildren, useState, useMemo } from 'react';
import { Requester, Responder } from 'jsonrpc-iframe';
import { useInterval } from 'usehooks-ts';
import { Actions, Methods, Custom, Events } from './types';
import { TG_CONFIG } from '../../tg-config';

export type TelegramWindowContextType = {
	actions: Requester<Actions>;
	methods: Requester<Methods>;
	custom: Requester<Custom>;
	events: Responder<Events>;
	iframe: HTMLIFrameElement;
	status: Requester<{ check: () => boolean }>;
};

function getTelegramIframe() {
	return document.querySelector<HTMLIFrameElement>('iframe#telegram-iframe');
}

export const TelegramWindowContext = React.createContext<
	TelegramWindowContextType | undefined
>(undefined);

export const TelegramWindowProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [iframe, setIframe] = useState(getTelegramIframe());

	useInterval(
		() => {
			const newIframe = getTelegramIframe();
			if (iframe != newIframe) {
				setIframe(newIframe);
			}
		},
		iframe?.contentWindow ? null : 100,
	);

	const args = useMemo(() => {
		if (!iframe || !iframe.contentWindow) {
			return undefined;
		}

		const actions = new Requester<Actions>(
			'actions',
			iframe.contentWindow,
			TG_CONFIG.TG_FRAME_ORIGIN,
		);
		const methods = new Requester<Methods>(
			'methods',
			iframe.contentWindow,
			TG_CONFIG.TG_FRAME_ORIGIN,
		);
		const custom = new Requester<Custom>(
			'custom',
			iframe.contentWindow,
			TG_CONFIG.TG_FRAME_ORIGIN,
		);
		const events = new Responder<Events>(
			'events',
			TG_CONFIG.TG_FRAME_ORIGIN,
		);
		const status = new Requester<{ check: VoidFunction }>(
			'status',
			iframe.contentWindow,
			TG_CONFIG.TG_FRAME_ORIGIN,
		);

		return {
			actions,
			methods,
			custom,
			events,
			iframe,
			status,
		};
	}, [iframe, iframe?.contentWindow]);

	return (
		<TelegramWindowContext.Provider value={args}>
			{children}
		</TelegramWindowContext.Provider>
	);
};

export function useTelegram() {
	const context = React.useContext(TelegramWindowContext);
	return context;
}
