import { NotificationTypeEnum } from './types';

export const getNotificationLabelByType = (type: NotificationTypeEnum) => {
	switch (type) {
		case NotificationTypeEnum.TASK:
			return 'Task';
		case NotificationTypeEnum.REMINDER:
			return 'Reminder';
		default:
			return 'Unknown';
	}
};
