import React from 'react';
import {
	ButtonGroup,
	Flex,
	IconButton,
	useEditableControls,
} from '@chakra-ui/react';
import { Icon } from '../../../components';
import { colors } from '../../../theme/colors';

export const StageItemEditableControls: React.FC = () => {
	const {
		isEditing,
		getSubmitButtonProps,
		// getCancelButtonProps,
		getEditButtonProps,
	} = useEditableControls();

	return isEditing ? (
		<ButtonGroup justifyContent="center" size="sm">
			<IconButton
				aria-label="edit"
				variant="ghost"
				minW="auto"
				boxSize="24px"
				borderRadius="4px"
				{...getSubmitButtonProps()}>
				<Icon
					name="edit"
					height="16px"
					width="16px"
					color={colors.secondary}
				/>
			</IconButton>
			{/*<IconButton*/}
			{/*	aria-label="accept"*/}
			{/*	icon={*/}
			{/*		<Icon*/}
			{/*			name="edit"*/}
			{/*			height="16px"*/}
			{/*			width="16px"*/}
			{/*			color={colors.secondary}*/}
			{/*		/>*/}
			{/*	}*/}
			{/*	{...getSubmitButtonProps()}*/}
			{/*/>*/}
			{/*<IconButton*/}
			{/*	aria-label="cancel"*/}
			{/*	icon={*/}
			{/*		<Icon*/}
			{/*			name="edit"*/}
			{/*			height="16px"*/}
			{/*			width="16px"*/}
			{/*			color={colors.secondary}*/}
			{/*		/>*/}
			{/*	}*/}
			{/*	{...getCancelButtonProps()}*/}
			{/*/>*/}
		</ButtonGroup>
	) : (
		<Flex justifyContent="center">
			<IconButton
				aria-label="edit"
				variant="ghost"
				minW="auto"
				boxSize="24px"
				borderRadius="4px"
				{...getEditButtonProps()}>
				<Icon
					name="edit"
					height="16px"
					width="16px"
					color={colors.secondary}
				/>
			</IconButton>
		</Flex>
	);
};
