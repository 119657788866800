import { useParams, useMatch } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { RootStore } from '../redux/createStore';

export const useGetWorkspaceId = () => {
	const { workspaceId } = useParams();
	// const { id } = useSelector((state: RootStore) => state.auth.user.workSpace);
	// TODO: return null instead of 0 here
	return +(workspaceId || 0);
};

export const useGetMatchedWorkspaceId = (path = '/:workspaceId/*') => {
	const match = useMatch(path);
	const workspaceId = match?.params?.workspaceId;

	if (workspaceId && !isNaN(Number(workspaceId))) {
		return Number(workspaceId);
	}
	// TODO: return null instead of 0 here
	return 0;
};
