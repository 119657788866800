import React, { useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { InputText } from '../../../components';
import { modalRegularTextStyleProps, modalSubHeaderStyleProps } from './helper';
import { useCreateWorkspace } from '../queries';
import { CreateWorkspaceForm, createWorkspaceResolver } from '../validation';
import { UploadWorkspaceAvatar } from '../components';

interface ICreateWorkspaceProps {
	isIsolated?: boolean;
}

export const CreateWorkspace: React.FC<ICreateWorkspaceProps> = ({
	isIsolated = false,
}) => {
	const [imageFromFile, setImageFromFile] = useState('');
	const [imageFile, setImageFile] = useState<File | null>(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
	} = useForm<CreateWorkspaceForm>({ resolver: createWorkspaceResolver });

	const createWorkspaceMutation = useCreateWorkspace(isIsolated);

	const showImage = (file: File) => {
		const imageDataUrl = URL.createObjectURL(file);
		setImageFile(file);
		setImageFromFile(imageDataUrl);
		clearErrors('image');
	};

	const removeImage = () => {
		setImageFromFile('');
		setImageFile(null);
	};

	const onSubmit = (data: CreateWorkspaceForm) => {
		const fd = new FormData();
		fd.append('Name', data.name);
		if (imageFile) {
			fd.append('NewImage', imageFile);
		}
		createWorkspaceMutation.mutate(fd);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Flex flexDirection={'column'} align={'center'} gap={'30px'}>
				<Box>
					<Text
						fontWeight={'600'}
						fontSize={'20px'}
						lineHeight={'24px'}
						textAlign={'center'}>
						Create your workspace
					</Text>
					<Text textAlign={'center'} mt={'16px'} color={'secondary'}>
						A shared environment where you will be able to manage
						your customer relations with your team.
					</Text>
				</Box>

				<UploadWorkspaceAvatar
					imageFromFile={imageFromFile}
					removeImage={removeImage}
					showImage={showImage}
					title="Workspace logo"
				/>

				<Box w={'100%'}>
					<Text {...modalSubHeaderStyleProps}>Workspace name</Text>
					<Text {...modalRegularTextStyleProps}>
						The name of your organization
					</Text>
					<InputText
						{...register('name')}
						mt={'16px'}
						placeholder={'Apple'}
						errorMsg={errors?.name?.message}
					/>
				</Box>

				<Button
					w={'65%'}
					type="submit"
					isLoading={createWorkspaceMutation.isPending}>
					{isIsolated ? 'Create' : 'Continue'}
				</Button>
			</Flex>
		</form>
	);
};
