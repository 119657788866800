import React, { ReactElement } from 'react';
import {
	Box,
	BoxProps,
	ButtonProps,
	HStack,
	IconButton,
} from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Icon } from '../Icon/Icon';

interface ActionsMenuProps {
	isOpen?: boolean;
	deleteButtonProps?: ButtonProps;
	includeDelete?: boolean;
	children?: ReactElement | null;
	sectionProps?: BoxProps;
}

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
	isOpen = false,
	deleteButtonProps,
	includeDelete,
	children,
	sectionProps,
}) => {
	return (
		<Box
			position="absolute"
			className="actions-menu"
			top="50%"
			right="10px"
			transform={
				isOpen
					? 'translateY(-50%) translateX(0)'
					: 'translateY(-50%) translateX(100%)'
			}
			bg="gray.10"
			display="flex"
			justifyContent="center"
			alignItems="center"
			borderRadius="4px"
			p={0}
			boxShadow={`0 0 9px 7px ${colors.gray[10]}`}
			opacity={isOpen ? 1 : 0}
			visibility={isOpen ? 'visible' : 'hidden'}
			zIndex={20}
			transition="opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, transform 0.4s ease-in-out"
			{...sectionProps}>
			<HStack zIndex={10} spacing={0}>
				{children}

				{includeDelete ? (
					<IconButton
						aria-label="delete template"
						minW="auto"
						variant="ghost"
						boxSize="24px"
						borderRadius="4px"
						{...deleteButtonProps}>
						<Icon
							name="delete-red-bin"
							height="16"
							width="16"
							color={colors.red['DEFAULT']}
						/>
					</IconButton>
				) : null}
			</HStack>
		</Box>
	);
};
