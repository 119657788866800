import React, { useMemo } from 'react';
import {
	Button,
	HStack,
	IconButton,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { CustomDatePicker, Icon } from '../../../components';
import { colors } from '../../../theme/colors';
import { getReminderMinDate, getViewDateByFormat } from '../../../utils';

interface RemindedFieldProps {
	date: Date | null;
	onChangeDate: (date: Date | null) => void;
	label?: string;
	fieldTooltip?: string;
}

export const ReminderField: React.FC<RemindedFieldProps> = ({
	date,
	onChangeDate,
	label,
	fieldTooltip = 'Provide the correct reminder date',
}) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const isOutdated = useMemo(() => {
		const reminderMinDate = getReminderMinDate();

		return date && date < reminderMinDate;
	}, [date]);

	return (
		<VStack align="flex-start" spacing={1}>
			{label ? (
				<Text
					fontSize="11px"
					lineHeight="13px"
					fontWeight={600}
					color="gray.35"
					textTransform="uppercase">
					{label}
				</Text>
			) : null}

			<CustomDatePicker
				isOpen={isOpen}
				onOpenChange={() => {
					if (isOpen) {
						onClose();
					}
				}}
				startDate={date}
				setStartDate={date => {
					onChangeDate(date);
					onClose();
				}}
				placement="left-end"
				datePickerAlignPlacementAlignOffset={-53}
				datePickerAlignPlacementSideOffset={18}
				pickerMaxYear={new Date().getFullYear() + 10}
				minDate={getReminderMinDate()}
				buttonTrigger={
					<HStack w="full" h="full" gap={2}>
						<Button
							variant="unstyled"
							flex={1}
							borderWidth="1px"
							borderColor={isOutdated ? 'red.DEFAULT' : 'gray.20'}
							bg="#00000005"
							alignItems="center"
							justifyContent="flex-start"
							textAlign="start"
							p="0px 8px"
							h="100%"
							borderRadius="4px"
							title={
								date
									? isOutdated
										? fieldTooltip
										: 'Change date'
									: 'Set date'
							}
							onClick={onOpen}>
							<Text
								fontSize="13px"
								lineHeight="30px"
								color={date ? 'primary' : 'gray.35'}>
								{date
									? getViewDateByFormat(date, 'MMM D, YYYY')
									: 'None'}
							</Text>
						</Button>
						<IconButton
							aria-label="date picker"
							title={date ? 'Clear date' : 'Set date'}
							variant="outline"
							w="32px"
							minW="32px"
							h="32px"
							borderRadius="4px"
							bg={isOpen && !date ? 'gray.20' : 'transparent'}
							onClick={e => {
								if (!date) {
									onOpen();
									return;
								}
								e.stopPropagation();
								onChangeDate(null);
							}}>
							<Icon
								name={date ? 'delete-red-bin' : 'plus'}
								width="16px"
								height="16px"
								color={colors.gray[40]}
							/>
						</IconButton>
					</HStack>
				}
			/>
		</VStack>
	);
};
