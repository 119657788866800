import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
	isSynced: boolean;
};

const initialState: InitialState = {
	isSynced: false,
};

const syncStateReducer = createSlice({
	name: 'tgSyncState',
	initialState,
	reducers: {
		setSyncState: (state, action: PayloadAction<boolean>) => {
			state.isSynced = action.payload;
		},
	},
});

export const { setSyncState } = syncStateReducer.actions;

export default syncStateReducer.reducer;
