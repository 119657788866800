import React, { useCallback, useEffect } from 'react';
import {
	Button,
	Flex,
	Text,
	Link as ChakraLink,
	Box,
	VStack,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, ModalWrapper } from '../../components';
import { RootStore } from '../../redux/createStore';
import {
	setShowTelegramModal,
	setShowLoginFlow,
	setTelegramConnected,
} from '../../redux/reducers/login-flow.reducer';
import { useAuthContext } from '../../state/auth/context'
import { DID } from '../../state/auth/did'
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../constants/query-client';
import { QueryKeys } from '../../constants';
import { setAuthToken } from '../../redux/reducers/auth.reducer';
import { clearTgDataCache } from '../../redux/reducers/telegram-state.reducer';

export const AuthModal: React.FC = () => {
	const navigate = useNavigate()

	const dispatch = useDispatch();
	const { telegramConnected, showLoginFlow, userId, exchangingCodesLoading } =
		useSelector((state: RootStore) => state.loginFlow);


	const ctx = useAuthContext()

	const onConnectTelegram = () => {
		dispatch(setShowLoginFlow(false));
		dispatch(setShowTelegramModal(true));
	};

	const onCRMLogin = () => {
		if (!userId)
			return
		
		let tgid = parseInt(userId)
		if (!tgid)
			return

		ctx.msgs['ui.auth_requested']({ tgid, did: DID })
	};

	useEffect(() =>
	{
		if (ctx.state.type == "authed")
		{
			dispatch(setAuthToken({
				accessToken: ctx.state.jwt,
			}));
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_WORKSPACES],
			});
			dispatch(setShowLoginFlow(false));
			dispatch(setTelegramConnected({ isConnected: true }));
			dispatch(clearTgDataCache());
			navigate('/select-workspace');
		}
	}, [ctx.state.type, dispatch, navigate])

	let crmLoginBtnDisabled = !telegramConnected
	switch (ctx.state.type)
	{
		case "polling_refresh":
		case "waiting_for_jwt":
		case "waiting_for_otp":
		case "authed":
			crmLoginBtnDisabled = true
			break
	}

	return (
		<ModalWrapper
			isOpen={showLoginFlow}
			shouldClose={false}
			modalContentProps={{ maxW: '400px', p: '40px' }}
			modalBodyProps={{ p: '40px 18px 0px 18px' }}>
			<Flex align={'center'} flexDirection={'column'} gap={'32px'}>
				<Box w={'156px'} minW={'156px'} h={'37px'} mb={'10px'}>
					<Icon
						name={'logo'}
						width={'100%'}
						height={'100%'}
						strokeColor={'none'}
					/>
				</Box>
				<Text fontSize={'20px'} lineHeight={'24px'} fontWeight={'600'}>
					Log in
				</Text>
				<VStack spacing="12px" width="60%">
					<VStack w="full" spacing="8px">
						<Text
							color="primary"
							fontSize="12px"
							lineHeight="13px"
							fontWeight={400}>
							Step 1:
						</Text>
						<Button
							w="100%"
							isDisabled={telegramConnected}
							onClick={onConnectTelegram}>
							Connect Telegram
						</Button>
					</VStack>
					<VStack w="full" spacing="8px">
						<Text
							color="primary"
							fontSize="12px"
							lineHeight="13px"
							fontWeight={400}>
							Step 2:
						</Text>
						<Button
							w="100%"
							isDisabled={crmLoginBtnDisabled}
							onClick={onCRMLogin}
							isLoading={exchangingCodesLoading}>
							Log in to CRM
						</Button>
						<Text color="red">{ctx.state.type}</Text>
					</VStack>
				</VStack>
				<Text
					fontSize={'12px'}
					lineHeight={'18px'}
					textAlign={'center'}
					color={'tertiary'}>
					By using DISE, you agree to the{' '}
					<ChakraLink href="#" fontWeight={500}>
						Terms of Service
					</ChakraLink>{' '}
					and{' '}
					<ChakraLink href="#" fontWeight={500}>
						Data Processing Agreement
					</ChakraLink>
					.
				</Text>
			</Flex>
		</ModalWrapper>
	);
};
