import React, { useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { TasksSection } from '../components';
import {
	useGetAllCurrentUserTasks,
	useGetAllTasksOtherUsers,
} from '../queries';
import { CardTaskStatusEnum } from '../../../types';
import { Loader } from '../../../components';
import { useUpdateCardTaskStatus } from '../../Chat/queries';
import { Alerter } from '../../../utils';
import { QueryKeys } from '../../../constants';
import { useGetWorkspaceId } from '../../../hooks';

interface TasksPanelTemplateTabsProps {
	filterId: CardTaskStatusEnum;
}

export const TasksPanelTemplateTabs: React.FC<TasksPanelTemplateTabsProps> = ({
	filterId,
}) => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	const updateTaskMutation = useUpdateCardTaskStatus();

	const {
		data: currentUserTasksResponse,
		hasNextPage: currentUserTasksHasNextPage,
		isFetching: currentUserTasksLoading,
		isFetchingNextPage: currentUserTasksFetchingNextPage,
		fetchNextPage: currentUserTasksFetchNextPage,
	} = useGetAllCurrentUserTasks(workspaceId, filterId);
	const currentUserTasks = useMemo(
		() =>
			currentUserTasksResponse?.pages?.flatMap(page => page.tasks) || [],
		[currentUserTasksResponse?.pages],
	);

	const {
		data: othersUsersTasksResponse,
		hasNextPage: othersUsersTasksHasNextPage,
		isFetching: othersUsersTasksLoading,
		isFetchingNextPage: othersUsersTasksFetchingNextPage,
		fetchNextPage: othersUsersTasksFetchNextPage,
	} = useGetAllTasksOtherUsers(workspaceId, filterId);

	const otherUsersTasks = useMemo(
		() =>
			othersUsersTasksResponse?.pages?.flatMap(page => page.tasks) || [],
		[othersUsersTasksResponse?.pages],
	);

	const onUpdateTaskStatus = (
		cardTaskId: number,
		status: CardTaskStatusEnum,
		isCardArchived: boolean,
		telegramUsersIds: number[],
		workspaceId?: number,
	) => {
		try {
			if (!workspaceId) {
				return Alerter.error('Workspace is missing');
			}

			updateTaskMutation.mutate(
				{
					bodyPayload: {
						workspaceId,
						status,
						cardTaskId,
						telegramUsersIds,
					},
					isCardArchived,
				},
				{
					onSuccess: () => {
						queryClient.invalidateQueries({
							queryKey: [QueryKeys.GET_ALL_CURRENT_USER_TASKS],
						});
					},
				},
			);
		} catch (error) {
			console.log('onUpdateTaskStatus: ', error);
		}
	};

	const tabLoading = useMemo(
		() =>
			(currentUserTasksLoading && !currentUserTasksFetchingNextPage) ||
			(othersUsersTasksLoading && !othersUsersTasksFetchingNextPage),
		[currentUserTasksLoading, othersUsersTasksLoading],
	);

	const emptyData = useMemo(
		() => !currentUserTasks.length && !otherUsersTasks.length,
		[currentUserTasks, otherUsersTasks],
	);

	if (tabLoading) {
		return <Loader centerHeight="calc(100vh - 170px)" />;
	}
	if (!tabLoading && emptyData) {
		return (
			<Flex justify="center" align="center" h="calc(100vh - 160px)">
				<Text fontSize="24px" fontWeight={600}>
					There are no tasks at the moment
				</Text>
			</Flex>
		);
	}

	return (
		<Box>
			<TasksSection
				title="Your tasks"
				hasNextPage={currentUserTasksHasNextPage}
				data={currentUserTasks}
				isFetchingNextPage={currentUserTasksFetchingNextPage}
				fetchNextPage={currentUserTasksFetchNextPage}
				totalCount={
					currentUserTasksResponse?.pages?.[
						currentUserTasksResponse?.pages.length - 1
					]?.count || 0
				}
				isSinglePageSection={!otherUsersTasks.length}
				onUpdateTaskStatus={(
					cardTaskId,
					status,
					isCardArchived,
					telegramUsersIds,
				) =>
					onUpdateTaskStatus(
						cardTaskId,
						status,
						isCardArchived,
						telegramUsersIds,
						workspaceId,
					)
				}
			/>
			<TasksSection
				title="Other tasks"
				hasNextPage={othersUsersTasksHasNextPage}
				data={otherUsersTasks}
				isFetchingNextPage={othersUsersTasksFetchingNextPage}
				fetchNextPage={othersUsersTasksFetchNextPage}
				totalCount={
					othersUsersTasksResponse?.pages?.[
						othersUsersTasksResponse?.pages.length - 1
					]?.count || 0
				}
				isSinglePageSection={!currentUserTasks.length}
				sectionProps={{
					mt: currentUserTasks.length ? 8 : 0,
				}}
			/>
		</Box>
	);
};
