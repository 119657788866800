import { useMutation } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { QueryKeys } from '../../../../../constants';
import { DeleteMessagePayload } from '../../../types';
import { Alerter } from '../../../../../utils';

export const useDeleteMessage = () => {
	return useMutation<ApiResponseEmpty, unknown, DeleteMessagePayload>({
		mutationKey: [QueryKeys.DELETE_MESSAGE],
		mutationFn: payload => {
			const { cardId, messageId, workspaceId } = payload;
			return API.delete(
				`/api/workspaces/${workspaceId}/cards/${cardId}/chat/message/${messageId}/delete`,
			);
		},
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(
					data.errors?.[0]?.message ||
						'Error occurred while deleting task',
				);
				return;
			}
			Alerter.success(
				data.messages?.[0]?.message || 'Message successfully deleted',
			);
		},
	});
};
