import React from 'react';
import * as Select from '@radix-ui/react-select';
import classnames from 'classnames';
import { Icon } from '../Icon/Icon';
import './styles.css';

interface ISelectItemProps extends Select.SelectItemProps {
	children: React.ReactNode;
	className?: string;
	iconSize?: string;
	selectItemIndicatorIconName?: string;
	selectItemIndicatorProps?: Select.SelectItemIndicatorProps;
	disableIndicator?: boolean;
}

export const SelectItem = React.forwardRef<null, ISelectItemProps>(
	(
		{
			children,
			className,
			iconSize = '15',
			selectItemIndicatorIconName = 'radix-check',
			selectItemIndicatorProps,
			disableIndicator,
			...props
		},
		forwardedRef,
	) => {
		return (
			<Select.Item
				className={classnames('SelectItem', className)}
				{...props}
				ref={forwardedRef}>
				<Select.ItemText>{children}</Select.ItemText>

				{disableIndicator ? null : (
					<Select.ItemIndicator
						className="SelectItemIndicator"
						{...selectItemIndicatorProps}>
						<Icon
							name={selectItemIndicatorIconName}
							width={iconSize}
							height={iconSize}
						/>
					</Select.ItemIndicator>
				)}
			</Select.Item>
		);
	},
);
