import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

export const RestrictedPlaceholder: React.FC = () => {
	return (
		<Box h="calc(100vh - 68px)" pt="100px">
			<Heading variant="h2" color="gray.30" textAlign="center">
				This chat does not exist, or you have no access to it
			</Heading>
		</Box>
	);
};
