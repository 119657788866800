import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../types';
import API from '../../../api/api.base';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';
import { useGetWorkspaceId } from '../../../hooks';

export const useRemoveUserFromWorkspace = () => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationKey: [QueryKeys.DELETE_USER_FROM_WORKSPACE],
		mutationFn: id =>
			API.delete(`/api/workspaces/${workspaceId}/team/user/${id}/remove`),
		onSuccess(data) {
			if (!data.success) {
				Alerter.error(
					data?.errors?.[0]?.message || 'Error while deleting member',
				);
				return;
			}

			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_WORKSPACE_MEMBERS, workspaceId],
			});
			Alerter.success(
				data.messages?.[0]?.message ||
					'User successfully removed from workspace',
			);
		},
		onError(error) {
			Alerter.error(
				error?.toString() || 'Error occurred while deleting member',
			);
		},
	});
};
