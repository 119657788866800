import React, { useEffect } from 'react';
import { Box, Text, Button, Flex, Center } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WorkspaceItem } from '../components';
import { useGetWorkspaces } from '../../../queries';
import {
	setOnboardingActiveStep,
	setOnboardingVisibility,
} from '../../../redux/reducers/onboarding.reducer';
import { Loader, EmptyItemsText } from '../../../components';
import { OnboardingSteps } from '../../../types';

interface ISelectWorkspaceProps {
	isIsolated?: boolean;
}

export const SelectWorkspace: React.FC<ISelectWorkspaceProps> = ({
	isIsolated = false,
}) => {
	const { data, isLoading, isFetched } = useGetWorkspaces();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isFetched && data && !data?.value?.length) {
			dispatch(setOnboardingVisibility(true));
			dispatch(setOnboardingActiveStep(OnboardingSteps.CREATE_WORKSPACE));
			if (isIsolated) {
				navigate('/onboarding');
			}
		}
	}, [data, isFetched, dispatch, navigate, isIsolated]);

	return (
		<Box>
			<Text
				fontWeight={'600'}
				fontSize={'20px'}
				lineHeight={'24px'}
				textAlign={'center'}>
				Select a workspace
			</Text>
			{isLoading ? (
				<Loader centerHeight="180px" />
			) : (
				<Flex
					flexDirection={'column'}
					align={'start'}
					borderRadius={'8px'}
					border={'1px solid'}
					borderColor="gray.20"
					maxH="50vh"
					overflowY="auto"
					bg="gray.10"
					my="32px"
					w={'100%'}>
					{data?.value?.length ? (
						<>
							{data?.value.map(elem => (
								<WorkspaceItem
									key={elem.id}
									name={elem.name}
									id={elem.id}
									imageLink={elem.avatarThumbnailUrl}
								/>
							))}
						</>
					) : (
						<EmptyItemsText
							text="No workspaces"
							wrapperProps={{ width: '100%' }}
						/>
					)}
				</Flex>
			)}
			<Center>
				<Button
					variant={'outline'}
					alignSelf={'center'}
					w="200px"
					boxShadow="0px 2px 4px 0px #0000000A, 0px 0px 4px 0px #00000014"
					onClick={() => {
						dispatch(setOnboardingVisibility(true));
						dispatch(
							setOnboardingActiveStep(
								OnboardingSteps.CREATE_WORKSPACE,
							),
						);
						navigate('/onboarding');
					}}>
					Create a workspace
				</Button>
			</Center>
		</Box>
	);
};
