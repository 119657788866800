import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { useGetWorkspaceId } from '../../../hooks';

export const useUpdateWorkspaceLogo = (key: number) => {
	const queryClient = useQueryClient();
	const workspaceId = useGetWorkspaceId();

	return useMutation<ApiResponseEmpty, unknown, FormData | null>({
		mutationKey: [QueryKeys.UPDATE_WORKSPACE_LOGO, key],
		mutationFn: payload =>
			API.put(`/api/settings/workspaces/${workspaceId}/logo`, payload),
		onSuccess(data) {
			if (!data.success) {
				Alerter.error(
					data?.errors?.[0]?.message ||
						'Error while updating workspace image',
				);
				return;
			}
			Alerter.success('Workspace image successfully updated');
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_CURRENT_USER],
			});
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_WORKSPACES],
			});
		},
		onError(error) {
			Alerter.error(
				error?.toString() ||
					'Error occurred while updating workspace image',
			);
		},
	});
};
