import React, {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Button, Spinner, Text, VStack } from '@chakra-ui/react';
import {
	Icon,
	Popover,
	PopoverTrigger,
	PopoverContent,
	InputText,
} from '../../../../../../components';
import { colors } from '../../../../../../theme/colors';
import { LabelDefaultColors, QueryKeys } from '../../../../../../constants';
import { useDeleteLabel, useUpdateLabel } from '../../queries';
import { useDebounce, useGetWorkspaceId } from '../../../../../../hooks';

import styles from './EditLabelPopup.module.css';
import { useQueryClient } from '@tanstack/react-query';
import { ApiResponse, LabelItemType } from '../../../../../../types';
import { Alerter } from '../../../../../../utils';

interface EditLabelPopupProps {
	isOpen: boolean;
	onClose: VoidFunction;
	children: ReactElement;
	onToggle: VoidFunction;
	alignOffset?: number;
	sideOffset?: number;
	align?: 'center' | 'start' | 'end';
	name: string;
	colourId: number;
	workspaceLabelId: number;
	isCreate?: boolean;
	side?: 'bottom' | 'top' | 'right' | 'left';
}

export const EditLabelPopup = React.forwardRef<
	HTMLDivElement | null,
	EditLabelPopupProps
>(
	(
		{
			name,
			colourId,
			workspaceLabelId,
			isOpen,
			children,
			onToggle,
			onClose,
			isCreate,
			alignOffset = 0,
			sideOffset = 0,
			align = 'start',
			side = 'bottom',
		},
		ref,
	) => {
		const queryClient = useQueryClient();
		const workspaceId = useGetWorkspaceId();
		const editLabelMutation = useUpdateLabel(!!isCreate);
		const deleteLabelMutation = useDeleteLabel();

		const labelNames = useMemo(
			() =>
				queryClient
					.getQueryData<
						ApiResponse<{ workspaceLabels: LabelItemType[] }>
					>([QueryKeys.GET_WORKSPACE_LABELS, workspaceId])
					?.value?.workspaceLabels?.filter(
						el => el.workspaceLabelId !== workspaceLabelId,
					)
					?.map(el => el.name),
			[queryClient, workspaceId, workspaceLabelId],
		);

		const [currentName, setCurrentName] = useState(name);
		const [currentColourId, setCurrentColourId] = useState(colourId);

		const updateFieldIdRef = useRef(0);
		const isInitialStateUpdatedRef = useRef(false);
		const previousTypedLabelNameRef = useRef('');

		const onChangeLabelName = useCallback(() => {
			if (
				!isOpen ||
				!currentName ||
				isCreate ||
				previousTypedLabelNameRef.current.trim() === currentName.trim()
			) {
				return;
			}

			const trimmedLabelName = currentName.trim();

			if (labelNames?.includes(trimmedLabelName)) {
				return Alerter.error('Label with that name already exist');
			}

			updateFieldIdRef.current = -1;

			editLabelMutation.mutate(
				{
					colourId: currentColourId,
					name: trimmedLabelName,
					workspaceLabelId,
					workspaceId,
				},
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						updateFieldIdRef.current = 0;
						previousTypedLabelNameRef.current = trimmedLabelName;
					},
				},
			);
		}, [
			currentColourId,
			currentName,
			workspaceLabelId,
			workspaceId,
			isOpen,
			editLabelMutation,
			isCreate,
			labelNames,
		]);

		useDebounce(currentName, 500, onChangeLabelName);

		const onChangeLabelColor = useCallback(
			(colourId: number) => {
				if (colourId === currentColourId) {
					return;
				}

				updateFieldIdRef.current = colourId;

				editLabelMutation.mutate(
					{
						colourId,
						name: currentName.trim(),
						workspaceLabelId,
						workspaceId,
					},
					{
						onSuccess: data => {
							if (!data.success) {
								return;
							}
							setCurrentColourId(colourId);
							updateFieldIdRef.current = 0;

							if (!isCreate) {
								return;
							}
							onClose();
						},
					},
				);
			},
			[
				isCreate,
				onClose,
				currentName,
				workspaceLabelId,
				workspaceId,
				editLabelMutation,
				currentColourId,
			],
		);

		const onDeleteLabel = useCallback(() => {
			deleteLabelMutation.mutate(
				{ workspaceId, workspaceLabelId },
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						onClose();
					},
				},
			);
		}, [deleteLabelMutation, workspaceId, workspaceLabelId, onClose]);

		const resetLocalState = useCallback(() => {
			setCurrentName(name);
			setCurrentColourId(colourId);
			isInitialStateUpdatedRef.current = true;
			previousTypedLabelNameRef.current = name;
		}, [name, colourId]);

		useEffect(() => {
			if (isOpen && !isInitialStateUpdatedRef.current) {
				resetLocalState();
			}
			if (!isOpen) {
				isInitialStateUpdatedRef.current = false;
			}
		}, [isOpen, resetLocalState]);

		return (
			<Popover open={isOpen} onOpenChange={onToggle} modal={false}>
				<PopoverTrigger asChild={true}>{children}</PopoverTrigger>
				<PopoverContent
					ref={ref}
					sideOffset={sideOffset}
					alignOffset={alignOffset}
					align={align}
					side={side}
					className={styles.EditLabelPopoverContent}>
					<Box padding="4px">
						<InputText
							placeholder="Label name"
							borderRadius="4px"
							value={currentName}
							onChange={ev => setCurrentName(ev.target.value)}
							fontWeight={500}
							h="32px"
							boxShadow="unset"
							px="8px"
							borderColor="transparent"
							isReadOnly={isCreate}
							cursor={isCreate ? 'default' : 'text'}
							bg="transparent"
							rightIcon={
								updateFieldIdRef.current === -1 ? (
									<Spinner
										size="sm"
										ml="auto"
										color="gray.40"
										mx="auto"
									/>
								) : null
							}
							inputRightElementProps={{
								w: '32px',
								h: '32px',
								pr: 2,
								cursor: 'default',
							}}
							_placeholder={{
								color: 'gray.35',
								fontWeight: 500,
							}}
							_hover={{
								borderColor: 'blueLight.10',
							}}
							_focusVisible={{
								borderColor: isCreate
									? 'transparent'
									: 'blue.45',
								boxShadow: isCreate ? 'unset' : 'blue.light',
							}}
						/>
					</Box>
					<VStack
						borderTopWidth="1px"
						borderTopColor="gray.15"
						p="4px"
						w="full"
						align="flex-start"
						spacing="2px">
						{LabelDefaultColors.map(el => (
							<Button
								key={el.id}
								w="full"
								justifyContent="flex-start"
								variant="ghost"
								columnGap="8px"
								p="8px 6px 8px 4px"
								h="32px"
								borderRadius="4px"
								onClick={() => onChangeLabelColor(el.id)}
								bg={
									el.id === currentColourId
										? 'transparentLight.5'
										: 'transparent'
								}
								_hover={{
									bg:
										el.id === currentColourId
											? 'transparentLight.5'
											: '#00000005',
								}}>
								<Box
									boxSize="16px"
									bg={el.color}
									borderRadius="50%"
								/>
								<Text color="secondary">{el.name}</Text>
								{updateFieldIdRef.current === el.id ? (
									<Spinner size="sm" ml="auto" />
								) : null}
								{el.id === currentColourId ? (
									<Box ml="auto">
										<Icon
											name="check"
											width="16px"
											height="16px"
											color={colors.secondary}
										/>
									</Box>
								) : null}
							</Button>
						))}
					</VStack>
					<Button
						variant="ghost"
						justifyContent="flex-start"
						w="100%"
						p="10px 8px"
						h="40px"
						borderTopWidth="1px"
						borderTopColor="gray.15"
						borderRadius="0px"
						leftIcon={
							deleteLabelMutation.isPending ? (
								<Spinner size="sm" />
							) : (
								<Icon
									name="delete-red-bin"
									width="16px"
									height="16px"
									color={colors.red['DEFAULT']}
								/>
							)
						}
						iconSpacing={2}
						isDisabled={deleteLabelMutation.isPending}
						onClick={onDeleteLabel}
						_disabled={{
							opacity: 0.8,
							cursor: 'not-allowed',
						}}>
						<Text color="red.DEFAULT">Delete</Text>
					</Button>
				</PopoverContent>
			</Popover>
		);
	},
);
