import React, { useCallback } from 'react';
import { Box, Flex, Text, Button, Center, Image } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '../../../components';
import {
	modalRegularTextStyleProps,
	modalSubHeaderStyleProps,
} from '../steps/helper';
import { Alerter } from '../../../utils';

interface IUploadWorkspaceAvatarProps {
	title: string;
	imageFromFile: string;
	showImage: (file: File) => void;
	removeImage: VoidFunction;
	uploadLoading?: boolean;
	removeLoading?: boolean;
}

const maxFileSize = 10 * 1024 * 1024; // 10mb

export const UploadWorkspaceAvatar: React.FC<IUploadWorkspaceAvatarProps> = ({
	imageFromFile,
	showImage,
	removeImage,
	title,
	removeLoading,
	uploadLoading,
}) => {
	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			if (acceptedFiles.length && acceptedFiles[0].size > maxFileSize) {
				Alerter.error('Max file size should now exceed 10mb');
				return;
			}
			showImage(acceptedFiles[0]);
		},
		[showImage],
	);

	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		onDrop,
		multiple: false,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpeg', '.jpg'],
			'image/gif': ['.gif'],
		},
		maxFiles: 1,
	});

	return (
		<Box>
			<Text {...modalSubHeaderStyleProps}>{title}</Text>
			<Flex gap={'15px'} mt={'10px'}>
				{imageFromFile ? (
					<Image
						src={imageFromFile}
						alt={'logo'}
						width="70px"
						height="fit-content"
						flexShrink={0}
						objectFit={'contain'}
						borderRadius="4px"
					/>
				) : (
					<Center
						{...getRootProps()}
						boxSize="70px"
						flexShrink={0}
						borderRadius="4px"
						border={'none'}
						bg={isDragActive ? 'gray.25' : 'gray.15'}>
						<Icon name="image-upload" width="16" height="16" />
						<input {...getInputProps()} />
					</Center>
				)}
				<Box>
					<Flex gap={'10px'}>
						<Button
							onClick={open}
							variant="outline"
							isLoading={uploadLoading}>
							<Icon
								width={'14px'}
								height={'14px'}
								name={'upload'}
							/>
							<Text ml={'10px'} color={'inherit'}>
								Upload
							</Text>
						</Button>
						<Button
							variant={'outline'}
							isDisabled={!imageFromFile}
							onClick={removeImage}
							isLoading={removeLoading}>
							<Icon
								width={'14px'}
								height={'14px'}
								name={'delete'}
							/>
							<Text ml={'10px'} color={'inherit'}>
								Remove
							</Text>
						</Button>
					</Flex>
					<Text {...modalRegularTextStyleProps} mt={'10px'}>
						We support your best PNGs, JPEGs and GIFs portraits
						under 10MB
					</Text>
				</Box>
			</Flex>
		</Box>
	);
};
