import React from 'react';
import { Avatar, Box, FormLabel, HStack } from '@chakra-ui/react';
import { RadixCheckbox } from '../../../components';
import { useGetFullChatInfo } from '../../../hooks';
import { ApiChatType } from '../../../types';

interface ChatItemProps {
	name: string;
	id: string;
	onCheckedChange: (value: boolean) => void;
	accessHash?: string;
	isChecked: boolean;
	chatType?: ApiChatType;
}

export const ChatItem: React.FC<ChatItemProps> = ({
	name,
	accessHash,
	id,
	onCheckedChange,
	isChecked,
	chatType,
}) => {
	const avatar = useGetFullChatInfo({
		accessHash: accessHash,
		type: chatType,
		chatId: +id,
		title: name || '',
		isEnabled: !!id,
	});

	return (
		<HStack
			px={4}
			py={1.5}
			spacing={4}
			w="100%"
			borderBottomWidth="1px"
			borderBottomColor="gray.20"
			align="center"
			_last={{ borderBottom: 'none' }}>
			<Box w="16px" h="16px">
				<RadixCheckbox
					checked={isChecked}
					onCheckedChange={onCheckedChange}
					id={`check-${id}`}
				/>
			</Box>
			<Box>
				<FormLabel
					htmlFor={`check-${id}`}
					h="20px"
					w="100%"
					mb={0}
					alignItems="center"
					fontWeight={500}
					ml={1}
					noOfLines={1}
					fontSize="13px"
					cursor="pointer"
					userSelect="none">
					<Avatar
						name={name}
						src={avatar || ''}
						borderRadius="2px"
						width="14px"
						height="14px"
						minW="14px"
						mt="3px"
						mr={1}
						sx={{
							'> div': {
								fontSize: '8px',
							},
						}}
					/>
					{name}
				</FormLabel>
			</Box>
		</HStack>
	);
};
