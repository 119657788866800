export enum BoardFilterValue {
	All,
	Unread,
	Unanswered,
	TeamActivity,
	ActiveTasks,
	Mentions,
}

export type BoardTabsType = {
	id: number;
	label: string;
	iconName?: string;
	activity?: number;
	activityBgColor?: string;
	activityTextColor?: string;
	filterValue: BoardFilterValue;
};
