import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

export const handleAxiosError = (err: AxiosError) => {
	if (!err) {
		return {
			data: {
				success: false,
				errors: [
					{
						key: 'Unknown error',
						message: 'Unknown error occurred',
					},
				],
			},
		};
	}
	if (err.response) {
		return {
			data: {
				...(err.response.data ?? {}),
				status: err.response.status,
				success: false,
			},
		};
	}
	if (err.request) {
		const errorDetails = {
			success: false,
			errors: [
				{
					key: 'Request error',
					message: `Request error ${err.request.status}: ${err.message ?? err.toJSON()}`,
				},
			],
		};
		Sentry.captureException(err, {
			extra: {
				errorDetails,
			},
		});
		return { data: errorDetails };
	}
	const errorDetails = {
		success: false,
		errors: [
			{
				key: 'Request setup error',
				message: `Request setup error: ${err.message}`,
			},
		],
	};
	Sentry.captureException(err, {
		extra: {
			errorDetails,
		},
	});
	return { data: errorDetails };
};
