import { SuggestionsObj } from '../types';

export const countSuggestions = (
	suggestions: Record<string, SuggestionsObj>,
) => {
	const count = Object.values(suggestions).reduce(
		(acc, { results }) => acc + results.length,
		0,
	);
	return count;
};
