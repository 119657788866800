import React from 'react';
import { Skeleton, Text, VStack } from '@chakra-ui/react';

interface LimitedAccessViewProps {
	hasAccess: boolean;
	onClick?: () => void;
	isLoaded?: boolean;
}

export const LimitedAccessView: React.FC<LimitedAccessViewProps> = ({
	hasAccess,
	isLoaded,
	// onClick,
}) => {
	if (hasAccess) {
		return null;
	}
	if (!isLoaded) {
		return (
			<VStack w="full" pb={1.5} spacing={1}>
				<Skeleton
					isLoaded={isLoaded}
					w="full"
					h="20px"
					startColor="gray.40"
					endColor="gray.20"
				/>
				<Skeleton
					isLoaded={isLoaded}
					w="full"
					h="20px"
					startColor="gray.40"
					endColor="gray.20"
				/>
			</VStack>
		);
	}
	return (
		<VStack w="full" spacing={1} pb="10px">
			<Text>You&apos;re not in this chat</Text>
			{/* <Button
				variant="unstyled"
				h="20px"
				borderRadius="4px"
				bg="transparentLight.5"
				px={1}
				transition="background 0.3s ease"
				onClick={onClick}
				_hover={{
					bg: 'transparentLight.15',
				}}>
				<Text>Ask to add</Text>
			</Button> */}
		</VStack>
	);
};
