import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ApiResponse } from '../../../types';
import { BoardCardItemResponse } from '../types';
import { QueryKeys } from '../../../constants';
import { RootStore } from '../../../redux/createStore';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { useSupabaseWithAuth } from '../../../state/auth/context';

export const useGetBoardCards = (cardStatusId?: number) => {
	const workspaceId = useGetMatchedWorkspaceId();
	const supabase = useSupabaseWithAuth();
	const { isOnboarded, id: currentUserId } = useSelector(
		(state: RootStore) => state.auth.user,
	);

	return useQuery<ApiResponse<{ cards: BoardCardItemResponse[] }> | null>({
		queryKey: [QueryKeys.GET_BOARD_CARDS, workspaceId, cardStatusId],
		queryFn: async () => {
			const { data, error } = await supabase.rpc(
				'get_workspace_cards_with_unread_messaged_and_tagged_users',
				{
					p_workspace_id: workspaceId,
					p_card_status_id: cardStatusId,
					p_current_user_id: currentUserId,
				},
			);

			if (error) {
				throw error;
			}

			const cards: BoardCardItemResponse[] = data.map(item => ({
				cardId: item.card_id,
				cardName: item.card_name,
				chatTelegramId: item.chat_telegram_id,
				tasksCount: item.tasks_count,
				hasAccess: true,
				trackedLastTelegramMessageUnixTime: item.tracked_last_telegram_message_unix_time,
				status: {
					id: item.status_id,
					typeId: item.status_type_id,
					name: item.status_name,
				},
				message: item.message_text ? {
					text: item.message_text,
					senderId: item.message_sender_id,
					senderName: item.message_sender_name,
					telegramUserId: item.message_telegram_user_id,
					unreadCount: item.message_unread_count,
					workspaceCardActivityId: item.message_workspace_card_activity_id,
					activityTypeId: item.message_activity_type_id,
				} : null,
				currentUserTagged: item.current_user_tagged,
			}));

			return {
				success: true,
				status: 200,
				value: { cards },
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && isOnboarded,
		refetchInterval: 30 * 1000, // refetch data every 30s
	});
};
