import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';
import { ApiResponse, TgFoldersResponseItem } from '../types';
import { useSupabaseWithAuth } from '../state/auth/context';

export const useGetTgFolders = () => {
	const { isOnboarded, workSpace } = useSelector(
		(state: RootStore) => state.auth.user,
	);
	const supabase = useSupabaseWithAuth();

	return useQuery<ApiResponse<{ folders: TgFoldersResponseItem[] } | null> | null>({
		queryKey: [QueryKeys.GET_TG_FOLDERS, workSpace?.id],
		queryFn: async () => {
			if (!workSpace?.id) {
				return null;
			}
			const { data, error, status } = await supabase
				.from('user_folders')
				.select(
					`
                    telegram_folder_id,
					user_folders_workspace_cards (
						...cards (
							telegram_chat_id
						)
					)
                `,
				)
				.eq('workspace_id', workSpace?.id);

			if (error) {
				return {
					success: false,
					status,
					value: null,
					errors: [{ key: error.code, message: error.message }],
					messages: [],
				};
			}

			const folders: TgFoldersResponseItem[] = data.map(folder => ({
				telegramFolderId: folder.telegram_folder_id,
				telegramChatsIds: folder.user_folders_workspace_cards.map(
					card => card.telegram_chat_id,
				),
			}));

			return {
				success: true,
				status,
				value: { folders },
				errors: [],
				messages: [],
			};
		},
		refetchOnWindowFocus: false,
		enabled: isOnboarded && !!workSpace?.id,
	});
};
