import React from 'react';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import {
	Icon,
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
} from '../../../../../components';
import {
	useCreateCardActivity,
	useGetCardActivitiesOptions,
} from '../../../queries';
import { Alerter, getActivityIconByTypeId } from '../../../../../utils';
import { useMarkChatAsRead } from '../queries';

interface NewActionMenuProps {
	cardId?: number;
	workspaceId?: number;
}

export const NewActionMenu: React.FC<NewActionMenuProps> = ({
	workspaceId,
	cardId,
}) => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { data } = useGetCardActivitiesOptions(false, workspaceId, cardId);
	const { mutateAsync: createCardActivity } = useCreateCardActivity();
	const { mutateAsync: markAsReadMutation } = useMarkChatAsRead(
		workspaceId,
		cardId,
	);

	const { workspaceCardActivities } = data?.value || {};

	const onCreateCardActivity = async (
		workspaceCardActivityId: number,
		cardId?: number,
		workspaceId?: number,
	) => {
		try {
			if (!workspaceId) {
				return Alerter.error('Workspace is missing');
			}
			if (!cardId) {
				return Alerter.error('Card does not exist');
			}

			const res = await createCardActivity({
				cardId,
				workspaceCardActivityId,
				workspaceId,
			});

			if (!res.success) {
				return;
			}
			onClose();
			await markAsReadMutation();
		} catch (error) {
			console.log('onCreateCardActivity: ', error);
		}
	};

	return (
		<DropdownMenu open={isOpen} onOpenChange={onToggle}>
			<DropdownMenuTrigger asChild={true}>
				<IconButton
					variant="outline"
					aria-label="Actions menu"
					title="Actions menu"
					minW="auto"
					borderRadius="4px"
					bg={isOpen ? 'transparentLight.10' : undefined}
					boxSize="24px">
					<Icon
						name={isOpen ? 'minus' : 'plus'}
						height="16"
						width="16"
					/>
				</IconButton>
			</DropdownMenuTrigger>

			<DropdownMenuContent align="end">
				{workspaceCardActivities
					?.sort((a, b) => a.position - b.position)
					.map(activity => {
						const menuIconName = getActivityIconByTypeId(
							activity.typeId,
						);
						return (
							<DropdownMenuItem
								key={activity.id}
								onClick={() =>
									onCreateCardActivity(
										activity.id,
										cardId,
										workspaceId,
									)
								}>
								{menuIconName ? (
									<Icon
										name={menuIconName}
										height="16"
										width="16"
									/>
								) : null}
								{activity.description}
							</DropdownMenuItem>
						);
					})}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
