import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { WorkspaceItemImage } from '../../../components/MainLayout/components/WorkspaceSelect/WorkspaceItemImage';
import { useSetWorkspace } from '../../../queries';

interface IWorkspaceItemProps {
	name: string;
	imageLink?: string;
	id: number;
}

export const WorkspaceItem: React.FC<IWorkspaceItemProps> = ({
	name,
	imageLink,
	id,
}) => {
	const setWorkspaceMutation = useSetWorkspace();

	return (
		<Flex
			p={'20px'}
			as="button"
			w={'100%'}
			gap="8px"
			disabled={setWorkspaceMutation.isPending}
			align="center"
			borderBottom={'1px solid'}
			onClick={() => setWorkspaceMutation.mutate(id)}
			borderColor="gray.15"
			_last={{ borderBottom: 'none' }}
			_hover={{ bg: 'gray.15' }}>
			<WorkspaceItemImage imageLink={imageLink} />
			<Text
				fontSize="13px"
				color="secondary"
				fontWeight={400}
				lineHeight="19.5px"
				isTruncated={true}>
				{name}
			</Text>
		</Flex>
	);
};
