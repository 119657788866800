export const tgChatTypes = [
	'chatTypeChannel',
	'chatTypeSuperGroup',
	'chatTypeBasicGroup',
];

export const allowedForSyncChatTypes = [
	'chatTypePrivate',
	'chatTypeSuperGroup',
	'chatTypeBasicGroup',
];
