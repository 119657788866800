import React, {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { IconButton, Skeleton, VStack } from '@chakra-ui/react';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	InputText,
	Icon,
} from '../../../../../../components';
import { useCreateLabel } from '../../queries';
import { EditLabelPopup } from '../EditLabelPopup/EditLabelPopup';
import { LabelPopupItem } from './components';

import styles from './LabelsPopup.module.css';
import { useGetWorkspaceLabels } from '../../../../../../queries';
import { useUpdateLabelsStateOnCreate } from '../../hooks';

interface LabelsPopupProps {
	isOpen: boolean;
	workspaceId: number;
	cardId: number;
	onClose: VoidFunction;
	children: ReactElement;
	onToggle?: VoidFunction;
	labelEditPopupRef: React.MutableRefObject<HTMLDivElement | null>;
	cardLabelIds: number[];
}

export const LabelsPopup = React.forwardRef<
	HTMLDivElement | null,
	LabelsPopupProps
>(
	(
		{
			workspaceId,
			isOpen,
			children,
			labelEditPopupRef,
			cardId,
			cardLabelIds,
		},
		ref,
	) => {
		const { data: labelsData, isLoading } =
			useGetWorkspaceLabels(workspaceId);
		const createLabelMutation = useCreateLabel();
		const { updateLabelsStateOnCreate } = useUpdateLabelsStateOnCreate();

		const [name, setName] = useState('');
		const [newLabelId, setNewLabelId] = useState(0);

		const onAddLabel = () =>
			createLabelMutation.mutate(
				{ workspaceId, name: name.trim(), colourId: 1 },
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						setNewLabelId(data.value);
					},
				},
			);

		useEffect(() => {
			if (!isOpen) {
				setName('');
				setNewLabelId(0);
			}
		}, [isOpen]);

		const isLabelAlreadyExist = useMemo(
			() =>
				!!labelsData?.value?.workspaceLabels?.find(
					el => el.name.trim() === name.trim(),
				),
			[name, labelsData?.value?.workspaceLabels],
		);

		const filteredLabelItems = useMemo(
			() =>
				labelsData?.value?.workspaceLabels?.filter(
					el => !cardLabelIds.includes(el.workspaceLabelId),
				) || [],
			[labelsData?.value?.workspaceLabels, cardLabelIds],
		);

		const onToggleLabelColorPickerPopup = useCallback(() => {
			if (!newLabelId) {
				return;
			}

			updateLabelsStateOnCreate({
				workspaceId,
				name,
				colourId: 1,
				workspaceLabelId: newLabelId,
			});

			setNewLabelId(0);
			setName('');
		}, [name, workspaceId, newLabelId, updateLabelsStateOnCreate]);

		if (isLoading) {
			return (
				<Skeleton
					isLoaded={false}
					w="full"
					h="32px"
					startColor="gray.40"
					endColor="gray.20"
				/>
			);
		}

		return (
			<Popover open={isOpen}>
				<PopoverTrigger asChild={true}>{children}</PopoverTrigger>
				<PopoverContent
					ref={ref}
					sideOffset={10}
					align="start"
					side="bottom"
					className={styles.LabelsPopoverContent}>
					<InputText
						placeholder="+ Add label"
						borderRadius="unset"
						value={name}
						onChange={ev => setName(ev.target.value)}
						fontWeight={400}
						h="36px"
						boxShadow="unset"
						borderWidth="0px"
						bg="transparent"
						px="8px"
						borderColor="transparent"
						isDisabled={createLabelMutation.isPending}
						rightIcon={
							<EditLabelPopup
								name={name}
								colourId={1}
								isCreate={true}
								workspaceLabelId={newLabelId}
								align="start"
								side="right"
								sideOffset={15}
								alignOffset={-7}
								ref={labelEditPopupRef}
								isOpen={!!newLabelId}
								onToggle={onToggleLabelColorPickerPopup}
								onClose={() => {
									setNewLabelId(0);
									setName('');
								}}>
								<IconButton
									aria-label="add-label"
									variant="outline"
									title={
										isLabelAlreadyExist
											? 'Label with that name already exist'
											: ''
									}
									minW="24px"
									w="24px"
									h="24px"
									borderRadius="4px"
									isLoading={createLabelMutation.isPending}
									onClick={onAddLabel}
									isDisabled={isLabelAlreadyExist}>
									<Icon
										name="plus"
										width="16px"
										height="16px"
									/>
								</IconButton>
							</EditLabelPopup>
						}
						inputRightElementProps={{
							visibility: name ? 'visible' : 'hidden',
							opacity: name ? 1 : 0,
							transition:
								'0.2s opacity ease, 0.2s visibility ease',
						}}
						_placeholder={{
							color: 'gray.35',
							fontWeight: 400,
						}}
						_hover={{
							borderColor: 'transparent',
						}}
						_focusVisible={{
							borderColor: 'transparent',
						}}
					/>
					{filteredLabelItems?.length ? (
						<VStack
							borderTopWidth="1px"
							borderTopColor="gray.15"
							p="4px"
							w="full"
							align="flex-start"
							spacing="2px"
							maxH="300px"
							overflow="auto">
							{filteredLabelItems.map(el => {
								return (
									<LabelPopupItem
										key={el.workspaceLabelId}
										labelEditPopupRef={labelEditPopupRef}
										workspaceId={workspaceId}
										cardId={cardId}
										{...el}
									/>
								);
							})}
						</VStack>
					) : null}
				</PopoverContent>
			</Popover>
		);
	},
);
