import React from 'react';
import { Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import parse from 'html-react-parser';
import { Template as TemplateType } from '../../../../../types';
import { ActionsMenu, Icon } from '../../../../../components';
import { TemplatePopup } from './TemplatePopup';
import { useDeleteTemplate } from '../queries';

interface ITemplateProps extends TemplateType {
	onContentClick?: VoidFunction;
}

export const Template: React.FC<ITemplateProps> = ({
	content,
	id,
	onContentClick,
}) => {
	const {
		onToggle: onToggleTemplatePopup,
		isOpen: isTemplatePopupOpen,
		onClose: onCloseTemplatePopup,
	} = useDisclosure();
	const deleteTemplateMutation = useDeleteTemplate();

	const onDeleteTemplate = () =>
		deleteTemplateMutation.mutate(id, {
			onSuccess: data => {
				if (data?.success && isTemplatePopupOpen) {
					onCloseTemplatePopup();
				}
			},
		});

	return (
		<Flex
			px={4}
			py={3}
			alignItems="center"
			justifyContent="space-between"
			_last={{ borderBottomWidth: 0 }}
			borderBottomWidth="1px"
			borderBottomColor="gray.15"
			position="relative"
			overflow="hidden"
			_hover={{
				'.actions-menu': {
					opacity: 1,
					visibility: 'visible',
					transform: 'translateY(-50%) translateX(0)',
				},
			}}>
			<Text
				color="primary"
				fontSize="13px"
				fontWeight="500"
				textAlign="left"
				as="button"
				onClick={onContentClick}
				wordBreak="break-word"
				sx={{
					a: {
						textDecoration: 'underline',
					},
				}}>
				{parse(content)}
			</Text>
			<ActionsMenu
				isOpen={isTemplatePopupOpen}
				includeDelete={true}
				deleteButtonProps={{
					onClick: onDeleteTemplate,
					isLoading: deleteTemplateMutation.isPending,
				}}>
				<TemplatePopup
					isOpen={isTemplatePopupOpen}
					onClose={onCloseTemplatePopup}
					onToggle={onToggleTemplatePopup}
					template={content}
					templateId={id}>
					<IconButton
						aria-label="edit template"
						minW="auto"
						variant="ghost"
						boxSize="24px"
						borderRadius="4px"
						onClick={onToggleTemplatePopup}>
						<Icon name="edit" height="16" width="16" />
					</IconButton>
				</TemplatePopup>
			</ActionsMenu>
		</Flex>
	);
};
