export const TABS = [
	{
		label: 'Inbox',
		iconName: 'inbox',
	},
	{
		label: 'Archived',
		iconName: 'archive',
	},
];
