import React, { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { ConfirmDeleteModal } from './ConfirmDelete.modal';

interface IBlockerDialog {
	isDirty: boolean;
}

export const BlockerDialog: React.FC<IBlockerDialog> = ({ isDirty }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();

	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			isDirty && currentLocation.pathname !== nextLocation.pathname,
	);

	const onCloseAction = useCallback(() => {
		if (blocker.state === 'blocked') {
			blocker.reset();
			onClose();
		}
	}, [blocker]);

	const onConfirm = useCallback(() => {
		if (blocker.state === 'blocked') {
			blocker.proceed();
		}
	}, [blocker]);

	useEffect(() => {
		if (blocker.state === 'blocked') {
			onOpen();
		}
	}, [blocker]);

	return (
		<ConfirmDeleteModal
			title="You have unsaved changes"
			isOpen={isOpen}
			onClose={onCloseAction}
			onConfirm={onConfirm}
			confirmLabel="Leave this page"
			confirmLoading={false}
			description="Are you sure you want to leave this page?"
			cancelLabel="Continue editing"
		/>
	);
};
