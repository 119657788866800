import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoardCardItemResponse } from '../../pages/Dashboard/types';
import { logout } from './auth.reducer';

type InitialState = {
	items: BoardCardItemResponse[];
};

const initialState: InitialState = {
	items: [],
};

const searchRecentStack = createSlice({
	name: 'searchRecentStack',
	initialState,
	reducers: {
		addRecentEntry: (
			state,
			action: PayloadAction<BoardCardItemResponse>,
		) => {
			const possibleInsertedValue = JSON.parse(
				JSON.stringify(state.items),
			).find(
				(elem: BoardCardItemResponse) =>
					elem.cardId === action.payload.cardId,
			);
			if (!possibleInsertedValue) {
				state.items.unshift(action.payload);
			} else {
				const index = state.items.findIndex(
					obj => obj.cardId === action.payload.cardId,
				);
				if (index > -1) {
					const [hoistedObject] = state.items.splice(index, 1);

					state.items.unshift(hoistedObject);
				}
			}

			if (state.items.length > 5) {
				state.items.pop();
			}
		},
		resetRecentStack: () => {
			return initialState;
		},
	},
	extraReducers(builder) {
		builder.addCase(logout, state => {
			state.items = [];
		});
	},
});

export const { addRecentEntry, resetRecentStack } = searchRecentStack.actions;

export default searchRecentStack.reducer;
