import React, { useMemo, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useSendMessage } from '../../queries';
import { RootStore } from '../../../../../../redux/createStore';
import { ApiResponse, BaseUserData } from '../../../../../../types';
import { QueryKeys } from '../../../../../../constants';
import { Mention, MentionsInput } from '../../../../../../components';
import {
	extractMarkedMentions,
	removeMentionsMarkup,
	extractUnmarkedMentions,
} from '../../../../utils';

interface ITextareaProps {
	cardId: number;
	workspaceId?: number;
}

export const Textarea: React.FC<ITextareaProps> = ({ cardId, workspaceId }) => {
	const queryClient = useQueryClient();
	const { state } = useLocation();

	const { chatTelegramId } = state || {};

	const sendMessageMutation = useSendMessage(workspaceId, cardId);

	const storeAvatars = useSelector((state: RootStore) => state.avatars);
	const telegramUserId = useSelector(
		(state: RootStore) => state.auth.user.telegramUserId,
	);
	const cachedData = useSelector(
		(state: RootStore) => state.telegramState.chats[chatTelegramId || 0],
	);
	const cachedUsers = useSelector(
		(state: RootStore) => state.telegramState.users,
	);

	const [text, setText] = useState('');
	const textareaRef = useRef<HTMLTextAreaElement | null>(null);

	const allTeamMembersData = queryClient.getQueryData<
		ApiResponse<{ members: BaseUserData[] }>
	>([QueryKeys.GET_WORKSPACE_MEMBERS, workspaceId]);

	const chatTeamMembers = useMemo(() => {
		if (cachedData?.type === 'chatTypePrivate') {
			return [];
		}

		const allTeamMembers = allTeamMembersData?.value?.members || [];
		const teamMembers = new Map(
			allTeamMembers.map(obj => [obj.telegramUserId, obj]),
		);

		return allTeamMembers
			.filter(
				teamMember =>
					teamMembers.has(teamMember.telegramUserId) &&
					teamMember.telegramUserId !== telegramUserId,
			)
			.map(teamMember => ({
				id: teamMember.telegramUserId || 0,
				username:
					teamMember.telegramUserId &&
					cachedUsers?.[teamMember.telegramUserId]
						? cachedUsers?.[teamMember.telegramUserId]?.username
						: null,
				avatar: storeAvatars[`${teamMember.telegramUserId}`],
				name: `${teamMember.firstName}${teamMember.lastName ? ` ${teamMember.lastName}` : ''}`,
			}));
	}, [
		cachedData,
		storeAvatars,
		cachedUsers,
		telegramUserId,
		allTeamMembersData?.success,
	]);

	const onSend = () => {
		if (text.trim() === '') {
			return;
		}

		const plainText = removeMentionsMarkup(text);
		const markedMentions = extractMarkedMentions(text);
		const unmarkedMentions = extractUnmarkedMentions(text, chatTeamMembers);

		sendMessageMutation.mutate(
			{
				text: plainText,
				taggedUsers: [...markedMentions, ...unmarkedMentions],
			},
			{
				onSuccess(data) {
					if (data.success) {
						setText('');
					}
				},
			},
		);
	};

	const handleEnterInput = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (ev.key === 'Enter' && !ev.shiftKey) {
			ev.preventDefault();
			ev.stopPropagation();

			onSend();
			return;
		}
	};

	const queryMentionUsers = (
		q: string,
		callback: (val: unknown[]) => void,
	) => {
		const matchedSuggestions = chatTeamMembers
			.filter(item => {
				const isUsernameMatched =
					item.username &&
					item.username.toLowerCase().startsWith(q.toLowerCase());
				const isNameMatched = item.name
					.toLowerCase()
					.startsWith(q.toLowerCase());

				return isUsernameMatched || isNameMatched;
			})
			.map(user => ({
				name: user.name,
				id: user.id,
				photoURL: user.avatar || '',
				username: user.username,
			}));

		callback(matchedSuggestions);
	};
	const onWrapperClick = () => {
		if (textareaRef.current) {
			textareaRef.current.focus();
		}
	};

	return (
		<Flex align="center" w="100%" onClick={onWrapperClick}>
			<MentionsInput
				inputRef={textareaRef}
				value={text}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setText(e.target.value)
				}
				forceSuggestionsAboveCursor={true}
				placeholder="Type a message to the team..."
				onKeyDown={handleEnterInput}>
				<Mention
					displayTransform={(
						_id: string,
						name: string,
						username: string | null,
					) => (username ? `@${username}` : `${name}`)}
					trigger="@"
					data={(q: string, callback: (val: unknown[]) => void) =>
						queryMentionUsers(q, callback)
					}
					appendSpaceOnAdd={true}
				/>
			</MentionsInput>
		</Flex>
	);
};
