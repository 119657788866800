import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import {
	ApiResponse,
	ApiResponseEmpty,
	LabelItemType,
	DeleteLabelPayload,
} from '../../../../../types';
import { Alerter } from '../../../../../utils';
import { QueryKeys } from '../../../../../constants';

export const useDeleteLabel = () => {
	const queryClient = useQueryClient();

	return useMutation<ApiResponseEmpty, unknown, DeleteLabelPayload>({
		mutationKey: [QueryKeys.DELETE_LABEL],
		mutationFn: payload => {
			const { workspaceId, workspaceLabelId } = payload;

			return API.delete(
				`/api/workspaces/${workspaceId}/labels/${workspaceLabelId}`,
				{},
			);
		},
		onSuccess: (data, variables) => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}

			Alerter.success(
				data.messages?.[0]?.message ||
					'Label was successfully deleted!',
			);

			const { workspaceId, workspaceLabelId } = variables;

			queryClient.setQueryData<
				ApiResponse<{ workspaceLabels: LabelItemType[] }> | undefined
			>([QueryKeys.GET_WORKSPACE_LABELS, workspaceId], oldData => {
				if (!oldData) {
					return oldData;
				}

				return {
					...oldData,
					value: {
						workspaceLabels: oldData.value?.workspaceLabels?.filter(
							label =>
								label.workspaceLabelId !== workspaceLabelId,
						),
					},
				};
			});
		},
	});
};
