import React from 'react';
import { Divider, HStack, Text } from '@chakra-ui/react';

interface MonthDividerProps {
	title: string;
}

export const MonthDivider: React.FC<MonthDividerProps> = ({ title }) => {
	return (
		<HStack align="center" spacing={4}>
			<Text
				color="gray.35"
				textTransform="uppercase"
				fontWeight="600"
				fontSize="11px"
				flexShrink={0}>
				{title}
			</Text>
			<Divider />
		</HStack>
	);
};
