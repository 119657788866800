import { iterateMentionsMarkup } from './iterateMentionsMarkup';
import { ConfigFromChildren } from '../types';

// For a given indexInPlainText that lies inside a mention,
// returns the index of the first char of the mention in the plain text.
// If indexInPlainText does not lie inside a mention, returns indexInPlainText.
export const findStartOfMentionInPlainText = (
	value: string,
	config: ConfigFromChildren[],
	indexInPlainText: number,
) => {
	let result = indexInPlainText;
	let foundMention = false;

	const markupIteratee = (
		markup: string,
		index: number,
		mentionPlainTextIndex: number,
		id: string,
		display: string,
		childIndex: number,
		lastMentionEndIndex: number,
	) => {
		if (
			mentionPlainTextIndex <= indexInPlainText &&
			mentionPlainTextIndex + display.length > indexInPlainText
		) {
			result = mentionPlainTextIndex;
			foundMention = true;
		}
	};
	iterateMentionsMarkup(value, config, markupIteratee);

	if (foundMention) {
		return result;
	}
};
