import { iterateMentionsMarkup } from './iterateMentionsMarkup';
import { ConfigFromChildren, InnerMention } from '../types';

export const getMentions = (value: string, config: ConfigFromChildren[]) => {
	const mentions: InnerMention[] = [];
	iterateMentionsMarkup(
		value,
		config,
		(match, index, plainTextIndex, id, display, childIndex, start) => {
			mentions.push({
				id: id,
				display: display,
				childIndex: childIndex,
				index: index,
				plainTextIndex: plainTextIndex,
			});
		},
	);
	return mentions;
};
