import React, { useMemo } from 'react';
import {
	Box,
	GridItem,
	GridItemProps,
	HStack,
	IconButton,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CustomTag, Icon, TaskCardSolo } from '../../../components';
import { BoardCardItemResponse, BoardFilterValue } from '../types';
import {
	getColumnDragBackgroundColor,
	sortCardsByDateAndAccess,
	getTaskStatusByType,
} from '../../../utils';
import { CardStatusItem, TgDataForCard } from '../../../types';
import {
	setCardsFilterId,
	setCardsStatusId,
} from '../../../redux/reducers/page-navigation-state.reducer';
import { addRecentEntry } from '../../../redux/reducers/search-recent-stack.reducer';
import { useGetWorkspaceId } from '../../../hooks';

interface KanbanBoardColumnProps {
	tasks: BoardCardItemResponse[];
	gridProps?: GridItemProps;
	tabIndex: BoardFilterValue;
	columnStatus: CardStatusItem;
	tgChatsData: Record<string, TgDataForCard>;
	enableColumnIcon?: boolean;
	isColumnIconActive?: boolean;
	onClickColumnIcon?: VoidFunction;
	teamTgIds: number[];
}

export const KanbanBoardColumn: React.FC<KanbanBoardColumnProps> = ({
	tasks,
	gridProps,
	tabIndex,
	columnStatus,
	tgChatsData,
	enableColumnIcon,
	isColumnIconActive,
	onClickColumnIcon,
	teamTgIds,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const workspaceId = useGetWorkspaceId();
	const {
		name: statusName,
		id: statusId,
		typeId: statusSystemDefaultId,
		position,
	} = columnStatus;

	const dataByColumnType = useMemo(
		() => getTaskStatusByType({ position, typeId: statusSystemDefaultId }),
		[position, statusSystemDefaultId],
	);

	const onRedirect = (obj: {
		cardData: BoardCardItemResponse;
		statusId?: number;
		filterId?: number;
	}) => {
		navigate(`/${workspaceId}/chat/${obj.cardData.cardId}`, {
			state: {
				statusId: obj.statusId,
				filterId: obj.filterId,
				chatTelegramId: obj.cardData.chatTelegramId,
			},
		});
		dispatch(setCardsStatusId(obj.statusId || 0));
		dispatch(setCardsFilterId(obj.filterId || 1));
		dispatch(addRecentEntry(obj.cardData));
	};

	return (
		<GridItem
			p={2}
			borderRightWidth="1px"
			borderRightColor="gray.15"
			height="100%"
			{...gridProps}>
			<VStack align="flex-start" w="100%" spacing={1.5} height="100%">
				<HStack justify="space-between" spacing={2} w="full">
					<HStack spacing={2}>
						<CustomTag
							label={statusName}
							bgColor={dataByColumnType.bgColor}
							labelColor={dataByColumnType.textColor}
						/>
						{tasks.length ? <Text>{tasks.length}</Text> : null}
					</HStack>
					{enableColumnIcon ? (
						<IconButton
							aria-label={
								isColumnIconActive
									? 'hide column cards'
									: 'show column cards'
							}
							minW="auto"
							boxSize="24px"
							variant="outline"
							borderRadius="4px"
							bg="transparent"
							onClick={onClickColumnIcon}>
							<Icon
								name={isColumnIconActive ? 'eye-off ' : 'eye'}
								height="16"
								width="16"
							/>
						</IconButton>
					) : null}
				</HStack>
				<Box w="100%" h="100%">
					<Droppable droppableId={`${statusId}`} type="tasks">
						{(provided, snapshot) => (
							<Box
								w="100%"
								h="100%"
								ref={provided.innerRef}
								transition="background .3s"
								bg={getColumnDragBackgroundColor(
									snapshot.isDraggingOver,
									Boolean(snapshot.draggingFromThisWith),
								)}
								{...provided.droppableProps}>
								{tasks
									?.sort((cardA, cardB) =>
										sortCardsByDateAndAccess(
											cardA,
											cardB,
											tgChatsData,
										),
									)
									?.map((task, index) => {
										if (
											enableColumnIcon &&
											isColumnIconActive
										) {
											return null;
										}

										return (
											<Draggable
												key={task.cardId}
												draggableId={`${task.cardId}`}
												index={index}>
												{(provided, snapshot) => (
													<Box
														ref={provided.innerRef}
														mb={1.5}
														p={0.5}
														{...provided.draggableProps}
														{...provided.dragHandleProps}>
														<TaskCardSolo
															isDragging={
																snapshot.isDragging
															}
															cardName={
																task.cardName
															}
															chatTelegramId={
																task.chatTelegramId
															}
															onRedirect={() =>
																onRedirect({
																	cardData:
																		task,
																	statusId:
																		statusId,
																	filterId:
																		tabIndex +
																		1,
																})
															}
															companyChatData={
																task.message
															}
															currentUserTagged={
																task.currentUserTagged
															}
															trackedLastTelegramMessageUnixTime={
																task.trackedLastTelegramMessageUnixTime
															}
															cardId={task.cardId}
															cardStatusId={
																task.status
																	.typeId
															}
															teamTgIds={
																teamTgIds
															}
															labels={task.labels}
														/>
													</Box>
												)}
											</Draggable>
										);
									})}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</Box>
			</VStack>
		</GridItem>
	);
};
