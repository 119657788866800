import { useQuery } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import { ApiResponse } from '../../../../../types';
import { CardReminderItem } from '../../../types';
import { QueryKeys } from '../../../../../constants';

export const useGetCardReminders = (workspaceId?: number, cardId?: number) => {
	return useQuery<ApiResponse<{ reminders: CardReminderItem[] }>>({
		queryKey: [QueryKeys.GET_CARD_REMINDERS, cardId, workspaceId],
		queryFn: () =>
			API.get(
				`/api/workspaces/${workspaceId}/cards/${cardId}/reminders`,
				{},
			),
		refetchOnWindowFocus: false,
		enabled: !!workspaceId && !!cardId,
	});
};
