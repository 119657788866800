import React from 'react';
import { VStack, Text, HStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { SidebarNavItem } from './SidebarNavItem';
import { LogoutButton } from './LogoutButton';
import { getSettingsNavItems } from '../constants';
import { Icon } from '../../Icon/Icon';
import { useGetMatchedWorkspaceId } from '../../../hooks';

export const SidebarSettingsView: React.FC = () => {
	const workspaceId = useGetMatchedWorkspaceId();

	return (
		<>
			<RouterLink to={`/${workspaceId}`}>
				<HStack spacing="4px" mb="32px" mt="29px">
					<Icon name="chevron-left" width="24px" height="24px" />
					<Text color="secondary" fontSize="16px" fontWeight="600">
						Settings
					</Text>
				</HStack>
			</RouterLink>
			<Text
				textTransform="uppercase"
				color="gray.35"
				fontSize="11px"
				fontWeight="600px"
				mb="8px">
				Workspace
			</Text>
			<VStack
				spacing={{ base: 3, md: '2px' }}
				mx="auto"
				align="stretch"
				mb="32px"
				w={{ base: '300px', md: 'full' }}>
				{getSettingsNavItems(workspaceId).map(elem => (
					<SidebarNavItem
						key={elem.id}
						name={elem.name}
						path={elem.path}
						iconName={elem.iconName}
						location={location.pathname}
						isSoon={elem.isSoon}
						isActive={elem.path === location.pathname}
					/>
				))}
			</VStack>
			<Text
				textTransform="uppercase"
				color="gray.35"
				fontSize="11px"
				fontWeight="600px"
				mb="8px">
				Other
			</Text>
			<SidebarNavItem
				name="Send Feedback"
				path="https://t.me/acecreamu"
				iconName="envelope"
				location={location.pathname}
				isSoon={false}
				isActive={false}
			/>
			<LogoutButton />
		</>
	);
};
