import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TgDataForCard, TgUserData } from '../../types';
import { logout } from './auth.reducer';

type TgLocalChatState = {
	// chat: ApiChat | undefined;
	// id: number;
	// chatFullInfo: ApiChatFullInfo | undefined;
	// msg: ApiMessage | undefined;
	// lastMessageUserInfo: ApiUser | undefined;
	// userFullInfo: ApiUserFullInfo | undefined;
	// lastMessageId?: number | null;
	// lastMessageDate?: number;
};

type InitialState = {
	isSynced: boolean;
	chats: Record<string, TgDataForCard>;
	users: Record<string, TgUserData>;
};

const initialState: InitialState = {
	isSynced: false,
	chats: {},
	users: {},
};

const telegramStateReducer = createSlice({
	name: 'telegramState',
	initialState,
	reducers: {
		setTelegramSyncState: (state, action: PayloadAction<boolean>) => {
			state.isSynced = action.payload;
		},
		updateTelegramChatState: (
			state,
			action: PayloadAction<{
				id: number;
				data: Partial<TgLocalChatState>;
			}>,
		) => {
			const { id, data } = action.payload;

			if (state.chats[id]) {
				state.chats = {
					...state.chats,
					[id]: {
						...state.chats[id],
						...data,
					},
				};
			} else {
				state.chats = { ...state.chats, [id]: data };
			}
		},
		updateTelegramUserState: (
			state,
			action: PayloadAction<{
				id: number;
				data: Partial<TgUserData>;
			}>,
		) => {
			const { id, data } = action.payload;
			if (state.users[id]) {
				state.users = {
					...state.users,
					[id]: {
						...state.users[id],
						...data,
					},
				};
			} else {
				state.users = { ...state.users, [id]: data };
			}
		},
		resetChatsIsRefetchDisabledState: state => {
			Object.keys(state.chats).forEach(key => {
				state.chats[key].isRefetchDisabled = false;
			});
		},
		clearTgDataCache: state => {
			state.chats = {};
			state.users = {};
		},
	},
	extraReducers(builder) {
		builder.addCase(logout, state => {
			state.chats = {};
			state.users = {};
		});
	},
});

export const {
	setTelegramSyncState,
	updateTelegramChatState,
	clearTgDataCache,
	updateTelegramUserState,
	resetChatsIsRefetchDisabledState,
} = telegramStateReducer.actions;

export default telegramStateReducer.reducer;
