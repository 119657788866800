import { useQuery } from '@tanstack/react-query';
import { ApiResponse, WorkspaceInfoResponse } from '../../../types';
import { QueryKeys } from '../../../constants';
import API from '../../../api/api.base';
import { useGetWorkspaceId } from '../../../hooks';

export const useGetWorkspaceInfo = () => {
	const workspaceId = useGetWorkspaceId();

	return useQuery<ApiResponse<WorkspaceInfoResponse>>({
		queryKey: [QueryKeys.GET_WORKSPACE_INFO, workspaceId],
		queryFn: () =>
			API.get(`/api/settings/workspaces/${workspaceId}/info`, {}),
		refetchOnWindowFocus: false,
	});
};
