import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { setInviteMembersModalOpen } from '../../redux/reducers/onboarding.reducer';

export const InviteMembersButton: React.FC = () => {
	const dispatch = useDispatch();
	return (
		<IconButton
			aria-label="Add members to team"
			size="sm"
			minW="auto"
			height="24px"
			ml="4px"
			width="24px"
			borderRadius="4px"
			variant="ghost"
			onClick={() => dispatch(setInviteMembersModalOpen(true))}>
			<Icon name="plus" height="14" width="14" />
		</IconButton>
	);
};
