import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { browserRouter } from './browserRouter'

export const AppRoutes: React.FC = () =>
{
	return (
		<RouterProvider
			router={browserRouter()}
		/>
	)
}
