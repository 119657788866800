import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants';
import { ApiResponse, CurrentUser, Tables, TeamChatData, UserWorkspaceData } from '../types';
import { useAuthContext, useSupabaseWithAuth } from '../state/auth/context';

export const useGetCurrentUser = (
	isEnabled: boolean,
) => {
	const supabase = useSupabaseWithAuth();
	const { state } = useAuthContext();

	return useQuery<ApiResponse<CurrentUser | null>>({
		queryKey: [QueryKeys.GET_CURRENT_USER],
		queryFn: async () => {
			if (state.type !== 'authed') {
				return {
					success: false,
					status: 400,
					value: null,
					errors: [],
					messages: [],
				} as ApiResponse<CurrentUser | null>;
			}
			type WorkspaceWithAvatars = Tables<'workspaces'> & {
				avatar_id: { storage_path: string } | null;
				thumbnail_id: { storage_path: string } | null;
			};
			type UserWorkspaceWithAvatarsAndTeamChat =
				Tables<'workspace_users'> & {
					workspace: WorkspaceWithAvatars;
				};
			type UserWithWorkspacesAndAvatars = Tables<'users'> & {
				workspaces: UserWorkspaceWithAvatarsAndTeamChat[];
				avatar: { storage_path: string } | null;
				thumbnail: { storage_path: string } | null;
			};
			// TODO: rewrite this to a Supabase RPC view function (Postgres view function) 
			// or use Drizzle/Prisma ORM
			const { data, error, status } = await supabase
				.from('users')
				.select(
					`
				id,
				first_name,
				last_name,
				telegram_user_id,
				is_onboarded,
				time_zone_id,
				avatar:avatar_id(storage_path),
				thumbnail:thumbnail_id(storage_path),
				workspaces:workspace_users!inner(
					is_current,
					is_removed,
					workspace:workspaces(
						id,
						name,
						is_archive_visible,
						avatar_id(storage_path),
						thumbnail_id(storage_path),
						owner_id
					)
				)
			`,
			)
				// TODO: use user_id instead of telegram_user_id from AuthContext
				// or make sure telegram_user_id is unique for each User in Supabase
				// right now user_id is not available in AuthContext
				.eq('telegram_user_id', state.tgid)
				.maybeSingle<UserWithWorkspacesAndAvatars>();

			if (error) {
				return {
					success: false,
					status: status,
					value: null,
					errors: [{ key: error.code, message: error.message }],
					messages: [],
				} as ApiResponse<CurrentUser | null>;
			}
			if (!data) {
				return {
					success: false,
					status: status,
					value: null,
					errors: [],
					messages: [],
				} as ApiResponse<CurrentUser | null>;
			}
			const currentWorkspace = data.workspaces.filter(
				workspace => workspace.is_current && !workspace.is_removed,
			)?.[0]?.workspace;

			let teamChat: TeamChatData | null = null;
			if (currentWorkspace) {
				const { data: teamChatData, error: teamChatError } =
					await supabase
						.from('workspace_cards')
						.select('*')
						.eq('workspace_team_chat_id', currentWorkspace?.id)
						.returns<TeamChatData[]>();

				if (teamChatError) {
					throw teamChatError;
				}
				teamChat = teamChatData[0];
			}

			const workspaceData = currentWorkspace
				? ({
						id: currentWorkspace.id,
						name: currentWorkspace.name,
						avatarUrl:
							currentWorkspace.avatar_id?.storage_path ?? null,
						avatarThumbnailUrl:
							currentWorkspace.thumbnail_id?.storage_path ?? null,
						teamChat: teamChat,
						isArchiveVisible: currentWorkspace.is_archive_visible,
						isAdmin: currentWorkspace.owner_id === data.id,
					} as UserWorkspaceData)
				: undefined;

			const currentUser = {
				id: data.id,
				firstName: data.first_name,
				lastName: data.last_name,
				avatarUrl: data.avatar?.storage_path,
				avatarThumbnailLink: data.thumbnail?.storage_path,
				telegramUserId: data.telegram_user_id,
				isOnboarded: data.is_onboarded,
				timeZoneId: data.time_zone_id,
				workSpace: workspaceData,
			} as CurrentUser;

			return {
				success: true,
				status: status,
				value: currentUser,
				errors: [],
				messages: [],
			} as ApiResponse<CurrentUser | null>;
		},
		refetchOnWindowFocus: false,
		enabled: isEnabled && state.type === 'authed',
	});
};
