/* eslint-disable indent */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../../../api/api.base';
import {
	ApiResponse,
	ApiResponseEmpty,
	LabelItemType,
	UpdateLabelPayload,
} from '../../../../../types';
import { Alerter } from '../../../../../utils';
import { QueryKeys } from '../../../../../constants';

export const useUpdateLabel = (isAfterCreate: boolean) => {
	const queryClient = useQueryClient();

	return useMutation<ApiResponseEmpty, unknown, UpdateLabelPayload>({
		mutationKey: [QueryKeys.UPDATE_LABEL],
		mutationFn: payload => {
			const { workspaceId, workspaceLabelId, ...bodyPayload } = payload;

			return API.put(
				`/api/workspaces/${workspaceId}/labels/${workspaceLabelId}`,
				bodyPayload,
			);
		},
		onSuccess: (data, variables) => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}

			if (isAfterCreate) {
				Alerter.success('Label color successfully updated!');
			}

			const { workspaceId, name, colourId, workspaceLabelId } = variables;

			queryClient.setQueryData<
				ApiResponse<{ workspaceLabels: LabelItemType[] }> | undefined
			>([QueryKeys.GET_WORKSPACE_LABELS, workspaceId], oldData => {
				if (!oldData) {
					return oldData;
				}

				const updatedLabelData = {
					workspaceLabelId,
					name,
					colourId,
				};

				const updateLabels = isAfterCreate
					? [...oldData.value.workspaceLabels, updatedLabelData]
					: oldData.value?.workspaceLabels?.map(label => {
							if (label.workspaceLabelId !== workspaceLabelId) {
								return label;
							}
							const isNameChanged = label.name !== name;
							Alerter.success(
								`Label ${isNameChanged ? 'name' : 'colour'} was successfully updated!`,
							);
							return updatedLabelData;
						});

				return {
					...oldData,
					value: {
						workspaceLabels: updateLabels,
					},
				};
			});
		},
	});
};
