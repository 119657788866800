export const TABS = [
	{
		label: 'To do',
		iconName: 'check',
	},
	{
		label: 'Done',
		iconName: 'archive',
	},
];
